import React, { useState, useEffect, useContext, useRef } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import useFetch from "hooks/useHTTP";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";

// Components
import Layout from "components/Layout";
import Tabs from "components/Tabs";
import Button from "components/Button";
import SearchBar from "components/SearchBar";
import SvgIcon from "components/SvgIcon";

// Context
import { AppContext } from "context/AppContext";

// Constants
import {
  OXFORD_BLUE,
  PRIMARY,
  WHITE,
  WILD_BLUE_YONDER,
} from "constants/colors";
import { ROOT } from "constants/routes";
import {
  searchCardTypes,
  searchFilterContext,
  searchEndpoints,
  searchTimeIntervalValues,
} from "utils/enums/search";

// Assets
import { ReactComponent as Arrow } from "assets/down-arrow.svg";
import { ReactComponent as CancelIcon } from "assets/cancel.svg";

//Internal Components
import Collapsable from "./components/Collapsable";
import {
  brickTabCards,
  projectsTabCards,
  usersTabCards,
  defaultValues,
} from "./config";
import ResultRow from "./components/ResultRow";
import FilterCard from "./components/FilterCard";
import {
  getQueryParamsFromFilters,
  getFiltersFromQueryParams,
  parseFilter,
} from "./components/filterHelper";
import { SEARCH_ROOT } from "constants/routes";
import Loading from "components/Loading";

// Styles
const useStyles = makeStyles(() => ({
  filterContainer: {
    height: "100%",
  },
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "287px 287px 287px 287px 287px",
    gridTemplateRows: "115px 115px",
    gridAutoFlow: "column",
    marginBottom: 20,
  },
  searchHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  searchContainer: {
    marginTop: 40,
    marginBottom: 50,
  },
  closeIcon: {
    cursor: "pointer",
  },
  title: {
    fontSize: "25px",
    marginBottom: 27,
  },
  emptyDiv: {
    height: 37.5,
    marginBottom: 27,
    width: "100%",
  },
  titleCard: {
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: 10,
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  customButton: {
    minWidth: 90,
    marginLeft: 5,
  },
  customWhiteButton: {
    backgroundColor: WHITE,
    color: PRIMARY,
  },
  coloredScrollbar: {
    "& .ps__thumb-y": {
      backgroundColor: PRIMARY,
    },
    "& :hover": {
      backgroundColor: "transparent",
    },
  },
  starContainer: {
    margin: "auto 0",
  },
  showMoreContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "30px 0",
    cursor: "pointer",
  },
  showMoreTitle: {
    fontSize: 18,
    marginRight: 15,
    cursor: "pointer",
  },
  showMoreicon: {
    width: 14,
    height: 14,
  },
  tabContainer: {
    marginBottom: 50,
  },
  resultsContainer: {
    borderTop: `1px solid ${WILD_BLUE_YONDER}70`,
  },
  loadingWrapper: {
    height: "350px",
  },
}));

const tabsDefaultValues = {
  [searchFilterContext.PROJECTS]: projectsTabCards,
  [searchFilterContext.BRICK]: brickTabCards,
  [searchFilterContext.USERS]: usersTabCards,
};

const itemsPerPage = 5;

const Search = () => {
  // Hooks
  const classes = useStyles({});
  const history = useHistory();
  const { get } = useFetch();
  const location = useLocation();
  const { searchContext } = useParams();

  // Context
  const { setPageTitle, setIsFirstColOpen } = useContext(AppContext);

  // Declare differents controls for each tab
  const {
    control: projectsControl,
    handleSubmit: handleProjectsSubmit,
    reset: projectsReset,
    watch: projectsWatch,
    setValue: projectsSetValue,
    getValues: projectsGetValues,
  } = useForm({
    defaultValues: { context: searchFilterContext.PROJECTS },
  });

  const {
    control: brickControl,
    handleSubmit: handleBrickSubmit,
    reset: brickReset,
    watch: brickWatch,
    setValue: brickSetValue,
    getValues: brickGetValues,
  } = useForm({
    defaultValues: { context: searchFilterContext.BRICK },
  });

  const {
    control: usersControl,
    handleSubmit: handleUsersSubmit,
    reset: usersReset,
    watch: usersWatch,
    setValue: usersSetValue,
    getValues: usersGetValues,
  } = useForm({
    defaultValues: { context: searchFilterContext.USERS },
  });

  const valuesHandlers = [
    {
      id: searchFilterContext.PROJECTS,
      setValue: projectsSetValue,
      getValues: projectsGetValues,
      reset: projectsReset,
    },
    {
      id: searchFilterContext.BRICK,
      setValue: brickSetValue,
      getValues: brickGetValues,
      reset: brickReset,
    },
    {
      id: searchFilterContext.USERS,
      setValue: usersSetValue,
      getValues: usersGetValues,
      reset: usersReset,
    },
  ];

  const getTabIndexFromContext = () =>
    valuesHandlers.findIndex(vh => vh.id === searchContext);

  // States
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [tabsContent, setTabContent] = useState(tabsDefaultValues);
  const [openTabIdx, setOpenTabIdx] = useState(getTabIndexFromContext());
  const [results, setResults] = useState([]);
  const [resultsCount, setResultsCount] = useState();
  const [currentResultPage, setCurrentResultPage] = useState(0);
  const [searchText, setSearchText] = useState();
  const [customers, setCustomers] = useState([]);
  const [skills, setSkills] = useState([]);
  const [jobTitles, setJobTitles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [triggerClearFilters, setTriggerClearFilters] = useState(false);
  //Dynamic Filters
  const [filterDefaultValues, setFilterDefaultValues] = useState();
  const preTimeChangeFilterValuesRef = useRef();

  const timeSelectedProjects = projectsWatch("period");
  const timeSelectedBrick = brickWatch("period");
  const timeSelectedUsers = usersWatch("period");
  const controllers = {
    [searchFilterContext.PROJECTS]: timeSelectedProjects,
    [searchFilterContext.BRICK]: timeSelectedBrick,
    [searchFilterContext.USERS]: timeSelectedUsers,
  };

  // Effects
  useEffect(() => {
    setPageTitle("Search");
    setIsFirstColOpen(false);
  }, []);

  useEffect(() => {
    //Effect to handle the first search call ever or at the tab change
    const handleSearch = async generateUrlFromControl => {
      const _context = getCurrentContext();
      const filtersFromQueryParams = getFiltersFromQueryParams(searchContext);
      const text = filtersFromQueryParams.find(
        obj => obj.key === "Query"
      )?.value;
      if (!text) return history.push("/");
      if (!generateUrlFromControl) {
        filtersFromQueryParams.map(obj => {
          if (obj.key.includes("year")) {
            _context.setValue("period", "fiscalYear");
          } else if (obj.key === "interval") {
            _context.setValue("period", "interval");
          }
          _context.setValue(obj.key, obj.value);
        });
        clearAllStates();
      } else {
        const alreadySettedFilterValue = _context.getValues();
        alreadySettedFilterValue.context =
          Object.keys(tabsDefaultValues)[openTabIdx];
        sendFilter(alreadySettedFilterValue);
      }
      if (filtersFromQueryParams.length > 1) setIsFilterOpen(true);
      setSearchText(text);
    };
    handleSearch(!!searchText);
  }, [openTabIdx]);

  useEffect(() => {
    //on change location, make the effective search call
    if (location) {
      search({
        endpoint:
          searchEndpoints[Object.values(searchFilterContext)[openTabIdx]],
        queryParams: location.search,
      });
    }
  }, [location]);

  useEffect(() => {
    if (timeSelectedProjects || timeSelectedBrick || timeSelectedUsers) {
      const _selectedTab = [...Object.values(tabsDefaultValues)[openTabIdx]];
      const foundIndex = _selectedTab.findIndex(
        obj => obj.showValue === Object.values(controllers)[openTabIdx]
      );
      if (foundIndex >= 0) {
        const targetProjectCard = { ..._selectedTab[foundIndex] };
        targetProjectCard.hideAtMount = false;
        _selectedTab[foundIndex] = targetProjectCard;
      }

      const _context = getCurrentContext();
      preTimeChangeFilterValuesRef.current = _context.getValues();

      setTabContent(prev => ({
        ...prev,
        [Object.keys(tabsDefaultValues)[openTabIdx]]: _selectedTab,
      }));
    }
  }, [timeSelectedProjects, timeSelectedBrick, timeSelectedUsers]);

  // Reset current tab filter values after adding or removing a time filter card:
  // temporary pass "pre-change" values through a ref
  useEffect(() => {
    if (!preTimeChangeFilterValuesRef.current) return;
    const currentValues = preTimeChangeFilterValuesRef.current;
    const currentContext = getCurrentContext();
    // Reinstate current tav values from ref
    currentContext.reset(currentValues);
    // Clear interval filter
    currentContext.setValue(
      "interval",
      searchTimeIntervalValues.LAST_SIX_MONTHS
    );
    // Clear all "year-" keys
    const filtersFromQueryParams = getFiltersFromQueryParams(searchContext);
    const yearStateKeys = Object.keys(currentValues).filter(k =>
      k.includes("year")
    );
    yearStateKeys.map(k => currentContext.setValue(k, false));
    // Re-enable "year-" keys present in queryString (at mount)
    if (yearStateKeys.length > 0)
      filtersFromQueryParams.map(
        ({ key }) => key.includes("year-") && currentContext.setValue(key, true)
      );
    // Clear temporary filter values in ref
    preTimeChangeFilterValuesRef.current = null;
  }, [tabsContent]);

  useEffect(() => {
    const getDynamicFilters = async endpoint => {
      const res = await get(`${endpoint}/filters?Query=${searchText}`);
      const { data } = res;
      const updatedFilters = {
        ...defaultValues,
        pricing: [0, data.maxPricing || 0],
        marginality: [
          Math.floor(data.minMargin) || 0,
          Math.ceil(data.maxMargin) || 0,
        ],
      };
      if (data?.customers?.length) {
        setCustomers(data.customers);
        data?.customers.map(
          c => (updatedFilters[`customerId-${c.id}`] = false)
        );
      }
      if (data.skills?.length) {
        setSkills(data.skills);
        data?.skills.map(s => (updatedFilters[`skillId-${s.id}`] = false));
      }
      if (data.jobTitles?.length) {
        setJobTitles(data.jobTitles);
        data?.jobTitles.map(
          j => (updatedFilters[`jobTitleId-${j.id}`] = false)
        );
      }

      setFilterDefaultValues(updatedFilters);
      if (triggerClearFilters) clearAllFilter(updatedFilters);
    };
    if (searchText) {
      getDynamicFilters(
        searchEndpoints[Object.values(searchFilterContext)[openTabIdx]]
      );
    }
  }, [searchText, openTabIdx]);

  // Functions
  const search = async ({
    endpoint,
    queryParams,
    pageNumber = 0,
    returnData = false,
  }) => {
    try {
      setIsLoading(true);
      const res = await get(
        `${endpoint}${queryParams}&PageSize=${itemsPerPage}&PageNumber=${pageNumber}`
      );
      let { data } = res;
      setResultsCount(data?.count);
      // If we fetch brick we should parse the results to render ResultRow
      if (
        endpoint === searchEndpoints[searchFilterContext.BRICK] &&
        data.data.length
      )
        data = { ...data, data: parseResultsForBrick(data.data) };
      if (!returnData) setResults(data.data);
      return data.data;
    } catch (error) {
      console.error("search-error::", error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateUrl = (text = "", filterParams = "") => {
    const urlToReplace = `${SEARCH_ROOT}/${
      Object.values(searchFilterContext)[openTabIdx]
    }?Query=${text || searchText}${filterParams && `&${filterParams}`}`;
    if (searchText) history.replace(urlToReplace);
  };

  const handleProjectClick = id => {
    history.push(`/project/${id}`);
  };

  const handleBrickClick = id => {
    history.push(`/brick/${id}`);
  };

  const handleUserClick = id => {
    history.push(`/user/${id}`);
  };

  const handleResultPicked = async text => {
    clearAllStates();
    setSearchText(text);
    updateUrl(text);
    setTriggerClearFilters(true);
  };

  const handleShowMore = async () => {
    const currentTab = Object.values(searchEndpoints)[openTabIdx];

    const _results = await search({
      endpoint: currentTab,
      queryParams: `?Query=${searchText}`,
      pageNumber: currentResultPage + 1,
      returnData: true,
    });

    //Logic for bricks too much complicated, need a control
    if (currentTab === searchEndpoints[searchFilterContext.BRICK]) {
      const unparsedFetchedResults = _results
        .map(project => project.bricks)
        .flat();
      const unparsedOldResults = results.map(project => project.bricks).flat();
      const updateResults = parseResultsForBrick([
        ...unparsedOldResults,
        ...unparsedFetchedResults,
      ]);
      setResults(updateResults);
    } else setResults(prev => [...prev, ..._results]);
    // to handle next page logic we should subtract from total count of
    // items the product between item per page and next page to load
    // than check if the result is greater than 0
    // Ex: 6 items, 5 items per page => 6 - (5*1) another page | 6 - (5*2) stop asking page
    resultsCount - itemsPerPage * (currentResultPage + 2) <= 0
      ? setCurrentResultPage(-1)
      : setCurrentResultPage(prev => prev + 1);
  };

  const sendFilter = async data => {
    const parsedFilter = parseFilter(data, filterDefaultValues);
    const query = getQueryParamsFromFilters(parsedFilter);
    clearAllStates();
    updateUrl("", query);
  };

  const parseResultsForBrick = data => {
    const reduced = data.reduce((acc, d) => {
      const found = acc.find(a => a.projectName === d.projectName);
      if (!found) {
        acc.push({ projectName: d.projectName, bricks: [d] }); // not found, so need to add data property
      } else {
        // if found, that means data property exists, so just push new element to found.data.
        found.bricks.push(d);
      }
      return acc;
    }, []);
    return reduced;
  };

  const clearAllStates = () => {
    setResults([]);
    setResultsCount(-1);
    setCurrentResultPage(0);
  };

  const clearAllFilter = value => {
    projectsReset(value);
    brickReset(value);
    usersReset(value);
    setTriggerClearFilters(false);
  };

  const handleTabChange = tabIdx => {
    setIsLoading(true);
    setOpenTabIdx(tabIdx);
  };

  const getCurrentContext = () =>
    valuesHandlers.find(c => c.id === Object.keys(controllers)[openTabIdx]);

  // Renders
  const renderHead = () => {
    return (
      <div className={classes.searchContainer}>
        <div className={classes.searchHeader}>
          {!isLoading ? (
            <Typography className={classes.title}>
              Risultati in {tabsArray[openTabIdx].label}: {resultsCount} per{" "}
              {`"${searchText}"`}
            </Typography>
          ) : (
            <div className={classes.emptyDiv}>
              <Typography className={classes.title}>
                Ricerca in corso...
              </Typography>
            </div>
          )}

          <div className={classes.closeIcon}>
            <SvgIcon
              color={WILD_BLUE_YONDER}
              hoverColor={WHITE}
              icon={<CancelIcon />}
              onClick={() => history.push(ROOT)}
              strokeWidth={2}
            />
          </div>
        </div>

        <SearchBar
          searchQuery={searchText}
          searchFunction={() => {}}
          fetchedSuggestionsHandler={() => {}}
          resultPickedHandler={handleResultPicked}
          placeholder="Cerca un Progetto, un Brick, un Utente..."
          searchFieldAdditionalStyle={{ width: "100%" }}
          emptyFieldAfterSubmit={false}
        />
      </div>
    );
  };

  const renderDyanimcChecklist = ({ defaultValues, filterKey, filterArray }) =>
    Object.keys(defaultValues)
      .filter(key => key.includes(filterKey))
      .map(key => ({
        key,
        label:
          filterArray.find(
            filterItem =>
              filterItem.id.toString() === key.replace(`${filterKey}-`, "")
          )?.name || "",
        defaultValue: false,
        customColorUnchecked: OXFORD_BLUE,
      }));

  const renderFilterContent = (tabCardsContext, control) => (
    <div className={classes.filterContainer}>
      {filterDefaultValues && (
        <PerfectScrollbar
          style={{ height: "auto" }}
          options={{ suppressScrollY: true }}
        >
          <div className={classes.gridContainer}>
            {tabsContent[tabCardsContext]
              .filter(card => !card.hideAtMount)
              .map((card, idx) => {
                const CardComponent = card.child;
                const additionalProps = {
                  control,
                  ...(card.type === searchCardTypes.STAR && {
                    customClass: classes.starContainer,
                  }),
                  ...(card.id === "customers" &&
                    customers?.length &&
                    Object.keys(filterDefaultValues).some(k =>
                      k.includes("customerId")
                    ) && {
                      checkBoxArray: renderDyanimcChecklist({
                        defaultValues: filterDefaultValues,
                        filterKey: "customerId",
                        filterArray: customers,
                      }),
                    }),
                  ...(card.id === "skills" &&
                    skills?.length &&
                    Object.keys(filterDefaultValues).some(k =>
                      k.includes("skillId")
                    ) && {
                      checkBoxArray: renderDyanimcChecklist({
                        defaultValues: filterDefaultValues,
                        filterKey: "skillId",
                        filterArray: skills,
                      }),
                    }),
                  ...(card.id === "jobTitles" &&
                    jobTitles?.length &&
                    Object.keys(filterDefaultValues).some(k =>
                      k.includes("jobTitleId")
                    ) && {
                      checkBoxArray: renderDyanimcChecklist({
                        defaultValues: filterDefaultValues,
                        filterKey: "jobTitleId",
                        filterArray: jobTitles,
                      }),
                    }),
                  ...(card.id === "pricing" &&
                    filterDefaultValues?.pricing?.length && {
                      min: 0,
                      max: filterDefaultValues.pricing[1],
                      marks: [
                        {
                          value: 0,
                          label: `0`,
                        },
                        {
                          value: filterDefaultValues.pricing[1],
                          label: `<${filterDefaultValues.pricing[1] / 1000}K`,
                        },
                      ],
                      defaultValue: [0, filterDefaultValues.pricing[1]],
                    }),
                  ...(card.id === "marginality" &&
                    filterDefaultValues?.marginality?.length && {
                      min: filterDefaultValues.marginality[0],
                      max: filterDefaultValues.marginality[1],
                      marks: [
                        {
                          value: filterDefaultValues.marginality[0],
                          label: `${filterDefaultValues.marginality[0]}%`,
                        },
                        {
                          value: filterDefaultValues.marginality[1],
                          label: `<${filterDefaultValues.marginality[1]}%`,
                        },
                      ],
                      defaultValue: [
                        filterDefaultValues.marginality[0],
                        filterDefaultValues.marginality[1],
                      ],
                    }),
                };

                return (
                  <FilterCard
                    idx={idx + 1}
                    halfSize={card.horizontal}
                    title={card.title}
                    key={idx}
                  >
                    <CardComponent {...additionalProps} {...card.childProps} />
                  </FilterCard>
                );
              })}
          </div>
        </PerfectScrollbar>
      )}
    </div>
  );

  const renderFilterButton = (onSubmitFn, resetFn) => (
    <div className={classes.buttonsContainer}>
      <Button
        variant="contained"
        className={clsx(classes.customButton, classes.customWhiteButton)}
        onClick={() => {
          sendFilter(filterDefaultValues);
          resetFn(filterDefaultValues);
        }}
      >
        AZZERA
      </Button>
      <Button
        variant="contained"
        className={classes.customButton}
        color={"primary"}
        onClick={onSubmitFn(sendFilter)}
      >
        APPLICA
      </Button>
    </div>
  );

  const renderShowMoreContainer = () =>
    resultsCount > 5 &&
    currentResultPage >= 0 && (
      <div
        className={classes.showMoreContainer}
        onClick={() => handleShowMore()}
      >
        <Typography className={classes.showMoreTitle}>
          Mostra più risultati
        </Typography>
        <SvgIcon
          color={WHITE}
          className={classes.showMoreicon}
          icon={<Arrow />}
          strokeWidth={2}
        />
      </div>
    );

  const renderLoading = () => (
    <div className={classes.loadingWrapper}>
      <Loading
        showWrapper={false}
        animationStyle={{ height: 80, width: 80, marginTop: -100 }}
      />
    </div>
  );

  // Constants
  const tabsArray = [
    {
      label: "Progetti",
      content: isLoading ? (
        renderLoading()
      ) : (
        <div className={classes.tabContainer}>
          <Collapsable
            customIsOpen={isFilterOpen}
            callBackIsOpen={setIsFilterOpen}
            content={renderFilterContent(
              searchFilterContext.PROJECTS,
              projectsControl
            )}
            buttonsContainer={renderFilterButton(
              handleProjectsSubmit,
              projectsReset
            )}
          />
          <div className={classes.resultsContainer}>
            {results?.map((p, idx) => (
              <ResultRow
                img={p.imageUrl || ""}
                title={p.name}
                subtitle={p.customer?.name}
                type={"project"}
                isFirst={idx === 0}
                onClickArrow={() => handleProjectClick(p.id)}
                onClickAvatar={() => handleProjectClick(p.id)}
                key={`project_${idx}`}
              />
            ))}
          </div>
          {renderShowMoreContainer()}
        </div>
      ),
    },
    {
      label: "Brick",
      content: isLoading ? (
        renderLoading()
      ) : (
        <div className={classes.tabContainer}>
          <Collapsable
            customIsOpen={isFilterOpen}
            callBackIsOpen={setIsFilterOpen}
            content={renderFilterContent(
              searchFilterContext.BRICK,
              brickControl
            )}
            buttonsContainer={renderFilterButton(handleBrickSubmit, brickReset)}
          />
          <div className={classes.resultsContainer}>
            {results.map((phase, idx) => (
              <ResultRow
                title={phase.projectName}
                subtitle={`${phase.bricks?.length} Brick`}
                type={"brick"}
                isFirst={idx === 0}
                onClickArrow={id => handleBrickClick(id)}
                bricks={phase.bricks}
                key={`brick${idx}`}
              />
            ))}
          </div>
          {renderShowMoreContainer()}
        </div>
      ),
    },
    {
      label: "Utenti",
      content: isLoading ? (
        renderLoading()
      ) : (
        <div className={classes.tabContainer}>
          <Collapsable
            customIsOpen={isFilterOpen}
            callBackIsOpen={setIsFilterOpen}
            content={renderFilterContent(
              searchFilterContext.USERS,
              usersControl
            )}
            buttonsContainer={renderFilterButton(handleUsersSubmit, usersReset)}
          />
          <div className={classes.resultsContainer}>
            {results.map((user, idx) => (
              <ResultRow
                img={user.imageUrl}
                title={`${user.name} ${user.surname}`}
                subtitle={user.jobTitle?.name || ""}
                type={"user"}
                isFirst={idx === 0}
                onClickArrow={() => handleUserClick(user.id)}
                onClickAvatar={() => handleUserClick(user.id)}
                key={`user${idx}`}
              />
            ))}
          </div>
          {renderShowMoreContainer()}
        </div>
      ),
    },
  ];

  return (
    <Layout showSecondCol showHeader={false} showPosts={false}>
      <div className={classes.wrapper}>
        {renderHead()}
        <Tabs
          tabs={tabsArray}
          currentOpenTab={openTabIdx}
          setCurrentOpenTab={handleTabChange}
        />
      </div>
    </Layout>
  );
};

export default Search;

import React from "react";
import PropTypes from "prop-types";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import { WHITE, WILD_BLUE_YONDER } from "constants/colors";

const BorderLinearProgress = withStyles(() => ({
  root: {
    height: "100%",
    width: "100%",
    borderRadius: 40,
  },
  colorPrimary: {
    backgroundColor: WILD_BLUE_YONDER,
  },
  bar: {
    borderRadius: 5,
    backgroundColor: WHITE,
  },
}))(LinearProgress);

const useStyles = makeStyles({
  root: ({ height, width }) => ({
    flexGrow: 1,
    height: height,
    width: width,
    borderRadius: 40,
  }),
});

const CustomizedProgressBar = ({ height, value, width }) => {
  const classes = useStyles({ height, width });

  return (
    <div className={classes.root}>
      <BorderLinearProgress variant="determinate" value={value} />
    </div>
  );
};
CustomizedProgressBar.defaultProps = {
  height: 8,
  width: 180,
};

CustomizedProgressBar.propTypes = {
  height: PropTypes.number,
  value: PropTypes.number,
  width: PropTypes.number,
};

export default CustomizedProgressBar;

// STATI
// 1  pianificato -> data I/F pianificata
// 2 in corso -> I reale /F prevista
// 3 completato -> I/F reali
// 4 da validare -> I reale/ F prevista
export const brickTypes = {
  PLANNED: 1,
  ACTIVE: 2,
  CLOSED: 3,
  TO_VALIDATE: 4,
};

export const genericBrickTypes = {
  BUSINESS_TRIP: 1,
  CUSTOMER_MEETINGS: 2,
  TRAINING: 3,
  FAIRS_EVENTS: 4,
  RESEARCH: 5,
  BENCHMARK: 6,
  OTHER_OFFICES_SUPPORT: 7,
};

export const brickFiltersTypes = {
  DAYS: 1,
  WEEKS: 2,
  MONTHS: 3,
};

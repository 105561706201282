import React, { useContext } from "react";
import PropTypes from "prop-types";
// Material UI
import { makeStyles } from "@material-ui/core/styles";

import { nanoid } from "nanoid";

// Context
import { AppContext } from "context/AppContext";

//enums
import { iconMap, metricTypes } from "utils/enums/grooveMetrics";

// Styles
const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    overflow: "hidden",
    padding: "0rem 10px",
    marginRight: 2,
  },

  icon: {
    width: "30px",
    height: "40px",
    textAlign: "center",
    cursor: "pointer",
    margin: "0 10px",
    "&:hover": {
      filter: "contrast(5.5)",
    },
  },
}));

const HeaderGlobalIcons = ({ headerIcons }) => {
  const classes = useStyles();

  // Context
  const { activeMetric } = useContext(AppContext);

  const renderIcon = () => {
    return headerIcons?.map(el => {
      return (
        <img
          key={nanoid()}
          className={classes.icon}
          src={iconMap[el?.icon]}
          onClick={el?.action}
          style={
            activeMetric === metricTypes[el?.icon.toUpperCase()] ||
            headerIcons?.length === 1
              ? { filter: "contrast(5.5)" }
              : null
          }
        />
      );
    });
  };

  return <div className={classes.container}>{renderIcon()}</div>;
};

HeaderGlobalIcons.propTypes = {
  headerIcons: PropTypes.array,
};

export default HeaderGlobalIcons;

import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";

// hooks
import { FetchProvider } from "hooks/useHTTP";
// Context
import AppGlobalProvider from "context/AppContext";
import AuthProvider from "context/AuthContext";
import SearchProvider from "context/SearchContext";
// AWS
import Amplify from "aws-amplify";
import awsConfig from "aws-exports";
// Components
import Authenticated from "pages/Authenticated";
import Login from "pages/Login";
import ForgotPassword from "pages/ForgotPassword";
import PasswordRequested from "pages/PasswordRequested";
import PasswordChanged from "pages/PasswordChanged";
import ChangePassword from "pages/ChangePassword";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
// Day
import DayJsUtils from "@date-io/dayjs";
import "dayjs/locale/it";
// Constants
import {
  ROOT,
  LOGIN,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  PASSWORD_REQUESTED,
  PASSWWORD_CHANGED,
} from "constants/routes";

const App = () => {
  useEffect(() => {
    Amplify.configure(awsConfig);
  }, []);

  return (
    <AppGlobalProvider>
      <AuthProvider>
        <FetchProvider>
          <SearchProvider>
            <MuiPickersUtilsProvider locale="it" utils={DayJsUtils}>
              <Switch>
                <Route path={RESET_PASSWORD} component={ChangePassword} />
                <Route path={FORGOT_PASSWORD} component={ForgotPassword} />
                <Route
                  path={PASSWORD_REQUESTED}
                  component={PasswordRequested}
                />
                <Route path={PASSWWORD_CHANGED} component={PasswordChanged} />
                <Route path={LOGIN} component={Login} />
                <Route path="/" component={Authenticated} />
              </Switch>
              <Route exact path={ROOT} />
            </MuiPickersUtilsProvider>
          </SearchProvider>
        </FetchProvider>
      </AuthProvider>
    </AppGlobalProvider>
  );
};

export default App;

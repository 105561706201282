import React, { createContext } from "react";
import PropTypes from "prop-types";
import { Auth } from "aws-amplify";

import useStorage from "hooks/useStorage";
import { USER_DETAILS } from "constants/api";

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [autoLogin, setAutoLogin] = useStorage("autoLogin", false);
  const [sessionToken, setSessionToken] = useStorage("sessionToken", null);
  const [user, setUser] = useStorage("tg-user", null);

  const handleLogin = async (email, password, rememberMe) => {
    if (rememberMe) setAutoLogin(true);
    const cognitoUser = await Auth.signIn(email, password);
    const currentSession = await Auth.currentSession();
    const sessionToken = currentSession.getIdToken().getJwtToken();
    setSessionToken(sessionToken);

    const userData = await getUserData(cognitoUser.username, sessionToken);
    return userData;
  };

  const handleLogOut = async () => {
    await Auth.signOut();
    setAutoLogin(false);
    setUser(null);
    setSessionToken(null);
  };

  const getValidToken = async () => {
    if (!user) return null;
    const session = await Auth.currentSession();
    const validToken = await session.getIdToken().getJwtToken();
    return validToken;
  };

  const getUserData = async (userId, token) => {
    const res = await fetch(`${USER_DETAILS}/${userId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const userData = await res.json();
    return userData;
  };

  const authShared = {
    autoLogin,
    setAutoLogin,
    user,
    setUser,
    sessionToken,
    setSessionToken,
    handleLogin,
    handleLogOut,
    getValidToken,
  };

  return (
    <AuthContext.Provider value={authShared}>{children}</AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default AuthProvider;

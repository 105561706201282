import React, { useEffect, useState, forwardRef } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import theme from "theme";
import clsx from "clsx";
import useFetch from "hooks/useHTTP";

// Material UI components
import Autocomplete from "@material-ui/lab/Autocomplete";
import Input from "./input";

// Constants
import { OXFORD_BLUE, WILD_BLUE_YONDER } from "constants/colors";

// Icons
import { ReactComponent as SearchIcon } from "assets/search-image.svg";

const useStyles = makeStyles(() => ({
  clearIndicator: ({ themeType }) => ({
    color:
      themeType === "dark"
        ? theme.palette.componentsBackground.light
        : theme.palette.common.black,
  }),
  searchIndicator: () => ({
    transform: "rotate(0)",
  }),
}));

const AutoComplete = forwardRef(
  (
    {
      className,
      themeType,
      label,
      options,
      required,
      endpoint,
      error,
      helperText,
      minSearchLength,
      ...other
    },
    ref
  ) => {
    const classes = useStyles({ themeType });
    const { get, loading } = useFetch();
    const [currentOptions, setCurrentOptions] = useState([]);
    const [currentValue, setCurrentValue] = useState("");

    // Effects
    useEffect(() => {
      if (options) setCurrentOptions(options);
    }, [options]);

    // Helpers
    const handleInputChange = (event, value) => {
      setCurrentValue(value);
      if (value.length >= minSearchLength && endpoint) {
        get(`${endpoint}=${value}`).then(res => {
          setCurrentOptions(res.data);
        });
      } else {
        setCurrentOptions([]);
      }
    };

    const getNoOptionsText = () =>
      currentValue.length > minSearchLength
        ? loading
          ? "Ricerca in corso..."
          : "Nesusn risultato trovato"
        : "Digita per cercare";

    return (
      <Autocomplete
        fullWidth
        className={clsx(classes.autocomplete, className)}
        // autoSelect
        classes={{
          clearIndicator: classes.clearIndicator,
          popupIndicatorOpen: classes.searchIndicator,
        }}
        options={currentOptions}
        autoComplete
        filterSelectedOptions
        onClose={() => endpoint && setCurrentOptions([])}
        onInputChange={handleInputChange}
        noOptionsText={getNoOptionsText()}
        renderInput={params => (
          <Input
            {...params}
            label={label}
            themeType={themeType}
            required={required}
            error={error}
            helperText={error && helperText}
            loading={loading}
            inputRef={ref}
          />
        )}
        forcePopupIcon
        popupIcon={<SearchIcon width={16} height={16} />}
        ListboxProps={{
          style: {
            color:
              themeType === "dark"
                ? theme.palette.componentsBackground.main
                : theme.palette.common.black,
          },
        }}
        ChipProps={{
          style: {
            backgroundColor:
              themeType === "dark" ? OXFORD_BLUE : WILD_BLUE_YONDER,
            color: theme.palette.common.white,
            borderRadius: 5,
          },
        }}
        {...other}
      />
    );
  }
);

AutoComplete.displayName = "AutoComplete";

AutoComplete.propTypes = {
  className: PropTypes.string,
  themeType: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  options: PropTypes.array,
  endpoint: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  minSearchLength: PropTypes.number,
};

AutoComplete.defaultProps = {
  themeType: "dark",
  required: false,
  helperText: "Campo obbligatorio",
  minSearchLength: 3,
};

export default AutoComplete;

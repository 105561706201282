import React, { useEffect } from "react";
import PropTypes from "prop-types";
import theme from "theme";

// Components
import TextField from "components/TextField";
import Select from "components/Select";
import Autocomplete from "components/Autocomplete";
import Button from "components/Button";
import SvgIcon from "components/SvgIcon";
import Loading from "components/Loading";
// Select Data
import { genericBrickTypologies } from "./formData";
// Material UI
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Controller, useFormContext, useFieldArray } from "react-hook-form";
// Assets
import { ReactComponent as CancelIcon } from "assets/cancel.svg";
// API
import { USERS_LIST } from "constants/api";

// Styles
const useStyles = makeStyles(() => ({
  formWrapper: {
    width: "100%",
  },
  formRow: {
    marginBottom: 15,
  },
  switchWrapper: {
    display: "flex",
    alignItems: "flex-end",
  },
}));

const THEME_TYPE = "light";
const DEFAULT_OWNER_MESSAGE = "Ciao, ti ho assegnato un nuovo Brick";
const DEFAULT_OWNER = { user: null, plannedHours: null };

const GenericBrickForm = ({ phaseTitle, loading }) => {
  // Hooks
  const classes = useStyles();
  const {
    control,
    getValues,
    register,
    formState: { errors },
  } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "owners",
  });

  // Effects
  useEffect(() => {
    append(DEFAULT_OWNER);
  }, []);

  // Helpers
  const addOwner = () => append(DEFAULT_OWNER);

  // Renders
  const renderOwner = (field, idx) => (
    <Grid
      key={field.id}
      item
      container
      className={classes.formRow}
      spacing={1}
      justify="space-between"
    >
      <Grid item xs={8}>
        {/* Owner Autocomplete */}
        <Controller
          render={({ field }) => (
            <Autocomplete
              themeType={THEME_TYPE}
              label="Owner"
              endpoint={`${USERS_LIST}?q`}
              required
              getOptionLabel={({ name, surname, jobTitle }) =>
                `${name} ${surname}${jobTitle ? ` - ${jobTitle}` : ""}`
              }
              error={!!errors.owners?.[idx]?.user}
              {...field}
              onChange={(_, data) => field.onChange(data)}
            />
          )}
          defaultValue={field.user}
          name={`owners.${idx}.user`}
          control={control}
          rules={{ required: true }}
        />
      </Grid>

      {/* Owner Planned Hours */}
      <Grid item xs={idx > 0 ? 3 : 4}>
        <TextField
          type="number"
          inputProps={{ min: 0 }}
          themeType={THEME_TYPE}
          label="Ore Pianificate"
          required
          {...register(`owners.${idx}.plannedHours`, {
            required: true,
          })}
          defaultValue={field.plannedHours}
          error={!!errors.owners?.[idx]?.plannedHours}
        />
      </Grid>

      {/* Owner Delete Button */}
      {idx > 0 && (
        <Grid item xs={1}>
          <Button
            className="iconButton"
            variant="outlined"
            color="secondary"
            themeType="light"
            onClick={() => remove(idx)}
          >
            <SvgIcon
              color={theme.palette.secondary.main}
              icon={<CancelIcon />}
            />
          </Button>
        </Grid>
      )}
    </Grid>
  );

  if (loading)
    return (
      <div className={classes.loading}>
        <Loading showWrapper={false} />
      </div>
    );

  return (
    <form>
      <Grid container spacing={1} style={{ width: "100%" }}>
        <Grid item xs={12} className={classes.formRow}>
          <Typography
            color="primary"
            variant="h6"
          >{`FASE: ${phaseTitle}`}</Typography>
        </Grid>

        <Grid item xs={12} className={classes.formRow}>
          <Controller
            render={({ field }) => (
              <Select
                label="Tipologia"
                data={genericBrickTypologies}
                themeType={THEME_TYPE}
                required
                error={!!errors.brickTypology}
                {...field}
              />
            )}
            defaultValue=""
            name="brickTypology"
            control={control}
            rules={{ required: true }}
          />
        </Grid>

        <Grid item xs={12} className={classes.formRow}>
          <Controller
            render={({ field }) => (
              <TextField
                label="Messaggio per l'owner"
                multiline
                rows={5}
                themeType={THEME_TYPE}
                shrink={!!getValues("ownerMessage")}
                required
                error={!!errors.ownerMessage}
                {...field}
              />
            )}
            defaultValue={DEFAULT_OWNER_MESSAGE}
            name="ownerMessage"
            control={control}
            rules={{ required: true }}
          />
        </Grid>

        {/* Render first (mandatory) owner field... */}
        {fields.length > 0 && renderOwner(fields[0], 0)}

        <Grid item xs={12} className={classes.formRow}>
          <Button
            fullWidth
            themeType="light"
            variant="outlined"
            color="primary"
            onClick={addOwner}
          >
            + OWNER
          </Button>
        </Grid>

        {/* ...Render remaining (optional) owner fields */}
        {fields?.slice(1)?.map((field, idx) => renderOwner(field, idx + 1))}
      </Grid>
    </form>
  );
};

GenericBrickForm.propTypes = {
  phaseTitle: PropTypes.string,
  loading: PropTypes.bool,
};

export default GenericBrickForm;

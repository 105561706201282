import React, { useContext } from "react";
import PropTypes from "prop-types";
import CountUp from "react-countup";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
// Context
import ProjectContext from "pages/Project/ProjectContext";
// Constant
import { SPACE_CADET } from "constants/colors";
// Skiline config
import { HORIZON_HEIGHT } from "../Skyline/config";

// Styles
const useStyles = makeStyles(() => ({
  wrapper: ({ offsetTop }) => ({
    position: "absolute",
    display: "flex",
    backgroundColor: SPACE_CADET,
    borderRadius: 6,
    color: "#fff",
    padding: 16,
    alignItems: "center",
    width: 150,
    height: 90,
    left: 80,
    top: offsetTop - HORIZON_HEIGHT + 8,
  }),
  containerPercentage: {
    display: "flex",
    flex: "1",
    color: `#FFFFFF`,
    justifyContent: "center",
    alignItems: "flex-end",
    position: "relative",
    fontWeight: "700",
    "& strong": {
      fontSize: 49,
    },
    "& small": {
      top: -12,
      display: "block",
      position: "relative",
      fontSize: 24,
    },
  },
  balloonTriangle: {
    width: "0px",
    height: "0px",
    borderTop: "6px solid transparent",
    borderBottom: "6px solid transparent",
    borderLeft: `6px solid ${SPACE_CADET}`,
    position: "absolute",
    right: 0,
    top: "50%",
    transform: "translate(100%, -50%)",
    zIndex: 5,
  },
  percentageCounter: {
    fontSize: "49px",
  },
}));

const Percentage = ({ offsetTop }) => {
  // Hooks
  const classes = useStyles({ offsetTop });

  // Context
  const {
    percentageAnimated,
    completionPercentage,
    onPercentageAnimation,
  } = useContext(ProjectContext);

  return (
    <div className={classes.wrapper}>
      <div className={classes.containerPercentage}>
        <CountUp
          delay={0.2}
          className={classes.percentageCounter}
          start={percentageAnimated ? completionPercentage : 0}
          useEasing
          end={completionPercentage}
          onEnd={() => onPercentageAnimation(true)}
        />
        <small>%</small>
      </div>
      <div className={classes.balloonTriangle}></div>
    </div>
  );
};

Percentage.defaultProps = {};

Percentage.propTypes = {
  offsetTop: PropTypes.number,
};

export default Percentage;

import React, { useContext, useState } from "react";
import PropTypes from "prop-types";

// Material UI
import { makeStyles } from "@material-ui/core/styles";

// hooks
import { useHistory } from "react-router-dom";

// icons
import homeIcon from "assets/homepage_groove.svg";

// components
import Avatar from "components/Avatar";
import HeaderGlobalIcons from "components/HeaderGlobalIcons";
import CustomSelect from "components/CustomSelect";
import Popper from "components/Popper";
import Breadcrumb from "components/Breadcrumb";

// constants
import { ROOT } from "constants/routes";
import { CORNFLOWER_BLUE, OXFORD_BLUE } from "constants/colors";

// Context
import { AppContext } from "context/AppContext";
import { AuthContext } from "context/AuthContext";

// Layout config
import { mainColConfig } from "components/Layout/config";

// Date
import dayjs from "dayjs";

// Enums
import {
  TimeFilterType,
  TimeFilter,
  Status,
  PeopleCountFilter,
} from "utils/enums/filters";

const useStyles = makeStyles(theme => ({
  headerContainer: ({ padRight, showFilters }) => ({
    position: "sticky",
    backgroundColor: OXFORD_BLUE,
    zIndex: 100,
    top: 0,
    right: 0,
    height: showFilters ? 140 : 100,
    paddingRight: padRight ? mainColConfig.horizontalPadding / 2 : 0,
    display: "flex",
    flexDirection: "column",
  }),
  header: {
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    zIndex: 5,
    padding: "16px 0 10px 0",
    paddingTop: 23,
    paddingLeft: 0,
    transition: "width 0.4s ease",
  },
  fade: {
    width: "100%",
    top: "100%",
    zIndex: 10,
    position: "absolute",
    height: "10px",
    backgroundImage: `linear-gradient(180deg, ${OXFORD_BLUE}, ${CORNFLOWER_BLUE}, transparent)`,
  },
  rightContent: {
    display: "flex",
    alignItems: "center",
  },
  breadcrumb: {
    marginRight: "auto",
    paddingLeft: 0,
  },
  menuIcon: {
    width: "27px",
    height: "27px",
    cursor: "pointer",
  },
  link: {
    textDecoration: "underline",
    color: "white",
    textTransform: "capitalize",
    cursor: "pointer",
  },
  marginLeft: {
    marginLeft: theme.spacing(1),
  },
  separator: {
    margin: `0 ${theme.spacing(1)}px`,
  },

  customSelect: {
    display: "flex",
    maxWidth: "639px",
  },

  select: {
    marginRight: "10px",
  },

  avatar: {
    margin: "10px 0 ",
  },
}));

const Header = ({
  headerIcons,
  padRight,
  showFilters,
  toggleUserProfileDrawer,
}) => {
  const history = useHistory();
  //Context
  const { filters, setFilters } = useContext(AppContext);
  const { user, handleLogOut } = useContext(AuthContext);

  //State
  const [anchorEl, setAnchorEl] = useState(null);

  const classes = useStyles({ padRight, showFilters });

  const onChange = (e, filterKey) => {
    let value = e.target.value;

    let selectedValue = "";

    if (filterKey === "TimeFilterType") {
      if (value === TimeFilterType.FISCAL_YEAR) {
        selectedValue = [String(dayjs().year())];
      } else if (value === TimeFilterType.TO_DATE) {
        selectedValue = Status.LAST_SIX_MONTHS;
      }

      setFilters({
        ...filters,
        TimeFilter: selectedValue,
        [filterKey]: value,
      });
    } else {
      if (filterKey === "TimeFilter" && (value?.length === 0 || value === "")) {
        value = [String(dayjs().year())];
      }

      setFilters({ ...filters, [filterKey]: value });
    }
  };
  const renderSelect = (options, label, filterKey, minWidth, multiple) => {
    return (
      <div className={classes.select}>
        <CustomSelect
          key={"custom-select-" + label}
          options={options}
          label={label}
          // disabled={disabled}
          // topOffset={-10}
          onChange={e => onChange(e, filterKey, multiple)}
          value={filters[filterKey]}
          minWidth={minWidth}
          multiple={multiple}
        />
      </div>
    );
  };
  const dataTime = [
    { value: "100", label: "Nessun filtro" },
    { value: TimeFilterType.FISCAL_YEAR, label: "Anno fiscale" },
    { value: TimeFilterType.TO_DATE, label: "Intervallo" },
  ];

  const dataProject = [
    { value: "100", label: "Tutti" },
    { value: TimeFilter.CREATED, label: "Pianificati" },
    { value: TimeFilter.STARTED, label: "In Corso" },
    { value: TimeFilter.CLOSED, label: "Chiusi" },
  ];

  const dataPeople = [
    { value: "100", label: "Tutti" },
    { value: PeopleCountFilter.PEOPLE_2_5, label: "2 >< 5 persone" },
    { value: PeopleCountFilter.PEOPLE_6_15, label: "6 >< 15 persone" },
    { value: PeopleCountFilter.PEOPLE_16_50, label: "16 >< 50 persone" },
    { value: PeopleCountFilter.PEOPLE_50, label: "> 50 persone" },
  ];

  const dataRange = [
    { value: Status.LAST_SIX_MONTHS, label: "Ultimi 6 mesi" },
    { value: Status.LAST_THREE_MONTHS, label: "Ultimi 3 mesi" },
    { value: Status.YEAR_TO_DATE, label: "Da inizio anno" },
    { value: Status.MONTH_FROM_EARLY, label: "Da inizio mese" },
    { value: Status.YEAR_TO_DATE_MONTH, label: "Da inizio anno a inizio mese" },
  ];

  const range = (start, end) => {
    return Array(end - start + 1)
      .fill()
      .map((_, idx) => start + idx);
  };

  const dataYears = () => {
    let dataList = [];
    const attualYear = dayjs().year();
    const result = [
      ...range(attualYear - 10, attualYear - 1),
      ...range(attualYear, attualYear + 10),
    ];
    result?.map(el => {
      dataList.push({ value: String(el), label: String(el) });
    });
    return dataList;
  };

  const popperItems = [
    {
      onClick: () => {
        setAnchorEl(null);
        toggleUserProfileDrawer();
      },
      text: "Profilo",
    },
    {
      onClick: () => {
        setAnchorEl(null);
        handleLogOut();
      },
      text: "Logout",
    },
  ];
  const open = Boolean(anchorEl);
  const id = open ? "user-popper" : undefined;
  return (
    <div className={classes.headerContainer}>
      <div className={classes.header}>
        <img
          src={homeIcon}
          className={classes.menuIcon}
          onClick={() => history.push(ROOT)}
        />
        <div className={classes.breadcrumb}>
          <Breadcrumb />
        </div>

        <div className={classes.rightContent}>
          <HeaderGlobalIcons headerIcons={headerIcons} />
          <div
            onMouseEnter={e => setAnchorEl(anchorEl ? null : e.currentTarget)}
            onMouseLeave={() => setAnchorEl(null)}
          >
            <Avatar
              image={user?.imageUrl}
              size={"40px"}
              onClick={() => {}}
              className={classes.avatar}
            />
            <Popper
              id={id}
              open={open}
              placement="bottom-end"
              anchorEl={anchorEl}
            >
              {popperItems}
            </Popper>
          </div>
        </div>
      </div>
      {showFilters && (
        <div className={classes.customSelect}>
          {renderSelect(dataTime, "TIME", "TimeFilterType")}
          {filters?.TimeFilterType &&
            filters?.TimeFilterType !== "100" &&
            renderSelect(
              filters?.TimeFilterType === "0" ? dataYears() : dataRange,
              "TIME RANGE",
              "TimeFilter",
              "194px",
              filters?.TimeFilterType === "0" ? true : false
            )}
          {renderSelect(dataProject, "PROJECT", "Status")}
          {renderSelect(dataPeople, "DEPARTMENT", "PeopleCountFilter")}
        </div>
      )}
      <div className={classes.fade}></div>
    </div>
  );
};

Header.propTypes = {
  headerIcons: PropTypes.array,
  padRight: PropTypes.bool,
  toggleUserProfileDrawer: PropTypes.func,
  showFilters: PropTypes.bool,
};

export default Header;

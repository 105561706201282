import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

//colors
import {
  MALACHITE,
  HARVARD_CRIMSON,
  WILD_BLUE_YONDER,
  DARK_ORANGE,
} from "constants/colors";
import { Typography } from "@material-ui/core";
import NumberFormatCustom from "components/NumberFormat";

const useStyles = makeStyles(() => ({
  wrapper: ({ alignItems, orientation }) => ({
    display: "flex",
    flexDirection: orientation,
    alignItems: alignItems,
    padding: orientation === "column" ? "5px 0 5px 0" : "0 12px 0 0",
    textAlign: "right",
  }),
  kpiDo: ({ alignItems }) => ({
    fontSize: "1rem",
    fontWeight: "bold",
    color: WILD_BLUE_YONDER,
    marginRight: alignItems === "baseline" ? "4px" : 0,
  }),
  kpiSlightDelay: ({ alignItems }) => ({
    fontSize: "1rem",
    fontWeight: "bold",
    color: DARK_ORANGE,
    marginRight: alignItems === "baseline" ? "4px" : 0,
  }),
  kpiDoPositive: ({ alignItems }) => ({
    fontSize: "1rem",
    fontWeight: "bold",
    color: MALACHITE,
    marginRight: alignItems === "baseline" ? "4px" : 0,
  }),
  kpiDoNegative: ({ alignItems }) => ({
    fontSize: "1rem",
    fontWeight: "bold",
    color: HARVARD_CRIMSON,
    marginRight: alignItems === "baseline" ? "4px" : 0,
  }),
  label: ({ alignItems }) => ({
    color: WILD_BLUE_YONDER,
    textTransform: "uppercase",
    lineHeight: props => (props.orientation === "column" ? 0.6 : undefined),
    marginLeft: alignItems === "flex-end" ? 5 : 0,
  }),
  labelIn: { fontSize: "15px", fontWeight: 600 },
}));

const KpiDo = props => {
  const {
    alignItems,
    numberClassname,
    labelClassname,
    label,
    number,
    orientation,
  } = props;
  const classes = useStyles({ alignItems, orientation });

  //QUALITY KPI
  let classQuality = clsx(numberClassname, classes.kpiDo); // n/a and 3.5< n <4.4
  if (number > 0 && number <= 3.4) {
    classQuality = clsx(numberClassname, classes.kpiDoNegative);
  } else if (number >= 4.5 && number <= 5) {
    classQuality = clsx(numberClassname, classes.kpiDoPositive);
  }

  //TIME KPI
  let classTime = clsx(numberClassname, classes.kpiDo); // In time and 2.9<= n <=-4.9
  let inTime = false;
  if (number >= 5) {
    classTime = clsx(numberClassname, classes.kpiDoNegative);
  } else if (number >= 3 && number < 5) {
    classTime = clsx(numberClassname, classes.kpiSlightDelay);
  } else if (number <= -5) {
    classTime = clsx(numberClassname, classes.kpiDoPositive);
  } else {
    inTime = true;
  }

  //COST KPI
  let classCost = clsx(numberClassname, classes.kpiDo);
  if (number <= -5) {
    classCost = clsx(numberClassname, classes.kpiDoPositive);
  } else if (number >= 2 && number < 5) {
    classCost = clsx(numberClassname, classes.kpiSlightDelay);
  } else if (number >= 5) {
    classCost = clsx(numberClassname, classes.kpiDoNegative);
  }
  const prefix = number === 0 ? "" : number > 0 ? "+" : "-";
  return (
    <div className={classes.wrapper}>
      <Typography
        // variant={orientation === "column" ? "subtitle1" : "h6"}
        className={
          label === "quality"
            ? classQuality
            : label === "cost"
            ? classCost
            : classTime
        }
      >
        {label === "quality" && number === 0 ? (
          <Typography
            className={numberClassname || classes?.labelIn}
            variant={"subtitle1"}
            style={{ marginRight: 0 }}
          >
            {"n/a"}
          </Typography>
        ) : label === "quality" ? (
          <NumberFormatCustom
            value={number}
            fixedDecimalScale={1}
            decimalScale={1}
          />
        ) : label === "cost" ? (
          <NumberFormatCustom
            value={Math.abs(number)}
            prefix={prefix}
            suffix={"%"}
            fixedDecimalScale={number % 1 != 0}
            decimalScale={1}
          />
        ) : label === "time" && inTime ? (
          <Typography
            className={numberClassname || classes?.labelIn}
            variant={"subtitle1"}
            style={{ marginRight: 0 }}
          >
            {"in"}
          </Typography>
        ) : (
          <NumberFormatCustom
            value={Math.abs(number)}
            suffix={"gg"}
            prefix={prefix}
            fixedDecimalScale={number % 1 != 0}
            decimalScale={1}
          />
        )}
      </Typography>
      <Typography
        className={clsx(labelClassname, classes.label)}
        variant="subtitle1"
      >
        {label}
      </Typography>
    </div>
  );
};

KpiDo.defaultProps = {
  alignItems: "flex-end",
};

KpiDo.propTypes = {
  alignItems: PropTypes.string,
  numberClassname: PropTypes.string,
  labelClassname: PropTypes.string,
  label: PropTypes.string,
  number: PropTypes.number,
  orientation: PropTypes.string,
  txtSize: PropTypes.string,
  isClosed: PropTypes.bool,
};

export default KpiDo;

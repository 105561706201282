import React, { useEffect, useState, useContext } from "react";

// Hooks
import useFetch from "hooks/useHTTP";
import useDrawer from "hooks/useDrawer";
import { useForm, FormProvider } from "react-hook-form";

// Contexts
import { AppContext } from "context/AppContext";

// // Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

// Config
import { jobTitleConfig } from "components/Table/config";

// Assets
import { ReactComponent as EditIcon } from "assets/edit.svg";
import { ReactComponent as DeleteIcon } from "assets/delete-outlined.svg";
import plusIcon from "assets/plus-white.png";

// Components
import Button from "components/Button";
import Table from "components/Table";
import Layout from "components/Layout";
import Drawer from "components/Drawer";
import Loading from "components/Loading";
import JobTitleForm from "./components/JobTitleForm";
import Navigation from "../components/Navigation";

// Constants
import { JOB_TITLE } from "constants/api";

// Styles
const useStyles = makeStyles(theme => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(4),
  },
  buttonIcon: {
    width: 16,
    height: 16,
  },
  loading: {
    height: "40vh",
  },
  navigation: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    margin: "50px 0px",
  },
  backButton: {
    display: "flex",
    alignItems: "center",
  },
}));

const SettingsJobTitles = () => {
  const classes = useStyles({});
  const { get, put, post, del } = useFetch();
  const { setModalConfig, setGlobalLoading } = useContext(AppContext);
  const [jobTitles, setJobTitles] = useState([]);
  const [loadingJobTitles, setLoadingJobTitles] = useState(false);
  const [loadingDrawer, setLoadingDrawer] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const jobTitleFormMethods = useForm({ defaultValues: { name: "" } });

  //Drawers state
  const {
    isDrawerOpen: isDrawerOpen,
    setIsDrawerOpen: setDrawerOpen,
    toggleDrawer: toggleDrawer,
  } = useDrawer();

  const fetchJobTitles = async () => {
    setLoadingJobTitles(true);
    await get(JOB_TITLE)
      .then(response => {
        setLoadingJobTitles(false);
        if (response.ok) setJobTitles(response.data);
      })
      .catch(() => {
        setLoadingJobTitles(false);
        setErrorMessage("Elaborazione non riuscita");
      });
  };

  const fetchJobTitle = async id => {
    setLoadingDrawer(true);
    await get(`${JOB_TITLE}/${id}`)
      .then(res => {
        if (res.ok) {
          setSelectedItem(res?.data);
          setLoadingDrawer(false);
        }
      })
      .catch(() => {
        setLoadingDrawer(false);
      });
  };

  const deleteJobTitle = async id => {
    setGlobalLoading(true);
    setModalConfig(null);
    await del(`${JOB_TITLE}/${id}`)
      .then(async res => {
        if (res.ok) {
          await fetchJobTitles();
          setGlobalLoading(false);
        }
      })
      .catch(() => {
        setGlobalLoading(false);
      });
  };

  const handleJobTitleDelete = id => {
    setModalConfig({
      title: "Eliminazione Job Title",
      content: "Sei sicuro di voler eliminare questo job title?",
      primaryAction: {
        text: "OK",
        onClick: () => deleteJobTitle(id),
      },
      secondaryAction: {
        text: "ANNULLA",
      },
    });
  };

  const handleClosing = () => {
    setErrorMessage("");
    setSelectedItem();
    toggleDrawer();
    jobTitleFormMethods.reset();
    setLoadingDrawer(false);
  };

  const onSubmit = async data => {
    setLoadingDrawer(true);
    if (selectedItem) {
      await put(`${JOB_TITLE}/${selectedItem.id}`, data)
        .then(response => {
          if (response.ok) {
            handleClosing();
            fetchJobTitles();
          }
        })
        .catch(() => {
          setLoadingDrawer(false);
          setErrorMessage("Elaborazione non riuscita");
        });
    } else {
      await post(JOB_TITLE, data)
        .then(response => {
          if (response.ok) {
            handleClosing();
            fetchJobTitles();
          }
        })
        .catch(() => {
          setLoadingDrawer(false);
          setErrorMessage("Elaborazione non riuscita");
        });
    }
  };

  useEffect(() => {
    fetchJobTitles();
  }, []);

  return (
    <Layout showSecondCol={false} showHeader={false} showPosts={false}>
      <Navigation />
      <div className={classes.header}>
        <Typography variant="h5">Job title</Typography>
        <Button
          color="primary"
          variant="contained"
          startIcon={<img src={plusIcon} className={classes.buttonIcon} />}
          onClick={() => setDrawerOpen(true)}
        >
          Nuovo job title
        </Button>
      </div>
      {loadingJobTitles ? (
        <div className={classes.loading}>
          <Loading showWrapper={false} />
        </div>
      ) : (
        <Table
          data={jobTitles}
          config={jobTitleConfig}
          rowActions={[
            {
              onClick: item => {
                fetchJobTitle(item?.id);
                setDrawerOpen(true);
              },
              icon: <EditIcon />,
            },
            {
              onClick: item => handleJobTitleDelete(item?.id),
              icon: <DeleteIcon />,
            },
          ]}
        />
      )}
      <FormProvider {...jobTitleFormMethods}>
        <Drawer
          open={isDrawerOpen}
          title={
            !loadingDrawer &&
            (selectedItem ? "Modifica Job Title" : "Nuovo Job Title")
          }
          primaryText={!loadingDrawer && "SALVA"}
          secondaryText={!loadingDrawer && "ANNULLA"}
          onPrimary={jobTitleFormMethods.handleSubmit(onSubmit)}
          onSecondary={() => handleClosing()}
          errorMessage={errorMessage}
        >
          <JobTitleForm data={selectedItem} loading={loadingDrawer} />
        </Drawer>
      </FormProvider>
    </Layout>
  );
};

export default SettingsJobTitles;

import React from "react";
import PropTypes from "prop-types";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
// Components
import Date from "components/Date";
import HorizonSeparator from "../HorizonSeparator";
import KpiDo from "components/KpiDo";
// Constants
import { WILD_BLUE_YONDER } from "constants/colors";
import { Typography } from "@material-ui/core";
// Shapes
import { KpisShape } from "./shapes";

// Styles
const useStyles = makeStyles(() => ({
  wrapper: {
    padding: 15,
    width: 300,
    height: "100%",
    position: "relative",
  },
  title: {
    "&:first-letter": {
      textTransform: "capitalize",
    },
  },
  owner: {
    fontSize: 13,
    color: WILD_BLUE_YONDER,
  },
  metadata: {
    display: "flex",
    marginTop: 8,
  },
  endDate: {
    fontSize: 12,
    flexGrow: 0,
    textTransform: "capitalize",
  },
  endDateLabel: {
    fontSize: 11,
    marginTop: 3,
    color: WILD_BLUE_YONDER,
  },
  kpis: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
    marginTop: -9,
    alignItems: "center",
  },
  kpiWrapper: {
    marginLeft: 8,
  },
}));

const HorizonPhase = ({ endDate, kpis, owner, title }) => {
  // Hooks
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <Typography variant="h5" className={classes.title}>
        {title}
      </Typography>
      <div className={classes.owner}>{owner}</div>
      <div className={classes.metadata}>
        <div>
          <Date className={classes.endDate} dateString={endDate} />
          <div className={classes.endDateLabel}>End date</div>
        </div>
        <div className={classes.kpis}>
          <div className={classes.kpiWrapper}>
            <KpiDo number={kpis?.time} label="time" orientation="column" />
          </div>
          <div className={classes.kpiWrapper}>
            <KpiDo number={kpis?.cost} label="cost" orientation="column" />
          </div>
          <div className={classes.kpiWrapper}>
            <KpiDo
              number={kpis?.quality}
              label="quality"
              orientation="column"
            />
          </div>
        </div>
      </div>
      <HorizonSeparator right />
    </div>
  );
};

HorizonPhase.propTypes = {
  endDate: PropTypes.string,
  kpis: PropTypes.shape(KpisShape),
  owner: PropTypes.string,
  title: PropTypes.string,
};

export default HorizonPhase;

import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ReactPlayer from "react-player";
import PropTypes from "prop-types";
import { Slider } from "@material-ui/core";
import usePlayer from "hooks/usePlayer";

// Icons
import playIcon from "assets/play.svg";
import pauseIcon from "assets/pause.svg";
import fullscreenIcon from "assets/fullscreen.svg";
import fullscreenExitIcon from "assets/fullscreen-exit.svg";

const useStyles = makeStyles(() => ({
  videoWrapper: ({ isPlaying, customVideoHeight, isFullScreen }) => ({
    position: "relative",
    height: isFullScreen
      ? "100vh"
      : customVideoHeight
      ? customVideoHeight
      : 150,
    width: isFullScreen ? "100vw" : "auto",
    background: isFullScreen ? "black" : "auto",
    "& img": isPlaying && {
      visibility: "hidden",
      opacity: 0,
      transition: `visibility 0s, opacity 0.25s linear`,
    },
    "& .MuiSlider-root": isPlaying && {
      visibility: "hidden",
      opacity: 0,
      transition: `visibility 0s, opacity 0.25s linear`,
    },
    "&:hover > img": {
      visibility: "visible",
      opacity: 1,
    },
    "&:hover > .MuiSlider-root": {
      visibility: "visible",
      opacity: 1,
    },
  }),
  playIcon: {
    position: "absolute",
    top: "calc(50% - 22.5px)",
    marginLeft: "auto",
    marginRight: "auto",
    left: 0,
    right: 0,
    width: 45,
    height: 45,
    cursor: "pointer",
  },
  slider: {
    position: "absolute",
    bottom: 0,
    width: "90%",
    marginLeft: "auto",
    marginRight: "auto",
    left: 0,
    right: 0,
  },
  spectrum: {
    width: "100%",
  },
  mark: {
    fontSize: "0.78rem",
    color: "white",
  },
  fullScreenIcon: {
    height: 20,
    width: 20,
    position: "absolute",
    bottom: 40,
    right: 30,
    cursor: "pointer",
  },
}));

const toMMSS = amount => {
  var sec_num = parseInt(amount, 10);
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - hours * 3600) / 60);
  var seconds = sec_num - hours * 3600 - minutes * 60;

  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return minutes + ":" + seconds;
};

const VideoPlayer = ({
  content,
  customVideoHeight,
  enableFullscreen,
  autoStart,
  hidePlay,
  hideControls,
}) => {
  const [media] = useState(content);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [firstTime, setIsFirstTime] = useState(true);
  const mediaPlayer = usePlayer([media]);
  console.log("🚀 ~ file: index.js ~ line 106 ~ mediaPlayer", mediaPlayer);
  const classes = useStyles({
    isPlaying: mediaPlayer.playing,
    customVideoHeight,
    isFullScreen,
  });

  // Effects
  useEffect(() => {
    if (autoStart && !mediaPlayer?.playing && firstTime) {
      mediaPlayer.onPlay();
      setIsFirstTime(false);
    }
  }, [autoStart, mediaPlayer]);

  // Functions
  const toggleFullScreen = () => {
    const nextIsFullScreen = !isFullScreen;
    setIsFullScreen(nextIsFullScreen);
  };

  return (
    <div className={classes.videoWrapper}>
      <ReactPlayer
        width="100%"
        height="100%"
        ref={mediaPlayer.player}
        url={content}
        playing={mediaPlayer.playing}
        onStart={mediaPlayer.onStart}
        onPause={mediaPlayer.onPause}
        onProgress={mediaPlayer.onProgress}
        onDuration={mediaPlayer.onDuration}
        onEnded={mediaPlayer.onEnded}
      />
      {hidePlay ? null : (
        <img
          src={mediaPlayer.playing ? pauseIcon : playIcon}
          className={classes.playIcon}
          onClick={() =>
            mediaPlayer.playing ? mediaPlayer.onPause() : mediaPlayer.onPlay()
          }
        />
      )}
      {hideControls ? null : (
        <Slider
          className={classes.slider}
          classes={{ markLabel: classes.mark }}
          value={
            typeof mediaPlayer.progressSeconds === "number" &&
            typeof mediaPlayer.durationSeconds === "number"
              ? (mediaPlayer.progressSeconds / mediaPlayer.durationSeconds) *
                100
              : 0
          }
          onChange={(event, value) => {
            mediaPlayer.onSeek(event, value);
          }}
          marks={[
            {
              value: 0.1,
              label: toMMSS(mediaPlayer.progressSeconds) || "0.00",
            },
            {
              value: 100,
              label: toMMSS(mediaPlayer.durationSeconds),
            },
          ]}
        />
      )}
      {enableFullscreen ? (
        <img
          src={isFullScreen ? fullscreenExitIcon : fullscreenIcon}
          className={classes.fullScreenIcon}
          onClick={toggleFullScreen}
        />
      ) : null}
    </div>
  );
};

VideoPlayer.propTypes = {
  content: PropTypes.string,
  customVideoHeight: PropTypes.number,
  enableFullscreen: PropTypes.bool,
  autoStart: PropTypes.bool,
  hidePlay: PropTypes.bool,
  hideControls: PropTypes.bool,
};

export default VideoPlayer;

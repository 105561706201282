import React from "react";
import PropTypes from "prop-types";
import theme from "theme";

// Material UI components
import { TextField, CircularProgress } from "@material-ui/core";
const Input = ({ themeType, label, loading, ...other }) => {
  return (
    <TextField
      {...other}
      label={label}
      variant="outlined"
      InputLabelProps={{
        style: {
          color:
            themeType === "dark"
              ? theme.palette.componentsBackground.light
              : theme.palette.componentsBackground.main,
        },
      }}
      InputProps={{
        ...other.InputProps,
        endAdornment: (
          <>
            {loading ? <CircularProgress color="inherit" size={20} /> : null}
            {other.InputProps.endAdornment}
          </>
        ),
        style: {
          color:
            themeType === "dark"
              ? theme.palette.componentsBackground.light
              : theme.palette.common.black,
          backgroundColor:
            themeType === "dark"
              ? theme.palette.componentsBackground.main
              : theme.palette.componentsBackground.light,
        },
      }}
    />
  );
};

Input.propTypes = {
  themeType: PropTypes.string,
  label: PropTypes.string,
  loading: PropTypes.bool,
};

Input.defaultProps = {
  themeType: "dark",
};

export default Input;

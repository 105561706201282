import React, { useState, useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import useFetch from "hooks/useHTTP";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import dayjs from "dayjs";
import { useHistory, useParams } from "react-router-dom";
import { Storage } from "aws-amplify";
import useQueryString from "hooks/useQueryString";
// Components
import { Grid, Typography } from "@material-ui/core";
import Layout from "components/Layout";
import TextField from "components/TextField";
import NumberTextField from "components/NumberFormatTextField";
import ImageUploader from "components/ImageUploader";
import Autocomplete from "components/Autocomplete";
import Button from "components/Button";
import Select from "components/Select";
// Contexts
import { AppContext } from "context/AppContext";
import { AuthContext } from "context/AuthContext";
// Icons
import { ReactComponent as DeleteIcon } from "assets/delete-icon.svg";
import { USERS_LIST, TAGS, CUSTOMERS, PROJECT } from "constants/api";
// Constants
import { projectImagesPrefix } from "constants/api";
import { ROOT } from "constants/routes";

const useStyles = makeStyles(theme => ({
  gridContainer: {
    margin: `${theme.spacing(1)}px 0`,
  },
  button: {
    marginLeft: theme.spacing(2),
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
  },
  paragraph: {
    width: "100%",
    borderBottom: "1px solid white",
  },
}));

const Planner = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const { portfolioId } = useQueryString();
  const { setModalConfig, setGlobalLoading, marginTarget } =
    useContext(AppContext);
  const { user } = useContext(AuthContext);

  const defaultValues = {
    type: 1,
    code: "",
    name: "",
    user,
    supervisors: [],
    customer: null,
    customerReference: null,
    mailCustomerReference: "",
    phoneCustomerReference: "",
    tags: [],
    budget: "",
    pricing: "",
    expectedMargin: !id && marginTarget ? marginTarget : "",
    competenceYear: "",
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    register,
    watch,
    clearErrors,
  } = useForm({ defaultValues });
  const { fields, prepend, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "costs", // unique name for your Field Array
  });
  const { get, put, post } = useFetch();
  const [files, setFiles] = useState([]);
  const [years, setYears] = useState([]);
  const [blurredImage, setBlurredImage] = useState(true);
  const [imageUrl, setImageUrl] = useState();
  const [customerReferences, setCustomerReferences] = useState([]);
  const [projectName, setProjectName] = useState();
  /* const [uploadProgress, setUploadProgress] = useState(0); */

  const projectTypes = [
    { value: "1", name: "Commessa" },
    { value: "2", name: "Investimento" },
  ];

  const availableYears = () => {
    let years = [];
    for (let i = dayjs().year() - 10; i <= dayjs().year() + 10; i++) {
      years.push({ value: i, name: `${i}` });
    }
    setYears(years);
  };

  const setFormFields = data => {
    setValue("type", data.type.toString());
    setValue("code", data.code);
    setValue("name", data.name);
    setValue("tags", data.tagsList || []);
    setValue("user", data.user);
    setValue("supervisors", data.supervisorsUsers || []);
    setValue("customer", data.customer);
    setCustomerReferences(data.customer?.references);
    setValue("customerReference", data.customerReference);
    setValue("mailCustomerReference", data.customerReference?.email);
    setValue("phoneCustomerReference", data.customerReference?.number);
    setValue("budget", data.budget);
    setValue("pricing", data.pricing);
    setValue("expectedMargin", data.expectedMargin);
    setValue(
      "competenceYear",
      years.length > 0 &&
        years.find(year => year.value === data.competenceYear).value
    );
    setValue("costs", data.costs);
    setImageUrl(data.imageUrl);
    setBlurredImage(data.blurredImage);

    setGlobalLoading(false);
  };

  const fetchPlannerDetails = async () => {
    setGlobalLoading(true);
    await get(`${PROJECT}/${id}`)
      .then(response => {
        setFormFields(response.data);
        setProjectName(response.data.name);
      })
      .catch(e => {
        setGlobalLoading(false);
        console.log("e", e);
      });
  };

  useEffect(() => {
    setValue("type", "1");
    if (!portfolioId && !id) history.push(ROOT);
    availableYears();
  }, []);

  useEffect(() => {
    if (years.length > 0 && id) {
      fetchPlannerDetails();
    }
  }, [id, years]);

  const watchType = watch("type", defaultValues.type);

  const prepareDataToSend = async data => {
    // Prepare data to send
    const updatedData = { ...data };
    updatedData.type = +data.type;
    updatedData.portfolioId = +portfolioId;
    updatedData.userId = data.user.id;
    if (typeof data.customer === "string") {
      updatedData.customer = { name: data.customer };
    } else {
      updatedData.customerId = data?.customer?.id;
      delete updatedData.customer;
    }
    const supervisorsIds = [];
    updatedData.supervisors &&
      updatedData.supervisors.forEach(element => {
        supervisorsIds.push(element?.id);
      });
    updatedData.supervisors =
      supervisorsIds.length > 0 ? supervisorsIds.join(",") : null;
    if (data.customerReference) {
      if (typeof data.customerReference === "string") {
        updatedData.customerReference = {
          name: data.customerReference,
          email: data.mailCustomerReference,
          number: data.phoneCustomerReference,
        };
      } else {
        updatedData.customerReferenceId = data.customerReference.id;
        delete updatedData.customerReference;
      }
    }
    updatedData.tags = data.tags.length > 0 ? data.tags.join(",") : null;
    updatedData.pricing = parseFloat(data.pricing);
    updatedData.budget = parseFloat(data.budget);
    updatedData.expectedMargin =
      updatedData.type === 2 /*Investiment*/
        ? undefined
        : /* Commission */ (updatedData.expectedMargin = parseInt(
            data.expectedMargin,
            10
          ));
    updatedData.costs.forEach(cost => {
      cost.value = parseFloat(cost.value);
    });
    delete updatedData.phoneCustomerReference;
    delete updatedData.user;

    const apiCall = id ? put : post;
    const endpoint = id ? `${PROJECT}/${id}` : PROJECT;

    await apiCall(endpoint, updatedData)
      .then(response => {
        setImageUrl(response.data.imageUrl);
        setModalConfig({
          type: "success",
          primaryAction: {
            text: "OK",
            onClick: () => {
              setModalConfig(null);
              history.push(`/planner/${response.data.id || id}/builder`);
            },
          },
        });
      })
      .catch(() =>
        setModalConfig({
          type: "error",
          primaryAction: {
            text: "OK",
            onClick: () => {
              setModalConfig(null);
            },
          },
        })
      );
  };

  const handleUpload = async data => {
    const bucket = process.env.REACT_APP_BUCKET_PROJECTS;

    await Storage.put(`images/${files[0].name}`, files[0], {
      bucket,
    })
      .then(res => {
        const updatedData = { ...data };
        updatedData.imageUrl = `${projectImagesPrefix}${res.key}`;
        updatedData.blurredImage = blurredImage;
        if (imageUrl) {
          const newImageFileName = res.key.split("/").pop();
          const currentImageFileName = imageUrl.split("/").pop();
          if (newImageFileName !== currentImageFileName)
            Storage.remove(`images/${currentImageFileName}`, { bucket });
        }
        prepareDataToSend(updatedData);
      })
      .catch(() =>
        setModalConfig({
          type: "error",
          primaryAction: {
            text: "OK",
            onClick: () => {
              setModalConfig(null);
            },
          },
        })
      );
  };

  const onSubmit = data => {
    setModalConfig({
      type: "loading",
    });

    files.length > 0
      ? handleUpload(data)
      : prepareDataToSend({ ...data, imageUrl, blurredImage });
  };

  const addCost = () => {
    prepend({ value: undefined, description: "" });
  };

  const deleteCost = index => {
    remove(index);
  };

  return (
    <Layout showSecondCol={false}>
      <form>
        <Grid className={classes.gridContainer} container spacing={2}>
          <Grid item xs>
            <div className={classes.header}>
              <Typography variant="h5">
                {id ? projectName : "Crea Obiettivo"}
              </Typography>
              <div>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                  onClick={() => history.goBack()}
                >
                  Annulla
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.button}
                  onClick={handleSubmit(onSubmit)}
                >
                  Salva
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid className={classes.gridContainer} container spacing={2}>
          <Grid item xs>
            <Controller
              render={({ field }) => (
                <Select
                  label="Tipo progetto"
                  data={projectTypes}
                  error={!!errors.type}
                  required
                  disabled={id}
                  {...field}
                />
              )}
              name="type"
              control={control}
              rules={{ required: true }}
            />
          </Grid>
          <Grid item xs>
            <Controller
              render={({ field }) => (
                <TextField
                  label="Codice progetto"
                  shrink={!!field.value}
                  {...field}
                />
              )}
              name="code"
              control={control}
            />
          </Grid>
          <Grid item xs={6} xl={6}>
            <Controller
              render={({ field }) => (
                <TextField
                  label="Nome progetto"
                  error={!!errors.name}
                  shrink={!!field.value}
                  required
                  {...field}
                />
              )}
              name="name"
              control={control}
              rules={{ required: true }}
            />
          </Grid>
        </Grid>
        <Grid className={classes.gridContainer} container spacing={2}>
          <Grid item xs>
            <Controller
              render={({ field }) => (
                <Autocomplete
                  label="Owner di progetto"
                  endpoint={`${USERS_LIST}?me=true&q`}
                  error={!!errors.user}
                  getOptionLabel={option =>
                    option?.name && option?.surname
                      ? `${option?.name} ${option?.surname}`
                      : ""
                  }
                  required
                  {...field}
                  onChange={(_, data) => field.onChange(data)}
                />
              )}
              name="user"
              control={control}
              rules={{ required: true }}
            />
          </Grid>
          <Grid item xs={9} xl={9}>
            <Controller
              render={({ field }) => (
                <Autocomplete
                  label="Supervisori"
                  multiple
                  endpoint={`${USERS_LIST}?me=true&q`}
                  getOptionLabel={option =>
                    option?.name && option?.surname
                      ? `${option?.name} ${option?.surname}`
                      : null
                  }
                  {...field}
                  onChange={(_, data) => field.onChange(data)}
                />
              )}
              name="supervisors"
              control={control}
            />
          </Grid>
        </Grid>
        <Grid className={classes.gridContainer} container spacing={2}>
          <Grid item xs>
            <Controller
              render={({ field }) => (
                <Autocomplete
                  freeSolo
                  label="Nome cliente"
                  endpoint={`${CUSTOMERS}?q`}
                  getOptionLabel={option => option.name || option}
                  required
                  error={!!errors.customer}
                  {...field}
                  onChange={(_, data) => {
                    setCustomerReferences(data ? data.references : []);
                    field.onChange(data);
                    setValue("customerReference", undefined);
                    setValue("mailCustomerReference", undefined);
                    setValue("phoneCustomerReference", undefined);
                  }}
                />
              )}
              name="customer"
              control={control}
              rules={{ required: true }}
            />
          </Grid>
          <Grid item xs>
            <Controller
              render={({ field }) => (
                <Autocomplete
                  freeSolo
                  label="Referente cliente"
                  options={customerReferences}
                  getOptionLabel={option => option.name || option}
                  {...field}
                  onChange={(event, data) => {
                    setValue("mailCustomerReference", data ? data.email : null);
                    setValue(
                      "phoneCustomerReference",
                      data ? data.number : null
                    );
                    clearErrors([
                      "mailCustomerReference",
                      "phoneCustomerReference",
                    ]);

                    field.onChange(data);
                  }}
                />
              )}
              name="customerReference"
              control={control}
            />
          </Grid>
          <Grid item xs>
            <Controller
              render={({ field, fieldState }) => (
                <TextField
                  label="Mail referente cliente"
                  shrink={!!field.value}
                  error={fieldState.error}
                  helperText={fieldState.error && fieldState.error.message}
                  disabled={
                    getValues("customerReference") === undefined ||
                    !getValues("customerReference") ||
                    typeof getValues("customerReference") !== "string"
                  }
                  {...field}
                />
              )}
              name="mailCustomerReference"
              control={control}
              rules={{
                pattern: {
                  value:
                    // eslint-disable-next-line max-len
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: "Email non valida",
                },
              }}
            />
          </Grid>
          <Grid item xs>
            <Controller
              render={({ field }) => (
                <TextField
                  label="Telefono referente cliente"
                  type="tel"
                  shrink={!!field.value}
                  disabled={
                    !getValues("customerReference") ||
                    typeof getValues("customerReference") !== "string"
                  }
                  {...field}
                />
              )}
              name="phoneCustomerReference"
              control={control}
            />
          </Grid>
        </Grid>
        {/* {loading ? (
        <div>Uploading... {uploadProgress}%</div>
      ) : (
        <> */}
        <Grid className={classes.gridContainer} container spacing={2}>
          <Grid item xs>
            <ImageUploader
              selectedFiles={files}
              onSelect={setFiles}
              onBlurChange={setBlurredImage}
              blurrable
              blurActive={blurredImage}
              imageUrl={imageUrl}
            />
          </Grid>
        </Grid>
        <Grid className={classes.gridContainer} container spacing={2}>
          <Grid item xs={12} xl={12}>
            <Controller
              render={({ field }) => (
                <Autocomplete
                  freeSolo
                  multiple
                  endpoint={`${TAGS}?q`}
                  label="Tag progetto"
                  getOptionLabel={option => option}
                  {...field}
                  onChange={(_, data) => field.onChange(data)}
                />
              )}
              name="tags"
              control={control}
            />
          </Grid>
        </Grid>
        <div className={classes.paragraph}>
          <Typography variant="h5">Economics</Typography>
        </div>
        <Grid className={classes.gridContainer} container spacing={2}>
          <Grid item xs={3} xl={3}>
            {watchType === "1" ? (
              <Controller
                render={({ field }) => (
                  <NumberTextField
                    required
                    error={!!errors.pricing}
                    label="Pricing EUR"
                    icon="€"
                    {...field}
                  />
                )}
                name="pricing"
                control={control}
                rules={{ required: true }}
              />
            ) : (
              <Controller
                render={({ field }) => (
                  <NumberTextField
                    required
                    error={!!errors.budget}
                    label="Budget"
                    icon="€"
                    {...field}
                  />
                )}
                name="budget"
                control={control}
                rules={{ required: true }}
              />
            )}
          </Grid>
          {watchType === "1" && (
            <Grid item xs={3} xl={3}>
              <Controller
                render={({ field, fieldState }) => (
                  <TextField
                    label="Margine target"
                    icon="%"
                    type="number"
                    error={fieldState.error}
                    helperText={fieldState.error && fieldState.error.message}
                    inputProps={{ min: 0, max: 100 }}
                    required
                    {...field}
                  />
                )}
                name="expectedMargin"
                control={control}
                rules={{
                  required: true,
                  min: { value: 0, message: "Inserire un valore tra 0 e 100" },
                  max: {
                    value: 100,
                    message: "Inserire un valore tra 0 e 100",
                  },
                }}
              />
            </Grid>
          )}
          <Grid item xs={3} xl={3}>
            <Controller
              render={({ field }) => (
                <Select
                  label="Anno competenze"
                  data={years}
                  showEmpty
                  error={!!errors.competenceYear}
                  required
                  {...field}
                />
              )}
              name="competenceYear"
              control={control}
              rules={{ required: true }}
            />
          </Grid>
        </Grid>
        <Typography variant="h6">Altri costi</Typography>

        <Button variant="outlined" color="primary" onClick={addCost}>
          + costo
        </Button>
        {fields.map((field, index) => (
          <Grid
            key={field.id}
            className={classes.gridContainer}
            container
            spacing={2}
          >
            <Grid item xs={3} xl={3}>
              <Controller
                render={({ field }) => (
                  <NumberTextField
                    label="Importo"
                    icon="€"
                    required
                    error={errors.costs && errors.costs[index]?.value}
                    {...field}
                  />
                )}
                name={`costs.${index}.value`}
                control={control}
                rules={{
                  required: true,
                }}
                defaultValue={0}
              />
            </Grid>
            <Grid item xs={8} xl={8}>
              <TextField
                label="Descrizione"
                required
                {...register(`costs.${index}.description`, {
                  required: true,
                })}
                defaultValue={field.value}
                error={errors.costs && errors.costs[index]?.description}
              />
            </Grid>
            <Grid item xs={1} xl={1}>
              <Button
                className="iconButton"
                variant="outlined"
                color="secondary"
                onClick={() => deleteCost(index)}
                icon={<DeleteIcon />}
              />
            </Grid>
          </Grid>
        ))}
      </form>
    </Layout>
  );
};

export default Planner;

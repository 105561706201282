import React, { useMemo, useContext } from "react";
import PropTypes from "prop-types";

import FetchContext from "./FetchContext";
import { BASE_URL as url } from "constants/hosts";
import { AuthContext } from "context/AuthContext";
import { useHttpOptions } from ".";

export const FetchProvider = ({ children }) => {
  const {
    user,
    handleLogOut,
    autoLogin,
    sessionToken,
    getValidToken,
  } = useContext(AuthContext);

  const defaults = useMemo(
    () => ({
      url,
      options:
        useHttpOptions(
          user,
          handleLogOut,
          autoLogin,
          getValidToken,
          sessionToken
        ) || {},
    }),
    [url, user, getValidToken, handleLogOut, autoLogin, sessionToken]
  );

  return (
    <FetchContext.Provider value={defaults}>{children}</FetchContext.Provider>
  );
};

FetchProvider.propTypes = {
  children: PropTypes.node,
};

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { TableRow as Row } from "@material-ui/core";
import clsx from "clsx";
import TableCell from "./TableCell";
import Button from "components/Button";
import { nanoid } from "nanoid";

const useStyles = makeStyles(theme => ({
  rowWrapper: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  clickableRow: {
    cursor: "pointer",
  },
}));

function TableRow({ row, selected, config, onRowClick, actions }) {
  const classes = useStyles();

  const renderActions = () => {
    return (
      <div>
        {actions.map(action => {
          return (
            <Button
              size={{ width: 18, height: 18 }}
              {...action}
              key={nanoid()}
              onClick={() => action.onClick(row)}
            />
          );
        })}
      </div>
    );
  };

  return (
    <Row
      className={clsx(
        {
          [classes.clickableRow]: onRowClick,
        },
        classes.rowWrapper
      )}
      hover={!selected}
      tabIndex={-1}
      key={row.id}
      selected={selected}
      onClick={onRowClick ? () => onRowClick(row) : undefined}
      data-value="tableRow"
    >
      {Object.keys(config.columns).map(key => {
        return (
          <TableCell
            key={row.id + key}
            value={row[key]}
            type={config.types && config.types[key]}
          />
        );
      })}
      {actions && <TableCell value={renderActions()} />}
    </Row>
  );
}

TableRow.propTypes = {
  row: PropTypes.object,
  index: PropTypes.number,
  selected: PropTypes.bool,
  actions: PropTypes.array,
  config: PropTypes.object,
  onRowClick: PropTypes.func,
};

export default TableRow;

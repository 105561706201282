import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

// icons
import PlaceholderIcon from "assets/placeholder.png";
import { ReactComponent as BulletIcon } from "assets/bullets.svg";

// context
import { AppContext } from "context/AppContext";

// components
import KpiDo from "components/KpiDo";
import { Typography } from "@material-ui/core";
import SvgIcon from "components/SvgIcon";
import KpiBe from "components/KpiBe";
import LoadingImage from "components/LoadingImage";
import Popper from "components/Popper";
// Constants
import { WHITE, WILD_BLUE_YONDER } from "constants/colors";

// Enums
import { metricTypes } from "utils/enums/grooveMetrics";
import { beKpiTypes } from "utils/enums/grooveKpis";

const useStyles = makeStyles(theme => ({
  container: ({ portfolio }) => ({
    cursor:
      (portfolio.leaf === undefined && portfolio?.lastPublishedDateTime) ||
      portfolio.leaf !== undefined
        ? "pointer"
        : "default",
    width: 310,
  }),
  textWrapper: {
    width: "100%",
    height: "100%",
    backdropFilter: "blur(8px)",
    borderRadius: "0.7rem",
    position: "absolute",
    top: 0,
    left: 0,
  },
  cardTitle: {
    position: "absolute",
    bottom: "18%",
    maxWidth: "100%",
    left: "8%",
    right: "8%",
    textShadow: "0px 3px 3px #00000029",
    fontSize: 20,
  },
  cardSubtitle: {
    textTransform: "uppercase",
    position: "absolute",
    left: "8%",
    bottom: "8%",
    color: WHITE,
    fontSize: "10px",
    letterSpacing: "0.6px",
  },
  cardBackground: {
    position: "absolute",
    top: 0,
    width: "100%",
    height: "100%",
    // eslint-disable-next-line max-len
    backgroundImage: `linear-gradient(180deg, transparent, ${theme.palette.cardCarouselBackground.light} 56%, ${theme.palette.cardCarouselBackground.main} 100%)`,
    borderRadius: "0.5rem",
  },
  kpi: {
    fontSize: 20,
    fontWeight: 600,
    height: 30,
    display: "flex",
    alignItems: "center",
    marginRight: 25,
  },
  kpiLabel: {
    fontSize: 10,
    marginRight: 25,
    marginTop: -3,
  },
  icon: {
    cursor: "pointer",
  },
}));

const Card = ({
  deletePortfolio,
  deleteProject,
  itemId,
  isLeaf,
  metric,
  portfolio,
  onEdit,
  canBeRemoved,
}) => {
  const classes = useStyles({ portfolio });
  const history = useHistory();
  const { setPageTitle, setActiveMetric, activeMetric, setModalConfig } =
    useContext(AppContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const popperItems = [
    {
      onClick: () => {
        setAnchorEl(null);
        isLeaf ? history.push(`/planner/${portfolio?.id}/builder`) : onEdit();
      },
      text: isLeaf ? "Gestisci progetto" : "Gestisci portfolio",
    },
    {
      onClick: () => {
        setAnchorEl(null);
        isLeaf || canBeRemoved ? handleDelete(isLeaf) : handleNotDelete();
      },
      text: "Elimina",
    },
  ];

  const handleDelete = isLeaf =>
    setModalConfig({
      title: isLeaf ? "Eliminazione Progetto" : "Eliminazione Portfolio",
      content: `Sei sicuro di voler eliminare questo ${
        isLeaf ? "progetto" : "portfolio"
      }?`,
      primaryAction: {
        text: "OK",
        onClick: () =>
          isLeaf ? deleteProject(itemId) : deletePortfolio(itemId),
      },
      secondaryAction: {
        text: "ANNULLA",
      },
    });

  const handleNotDelete = () => {
    setModalConfig({
      title: "Impossibile eliminare il Portfolio",
      /* eslint-disable */
      content: `Per eliminare il portfolio, rimuovere prima i contenuti presenti al suo interno. `,
      primaryAction: {
        text: "OK",
      },
    });
  };

  const renderSubtitle = () => {
    let projectString = "PROJECT";
    if (
      !isLeaf &&
      (portfolio?.projectsCount === 0 || portfolio?.projectsCount > 1)
    )
      projectString = "PROJECTS";

    return (
      <Typography className={classes.cardSubtitle}>
        {portfolio?.projectsCount} {projectString}
      </Typography>
    );
  };

  const open = Boolean(anchorEl);
  const id = open ? "card-popper" : undefined;
  return (
    <>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <SvgIcon
          icon={<BulletIcon />}
          width={24}
          height="auto"
          color={anchorEl ? WHITE : WILD_BLUE_YONDER}
          className={classes.icon}
          onMouseEnter={e => setAnchorEl(anchorEl ? null : e.currentTarget)}
          onMouseLeave={() => setAnchorEl(null)}
        >
          <Popper
            id={id}
            open={open}
            placement="bottom-end"
            anchorEl={anchorEl}
          >
            {popperItems}
          </Popper>
        </SvgIcon>
      </div>
      <div
        className={classes.container}
        key={portfolio.id}
        onClick={() => {
          const targetUrl =
            portfolio.leaf === undefined
              ? activeMetric === metricTypes.DO
                ? `/project/${portfolio.id}`
                : `/project/${portfolio.id}/skyview`
              : `/portfolios/${portfolio.id}`;

          // Se non ho pubblicato il progetto non posso cliccarlo
          if (
            (portfolio.leaf === undefined &&
              portfolio?.lastPublishedDateTime) ||
            portfolio.leaf !== undefined
          ) {
            if (!activeMetric) {
              setActiveMetric(metricTypes.DO);
            }

            setPageTitle(portfolio.name);
            history.push(targetUrl);
          }
        }}
      >
        <LoadingImage
          height={210}
          imageUrl={portfolio.imageUrl}
          placeholder={PlaceholderIcon}
          blurred={portfolio.blurredImage}
        >
          <div className={classes.cardBackground}>
            <Typography variant="h5" className={classes.cardTitle}>
              {portfolio.name}
            </Typography>
            {renderSubtitle()}
          </div>
        </LoadingImage>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginLeft: "auto",
            marginTop: metric === metricTypes.BE ? 15 : 0,
            marginRight: metric === metricTypes.DO ? -25 : -15,
          }}
        >
          {metric === metricTypes.DO &&
            (!isLeaf || (isLeaf && portfolio?.lastPublishedDateTime)) && (
              <>
                <KpiDo
                  numberClassname={classes.kpi}
                  labelClassname={classes.kpiLabel}
                  number={portfolio?.time}
                  label="time"
                  orientation="column"
                />
                <KpiDo
                  numberClassname={classes.kpi}
                  labelClassname={classes.kpiLabel}
                  number={portfolio?.cost}
                  label="cost"
                  orientation="column"
                />
                <KpiDo
                  numberClassname={classes.kpi}
                  labelClassname={classes.kpiLabel}
                  number={portfolio?.quality}
                  label="quality"
                  orientation="column"
                />
              </>
            )}
          {metric === metricTypes.BE &&
            (!isLeaf || (isLeaf && portfolio?.lastPublishedDateTime)) && (
              <>
                <KpiBe
                  type={beKpiTypes.SENTIMENT}
                  value={portfolio.sentiment}
                />
                <KpiBe
                  type={beKpiTypes.REPUTATION}
                  value={portfolio.reputation}
                />
                <KpiBe type={beKpiTypes.SKILLS} value={portfolio.skillsCount} />
              </>
            )}
        </div>
      </div>
    </>
  );
};

Card.propTypes = {
  metric: metricTypes.DO,
  isLeaf: PropTypes.bool,
};

Card.propTypes = {
  deletePortfolio: PropTypes.func,
  deleteProject: PropTypes.func,
  getPortfolio: PropTypes.func,
  itemId: PropTypes.string,
  metric: PropTypes.oneOf(Object.values(metricTypes)),
  onEdit: PropTypes.function,
  portfolio: PropTypes.object,
  canBeRemoved: PropTypes.bool,
};

export default Card;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Components
import { CheckBox } from "components/Checkbox";
import PerfectScrollbar from "react-perfect-scrollbar";

import { makeStyles, Typography } from "@material-ui/core";

// Constants
import { WILD_BLUE_YONDER } from "constants/colors";

const useStyles = makeStyles(() => ({
  checkContainer: {
    display: "flex",
    alignItems: "center",
    height: "35px",
  },
  checklistContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "10px 0 ",
  },
  container: {
    flex: "0 0 457px",
    padding: "0 20px",
    height: "289px",
    position: "relative",
  },
  text: { fontSize: "20px" },
  text2: {
    fontSize: "15px",
  },
  text3: {
    fontSize: "15px",
    color: WILD_BLUE_YONDER,
  },
}));

const Checklist = ({ data }) => {
  if (data?.length === 0) return null;

  const [count, setCount] = useState(0);

  useEffect(() => {
    const res = data?.filter(item => item.checked);
    setCount(res.length);
  }, [data]);

  const renderText = (str, customClass) => {
    return (
      <Typography component="h3" className={customClass || classes.text}>
        {str}
      </Typography>
    );
  };

  const classes = useStyles();

  return (
    <div className={classes.container}>
      {renderText("Checklist " + count + "/" + data.length)}
      <PerfectScrollbar id="pf-notes-brick">
        <div className={classes.checklistContainer}>
          {data?.map(({ checked, description }, index) => (
            <div className={classes.checkContainer} key={index}>
              <CheckBox
                customColorChecked={WILD_BLUE_YONDER}
                checked={checked}
                disabled={true}
              />
              {/* <StyledCheckbox checked={checked} disabled={true} /> */}
              {renderText(description, checked ? classes.text2 : classes.text3)}
            </div>
          ))}
        </div>
      </PerfectScrollbar>
    </div>
  );
};

Checklist.defaultProps = {
  data: [],
  disabled: false,
  label: "Checklist",
};

Checklist.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool,
  label: PropTypes.string,
};
export default Checklist;

import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

//Hooks
import useFetch from "hooks/useHTTP";

// Others components
import { Typography } from "@material-ui/core";

// Components
import Layout from "components/Layout";
import Accordion from "components/Accordion";
import BrickList from "../../components/BrickList";
import DirectlyResponsible from "./components/DirectlyResponsible";
import Loading from "components/Loading";

// Context
import { AppContext } from "context/AppContext";

//constants
import { WHITE } from "constants/colors";
import { USERS_LIST } from "constants/api";

const useStyles = makeStyles(() => ({
  container: {
    height: "100%",
    overflow: "hidden",
  },
  wrapper: {
    width: "100%",
    display: "flex",
    padding: "10px",
    height: "100%",
  },

  column: {
    flex: 1,
    marginRight: "3rem",
    // height: "100%",
    // position: "relative",
  },

  title: {
    fontSize: "20px",
    color: WHITE,
    marginBottom: "10px",
    fontWeight: "bold",
  },
  accordionWrap: {
    marginBottom: "40px",
  },

  noData: {
    marginTop: "24px",
  },
}));

const UserDashboard = () => {
  const classes = useStyles();
  const history = useHistory();
  const { addBreadcrumb, setPageTitle, setOwner } = useContext(AppContext);
  const [userDash, setUserDash] = useState(null);
  const { id } = useParams();
  const { get, loading } = useFetch();

  // Effects
  useEffect(() => {
    getUserDashboard();
  }, []);

  useEffect(() => {
    if (userDash?.user) {
      addBreadcrumb({
        name: userDash?.user?.name + " " + userDash?.user?.surname,
      });
      setPageTitle(userDash?.user?.name + " " + userDash?.user?.surname);
    }
  }, [userDash]);

  // API USER DASHBOARD
  const getUserDashboard = async () => {
    try {
      const res = await get(`${USERS_LIST}/${id}/dashboard`);
      if (res.ok) {
        setUserDash(res?.data);
        setOwner({
          name: res?.data?.user?.name + " " + res?.data?.user?.surname,
          id: res?.data?.user?.id,
          image: res?.data?.user?.imageUrl,
          jobTitle: res?.data?.user?.jobTitle?.name,
          onClick: () => history.push(`/user/${res?.data?.user?.id}`),
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const renderTitle = str => {
    return (
      <Typography component="h2" className={classes.title}>
        {str}
      </Typography>
    );
  };

  const renderAccordionBrick = (str, data) => {
    return (
      <div className={classes.column}>
        {renderTitle(str)}
        {data?.length > 0 ? (
          <div className={classes?.accordionWrap}>
            {data?.map((el, index) => {
              const totalBricks =
                el?.bricks?.length > 0 ? el?.bricks?.length + " brick" : "";
              return (
                <Accordion
                  title={{
                    name: el?.name,
                    onClick: () => history.push(`/project/${el?.id}`),
                  }}
                  subtitle={totalBricks}
                  key={index}
                  details={<BrickList bricks={el?.bricks} />}
                  heightScroll={"calc(100vh - 215px)"}
                />
              );
            })}
          </div>
        ) : (
          <Typography variant="h6" className={classes?.noData}>
            Non ci sono {str}
          </Typography>
        )}
      </div>
    );
  };

  const headerIcons = [
    {
      icon: "be",
    },
  ];

  const renderLoading = () => (
    <Loading
      showWrapper={false}
      animationStyle={{ height: 80, width: 80, marginTop: -100 }}
    />
  );

  const renderData = () => {
    return (
      <div className={classes.container}>
        <div className={classes.wrapper}>
          {renderAccordionBrick("Brick in corso", userDash?.activeBricks)}
          {renderAccordionBrick("Brick completati", userDash?.completedBricks)}

          <div className={classes.column}>
            <DirectlyResponsible user={userDash?.user} />
          </div>
        </div>
      </div>
    );
  };

  return (
    <Layout headerIcons={headerIcons}>
      {loading ? renderLoading() : renderData()}
    </Layout>
  );
};

export default UserDashboard;

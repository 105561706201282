import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

// Components
import PerfectScrollbar from "react-perfect-scrollbar";
import { Typography } from "@material-ui/core";

// Constants
import { WILD_BLUE_YONDER } from "constants/colors";

const useStyles = makeStyles(theme => ({
  containerSkill: {
    marginTop: "10px",
  },

  data: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginLeft: "20px",
  },

  ownerSub: {
    display: "flex",
    marginTop: "5px",
    alignItems: "center",
  },
  responsibleData: {
    display: "flex",
    margin: "20px 0",
  },
  sub: {
    color: WILD_BLUE_YONDER,
    fontSize: "10px",
    textTransform: "uppercase",
  },
  margin: {
    marginTop: "10px",
  },
  title: {
    fontSize: "20px",
    color: theme.palette.text.primary,
    fontWeight: "bold",
  },
  text2: {
    fontSize: "15px",
    color: theme.palette.text.primary,
    marginLeft: "10px",
  },
  text3: {
    display: "flex",
    flexDirection: "column",
  },
  text4: {
    padding: "5px 0",
    color: theme.palette.text.primary,
    fontSize: "15px",
    borderBottom: "1px solid rgba(255, 255, 255, .2);",
  },
}));

const DirectlyResponsible = ({ user }) => {
  const classes = useStyles();

  const userSkill = {
    senior: user?.seniorSkills,
    intermediate: user?.intermediateSkills,
    junior: user?.juniorSkills,
  };

  const renderText = (str, className) => {
    return (
      <Typography component="h2" className={className || classes.title}>
        {str}
      </Typography>
    );
  };

  const renderBoxSkill = type => {
    return (
      <div className={classes.containerSkill}>
        {userSkill[type]?.length > 0 && (
          <>
            <div className={classes.margin} />
            {renderText(type, classes.sub)}
            {userSkill[type]?.map(el => {
              return renderText(el?.name, classes.text4);
            })}
          </>
        )}
      </div>
    );
  };

  const renderSkill = () => {
    return (
      <div className={classes.text3}>
        {renderText("Skill")}
        {renderBoxSkill("senior")}
        {renderBoxSkill("intermediate")}
        {renderBoxSkill("junior")}
      </div>
    );
  };

  return (
    <PerfectScrollbar id={"pf-scrollbar-responsible"}>
      {renderSkill()}
    </PerfectScrollbar>
  );
};

DirectlyResponsible.propTypes = {
  user: PropTypes.object,
};

export default DirectlyResponsible;

import React, { useState, useEffect, createContext } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
// Hooks
import useBreadcrumbs from "hooks/useBreadcrumbs";
import useLightBox from "hooks/useLightBox";
import useStorage from "hooks/useStorage";
import useFilters from "hooks/useFilters";

// Components
import Dialog from "components/Dialog";
import GlobalLoading from "components/GlobalLoading";
import { LightBox } from "components/LightBox";
import { TimeFilterType } from "utils/enums/filters";

// Date
import dayjs from "dayjs";

//configuration
import { ownerDisplay } from "widgets/Configuration";

export const AppContext = createContext();

export const defaultBreadcrumb = [{ id: null, name: "Groove", url: "/" }];
export const defaultLightbox = { isOpen: null, type: null };
export const defaultlPageTitle = "Groove"; // TO BE: Nome company impostasta dai settings

const AppContextProvider = ({ children }) => {
  const breadcrumbsUtils = useBreadcrumbs(defaultBreadcrumb);
  const lightboxHelper = useLightBox(defaultLightbox);
  const [usersWalkThrough, setUsersWalkThrough] = useStorage("usersWT", []);
  const [globalLoading, setGlobalLoading] = useState(false);
  const [isFirstColOpen, setIsFirstColOpen] = useState(false);
  const [isSecondColOpen, setIsSecondColOpen] = useState(false);
  const [modalConfig, setModalConfig] = useState(null);
  const [pageTitle, setPageTitle] = useState(defaultlPageTitle);
  const [activeMetric, setActiveMetric] = useStorage("activeMetric", null);
  const [owner, setOwner] = useState(null);
  const [widgets, setWidgets] = useState(null);
  const [refreschSecondCol, setRefreschSecondCol] = useState(false);
  const [marginTarget, setMarginTarget] = useState(null);

  const filtersUtils = useFilters({
    TimeFilterType: TimeFilterType.FISCAL_YEAR,
    TimeFilter: [String(dayjs().year())],
    Status: "100",
    PeopleCountFilter: "100",
  });
  const location = useLocation();

  useEffect(() => {
    setIsSecondColOpen(false);
    const display = ownerDisplay?.filter(el =>
      location?.pathname.startsWith(el)
    );

    if (display?.length === 0) {
      setOwner(null);
    }
  }, [location]);

  const shared = {
    isFirstColOpen,
    setIsFirstColOpen,
    isSecondColOpen,
    setIsSecondColOpen,
    modalConfig,
    setModalConfig,
    setGlobalLoading,
    usersWalkThrough: usersWalkThrough,
    setUsersWalkThrough: setUsersWalkThrough,
    pageTitle,
    setPageTitle,
    activeMetric,
    setActiveMetric,
    owner,
    setOwner,
    widgets,
    setWidgets,
    location,
    refreschSecondCol,
    setRefreschSecondCol,
    marginTarget,
    setMarginTarget,
    ...breadcrumbsUtils,
    ...filtersUtils,
    ...lightboxHelper,
  };

  return (
    <AppContext.Provider value={shared}>
      {children}
      <GlobalLoading open={globalLoading} />
      <Dialog {...modalConfig} />
      <LightBox {...lightboxHelper} />
    </AppContext.Provider>
  );
};

AppContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default AppContextProvider;

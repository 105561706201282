import React from "react";
import PropTypes from "prop-types";

// Material
import { Typography, makeStyles } from "@material-ui/core";

// Components
import BlueIcon from "components/BlueIcon";

// Constants
import { WILD_BLUE_YONDER } from "constants/colors";

const useStyles = makeStyles(theme => ({
  container: {
    flex: "0 0 457px",
    padding: "0 20px",
  },
  days: {
    fontSize: "15px",
    color: theme.palette.text.primary,
  },
  label: {
    marginLeft: 20,
    fontSize: "15px",
    color: theme.palette.text.primary,
  },
  leftColumn: {
    display: "flex",
    alignItems: "center",
  },
  listContainer: {
    display: "flex",
    flexDirection: "column",
    color: WILD_BLUE_YONDER,
    "& >div:not(:last-child)": {
      padding: "0 0 15px 0",
      borderBottom: `1px solid rgba(255, 255, 255, .2)`,
    },
  },
  text: {
    fontSize: "20px",
  },
  valueContainer: {
    display: "flex",
    justifyContent: "space-between",
    margin: "20px 0 0 0",
  },
}));

const Report = ({ brickData }) => {
  const classes = useStyles();

  const renderText = (str, customClass) => {
    return (
      <Typography component="h3" className={customClass || classes.text}>
        {str}
      </Typography>
    );
  };

  const returnTime = str => {
    return Number(str) > 0 ? " " + str + "m)" : ")";
  };

  const plannedH = brickData?.plannedHours?.split(":");
  const reportedH = brickData?.reportedHours?.split(":");
  const extimationH = brickData?.estimationCompletion?.split(":");

  const data = [
    {
      label: "Ore pianificate",
      value:
        Math.round(brickData?.plannedHoursInDays * 10) / 10?.toLocaleString() +
        "gg (" +
        plannedH?.[0] +
        "h" +
        returnTime(plannedH?.[1]),
    },
    {
      label: "Ore rendicontate",
      value:
        Math.round(brickData?.reportedHoursInDays * 10) / 10?.toLocaleString() +
        "gg (" +
        reportedH?.[0] +
        "h" +
        returnTime(reportedH?.[1]),
    },
    {
      label: "Stima a finire",
      value:
        Math.round(brickData?.estimationCompletionInDays * 10) /
          10?.toLocaleString() +
        "gg (" +
        extimationH?.[0] +
        "h" +
        returnTime(extimationH?.[1]),
    },
  ];

  const renderReport = () => {
    return (
      <div className={classes.container}>
        {renderText("Report")}
        <div className={classes.listContainer}>
          {data.map(({ label, value }, index) => (
            <div key={index} className={classes.valueContainer}>
              <div className={classes.leftColumn}>
                <BlueIcon width={"12.5px"} height={"12.5px"} />
                {renderText(label, classes.label)}
              </div>
              {renderText(value, classes.days)}
            </div>
          ))}
        </div>
      </div>
    );
  };

  return renderReport();
};

Report.propTypes = {
  brickData: PropTypes.object,
};

export default Report;

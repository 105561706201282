import React, { useState } from "react";
import PropTypes from "prop-types";

import Loading from "components/Loading";

import AreaWithDate from ".";

const data1 = [
  {
    x: "4/9/2021",
    y: "3",
  },
  {
    x: "5/9/2021",
    y: "-5",
  },
  {
    x: "6/9/2021",
    y: "3",
  },
];

const AreaWithDateManager = props => {
  const [data] = useState(props.data);

  if (Object.keys(data).length > 0) {
    return <AreaWithDate data={data} {...props} />;
  }
  return <Loading />;
};

AreaWithDateManager.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
};

AreaWithDateManager.defaultProps = {
  data: data1,
};

export default AreaWithDateManager;

import React, { useState } from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import Loading from "components/Loading";

const useStyles = makeStyles(() => ({
  container: ({ height }) => ({
    width: "100%",
    height: height || 300,
    position: "relative",
  }),
  imageWrapper: {
    height: "100%",
    display: props => (props.loading ? "none" : "block"),
  },
  textWrapper: {
    width: "100%",
    height: "100%",
    backdropFilter: props =>
      props.blurred || !props.imageUrl ? "blur(8px)" : "none",
    borderRadius: "0.7rem",
    position: "absolute",
    top: 0,
    left: 0,
  },
}));

const LoadingImage = ({ children, height, imageUrl, placeholder, blurred }) => {
  const [loading, setLoading] = useState(true);
  const classes = useStyles({ height, loading, blurred, imageUrl });

  return (
    <div className={classes.container}>
      {loading && <Loading />}
      <div className={classes.imageWrapper}>
        <img
          src={imageUrl || placeholder}
          onError={e => {
            e.target.onerror = null;
            e.target.src = placeholder;
          }}
          onLoad={() => {
            setLoading(false);
          }}
          style={{ width: "100%", height: "100%", borderRadius: "0.5rem" }}
        />
        <div className={classes.textWrapper}>{children}</div>
      </div>
    </div>
  );
};

LoadingImage.propTypes = {
  children: PropTypes.node,
  height: PropTypes.number,
  imageUrl: PropTypes.string,
  placeholder: PropTypes.node,
  blurred: PropTypes.bool,
};

LoadingImage.defaultProps = {
  blurred: true,
};

export default LoadingImage;

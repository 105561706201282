import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import placeholderIcon from "assets/placeholder.png";
import { Typography } from "@material-ui/core";
import theme from "theme";
import calendarIcon from "assets/calendar.svg";

// Components
import Date from "components/Date";
import Avatar from "components/Avatar";
import KpiDo from "components/KpiDo";
// Utils
import { brickTypes } from "utils/enums/bricks";
// Shapes
import { BrickShape } from "./shapes";
// Constants
import { WILD_BLUE_YONDER } from "constants/colors";

const avatarSize = 30;

const useStyles = makeStyles(() => ({
  container: {
    cursor: props => (props.onClick ? "pointer" : "default"),
    position: "relative",
    borderRadius: "0.4rem",
    width: props => props.width || "100%",
    minHeight: 74,
    whiteSpace: "normal",
    color: "#fff",
    backgroundImage: props =>
      props.isSkyBrick
        ? props.imageUrl
          ? `url(${props.imageUrl})`
          : `url(${placeholderIcon})`
        : undefined,
    backgroundColor: props =>
      props.isSkyBrick ? undefined : theme.palette.componentsBackground.main,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  imageWrapper: {
    backdropFilter: props =>
      props.isSkyBrick && !props.imageUrl ? "blur(20px)" : undefined,
    borderRadius: "0.4rem",
    padding: props => (props.isSkyBrick ? 14 : `20px 16px 12px 22px`),
    display: "flex",
    flexDirection: props => (props.isSkyBrick ? "row" : "column"),
  },
  avatarWrapper: {
    position: "absolute",
    left: 8,
    top: `-${avatarSize / 2}px`,
  },
  dateWrapper: {
    display: "flex",
    alignItems: "center",
    marginTop: 2,
  },
  calendarIcon: {
    width: 10,
    aspectRatio: "1/1",
    marginRight: 4,
  },
  grey: {
    color: WILD_BLUE_YONDER,
    lineHeight: 0.5,
  },
  ownerWrapper: {
    display: "flex",
    alignItems: "flex-start",
  },
  infoOwner: {
    display: "flex",
    flexDirection: "column",
    marginLeft: 12,
  },
  kpi: {
    height: "100%",
    display: "flex",
    flexDirection: props => (props.isSkyBrick ? "column" : "row"),
    marginTop: props => (props.isSkyBrick ? undefined : theme.spacing(1)),
    alignItems: "flex-end",
  },
  leftWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flex: 1,
  },
  ellipsis: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  date: {
    marginRight: 5,
    textTransform: "capitalize",
  },
  brickTitle: {
    fontSize: 12,
    fontWeight: "bold",
    "&:first-letter": {
      textTransform: "capitalize",
    },
  },
  jobTitle: {
    marginTop: 3,
  },
}));

const Brick = ({ data, width, onClick, onOwnerClick }) => {
  const { avatarImageUrl, endDate, imageUrl, startDate, type } = data;
  // Brick status helpers
  const isWaterBrick =
    type === brickTypes.PLANNED ||
    type === brickTypes.ACTIVE ||
    type === brickTypes.TO_VALIDATE;

  const isSkyBrick = type === brickTypes.CLOSED;

  const classes = useStyles({ imageUrl, width, type, isSkyBrick, onClick });

  return (
    <div className={classes.container} onClick={onClick}>
      {isWaterBrick && (
        <div className={classes.avatarWrapper}>
          <Avatar
            onClick={onOwnerClick}
            size={avatarSize}
            image={avatarImageUrl}
          />
        </div>
      )}
      <div className={classes.imageWrapper}>
        <div className={classes.leftWrapper}>
          <div>
            <Typography
              variant="subtitle1"
              className={clsx(classes.ellipsis, classes.brickTitle)}
              title={data.name}
            >
              {data.name}
            </Typography>
            <div className={classes.dateWrapper}>
              <img src={calendarIcon} className={classes.calendarIcon} />
              <Date
                dateString={startDate}
                variant="subtitle1"
                className={clsx(classes.grey, classes.date)}
              />
              <Typography
                variant="subtitle1"
                className={clsx(classes.grey, classes.date)}
                component="span"
              >
                |
              </Typography>
              <Date
                dateString={endDate}
                variant="subtitle1"
                className={clsx(classes.grey, classes.date)}
              />
            </div>
          </div>
          {/* In base al type del brick questo deve visualizzare i kpi in orizzontale o in verticale */}
          {isSkyBrick && (
            <div className={classes.ownerWrapper}>
              <Avatar
                onClick={onOwnerClick}
                size={avatarSize}
                image={avatarImageUrl}
              />
              <div className={classes.infoOwner}>
                <Typography variant="caption">{data.displayName}</Typography>
                <Typography
                  variant="subtitle1"
                  className={clsx(classes.grey, classes.jobTitle)}
                >
                  {data.position}
                </Typography>
              </div>
            </div>
          )}
        </div>
        {data.type !== brickTypes.PLANNED && (
          <div className={classes.kpi}>
            <KpiDo
              number={data?.time}
              label="time"
              orientation={isSkyBrick ? "column" : "row"}
              alignItems={isSkyBrick ? "flex-end" : "baseline"}
            />
            <KpiDo
              number={data?.cost}
              label="cost"
              orientation={isSkyBrick ? "column" : "row"}
              alignItems={isSkyBrick ? "flex-end" : "baseline"}
            />
            <KpiDo
              number={data?.quality}
              label="quality"
              orientation={isSkyBrick ? "column" : "row"}
              alignItems={isSkyBrick ? "flex-end" : "baseline"}
            />
          </div>
        )}
      </div>
    </div>
  );
};

Brick.propTypes = {
  data: PropTypes.shape(BrickShape),
  width: PropTypes.number,
  onClick: PropTypes.func,
  onOwnerClick: PropTypes.func,
};

Brick.defaultProps = {
  data: {},
};

export default Brick;

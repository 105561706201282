import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import { InputLabel } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import {
  BLACK,
  GHOST_WHITE,
  SPACE_CADET,
  WILD_BLUE_YONDER,
  WHITE,
  AMETHYST_SMOKE,
} from "constants/colors";
import theme from "theme";
// Icons
import { ReactComponent as Arrow } from "assets/arrow.svg";

// key
import { nanoid } from "nanoid";

const useStyles = makeStyles(() => ({
  formControl: ({ themeType, disabled, minWidth }) => ({
    minWidth: minWidth,
    position: "relative",
    display: "block",
    "& .MuiFormLabel-root.Mui-focused": {
      color: AMETHYST_SMOKE,
    },
    "& .MuiFormLabel-root.MuiFormLabel-filled": {
      color: AMETHYST_SMOKE,
    },
    "& .MuiFormLabel-root": {
      color: disabled ? AMETHYST_SMOKE : themeType === "light" ? BLACK : WHITE,
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: AMETHYST_SMOKE,
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: disabled ? AMETHYST_SMOKE : "tranparent",
    },
    "& .MuiSelect-outlined.MuiSelect-outlined.MuiSelect-select:focus": {
      backgroundColor: "transparent",
      borderColor: AMETHYST_SMOKE,
    },
  }),

  customValueComponent: ({ themeType, disabled }) => ({
    display: "flex",
    justifyContent: "space-between",
    paddingRight: "10px",
    alignItems: "center",
    background: "transparent",
    color: disabled ? AMETHYST_SMOKE : themeType === "light" ? BLACK : WHITE,
  }),

  label: ({ themeType }) => ({
    color: themeType === "light" ? BLACK : WHITE,
  }),

  numberValue: {
    width: "14px",
    height: "14px",
    textAlign: "center",
    fontSize: "9px",
    backgroundColor: theme.palette.primary.main,
    color: WHITE,
  },

  selectEmpty: ({ themeType }) => ({
    width: "100%",
    height: "45px",
    color: themeType === "light" ? BLACK : WHITE,
    fontSize: "13px",
    background: themeType === "light" ? GHOST_WHITE : SPACE_CADET,
    padding: "0px 10px",
    borderRadius: "23px",
    borderBottom: "0",
    display: "flex",
    alignItems: "center",
    "& .MuiSelect-iconOutlined": {
      width: "10px",
      marginRight: "10px",
      marginTop: "7px",
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: AMETHYST_SMOKE,
    },
    "&:hover": {
      background: themeType === "light" ? GHOST_WHITE : SPACE_CADET,
    },
    "&:after": {
      background: themeType === "light" ? GHOST_WHITE : SPACE_CADET,
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(16px, 15px) scale(1)",
    },
  }),
  dropdownStyle: ({ themeType, minWidth, multiple }) => ({
    borderRadius: "20px",
    marginTop: multiple ? "-10px !important!" : "8px !important",
    background: "transparent",
    boxShadow: "none",
    textAlign: "right",
    position: "relative",
    maxWidth: minWidth,
    transformOrigin: "0px 0px !important",
    marginLeft: "-10px",
    backgroundColor: "transparent",
    maxHeight: "250px",

    "& .MuiFilledInput-input": {
      padding: "10px",
    },

    "& .MuiListItem-root.Mui-selected": {
      color: themeType == "light" ? BLACK : WHITE,
      backgroundColor: "transparent",
    },

    "& ul": {
      borderBottom: "0",
      padding: "13px",
      width: "100%",
      borderRadius: "20px",
      fontSize: "13px",
      background: themeType === "light" ? GHOST_WHITE : SPACE_CADET,
      color: WILD_BLUE_YONDER,
    },
    "& li": {
      padding: "0.3rem 0rem",
      backgroundColor: "transparent",
      "&:hover": {
        color: themeType == "light" ? BLACK : WHITE,
        backgroundColor: "transparent",
      },
      "&:focus": {
        backgroundColor: "transparent",
      },
      "& .MuiListItem-root.Mui-selected": {
        backgroundColor: "transparent",
      },
    },
  }),
}));

const CustomSelect = ({
  disabled,
  label,
  numberRequired,
  minWidth,
  multiple,
  onChange,
  options,
  themeType,
  topOffset,
  value,
}) => {
  const classes = useStyles({ themeType, disabled, minWidth, multiple });
  return (
    <FormControl fullWidth variant="outlined" className={classes.formControl}>
      <InputLabel className={classes.label}>{label}</InputLabel>
      <Select
        value={value}
        disabled={disabled}
        multiple={multiple}
        renderValue={
          !multiple
            ? value => {
                const label = options?.find(el => el.value === value)?.label;
                return (
                  <div className={classes.customValueComponent}>
                    <p>{label}</p>
                    {numberRequired && (
                      <div className={classes.numberValue}>
                        {" "}
                        {numberRequired}
                      </div>
                    )}
                  </div>
                );
              }
            : null
        }
        displayEmpty
        onChange={onChange}
        className={classes.selectEmpty}
        label={label}
        MenuProps={{
          classes: {
            paper: classes.dropdownStyle,
            icon: classes.icon,
          },
          getContentAnchorEl: null,
          style: {
            top: 60 + topOffset,
          },
        }}
        IconComponent={Arrow}
      >
        {options?.map(e => (
          <MenuItem key={nanoid()} value={e.value}>
            {e.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

CustomSelect.propTypes = {
  themeType: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  numberRequired: PropTypes.number,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.any,
  onChange: PropTypes.func,
  minWidth: PropTypes.string,
  topOffset: PropTypes.number,
  multiple: PropTypes.bool,
};

CustomSelect.defaultProps = {
  options: [],
  disabled: false,
  value: "",
  minWidth: "160px",
  themeType: "dark",
  topOffset: 0,
  multiple: false,
};

export default CustomSelect;

import React, { useState } from "react";
import PropTypes from "prop-types";

import Loading from "components/Loading";

import Gantt from ".";

import { PACIFIC_BLU, MALACHITE } from "constants/colors";

const data1 = [
  {
    name: "Data pianificata",
    fromDateName: "Data di inizio pianificata",
    fromDate: "2020-10-15",
    toDateName: "Data di fine pianificata",
    toDate: "2021-07-28",
    color: PACIFIC_BLU,
  },
  {
    name: "Data reale",
    fromDateName: "Data di inizio reale",
    fromDate: "2020-11-03",
    toDateName: "Data di fine prevista",
    toDate: "2021-09-20",
    color: MALACHITE,
  },
];

const GanttManager = props => {
  const [data] = useState(props.data);

  if (Object.keys(data).length > 0) {
    return <Gantt data={data} {...props} />;
  }
  return <Loading />;
};

GanttManager.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
};

GanttManager.defaultProps = {
  data: data1,
};

export default GanttManager;

import React, { useState, useContext, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { nanoid } from "nanoid";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
// Hooks
import { useForm, FormProvider } from "react-hook-form";
import useFetch from "hooks/useHTTP";
import useDrawer from "hooks/useDrawer";
// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography } from "@material-ui/core";
// Components...
import BuilderPhase from "./components/BuilderPhase";
import Loading from "components/Loading";
import Layout from "components/Layout";
import Drawer from "components/Drawer";
import Accordion from "components/Accordion";
import ProjectDetails from "./components/ProjectDetails";
// ...Forms
import BrickForm from "./BrickForm";
import GenericBrickForm from "./GenericBrickForm";
import { brickFormDefaults as brickDefaults } from "./BrickForm/defaultValues";
// Context
import { AppContext } from "context/AppContext";
// Constants
import {
  PLANNER_PROJECT,
  PLANNER_PHASES,
  PLANNER_PHASES_REORDER,
  PLANNER_BRICKS,
} from "constants/api";
import { WHITE } from "constants/colors";
// Icons
import { ReactComponent as EditIcon } from "assets/edit.svg";

// Styles
const useStyles = makeStyles(() => ({
  wrapper: {
    width: "100%",
    display: "flex",
    paddingRight: 15,
    height: "calc(100vh - 287px)",
  },
  loadingWrapper: {
    height: "calc(100% - 70px)",
  },
  header: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginBottom: 40,
    paddingTop: 10,
    width: "95%",
  },
  deleteIconButton: {
    color: WHITE,
    padding: 18,
  },
  droppablePhasesWrapper: {
    display: "flex",
  },
  phase: {
    width: 290,
    padding: "0 6px",
    flexShrink: 0,
  },
  newPhaseButton: {
    marginTop: 58,
  },
}));

const PHASE_TEMPLATE = {
  id: null,
  name: "",
  user: null,
};

const SkylineBuilder = () => {
  // Hooks
  const classes = useStyles();
  const history = useHistory();
  const globalFormMethods = useForm();
  const brickFormMethods = useForm({ defaultValues: brickDefaults });
  const genericBrickFormMethods = useForm();
  const { projectId } = useParams();
  const { get, post, put, del } = useFetch();
  const {
    isDrawerOpen: isBrickDrawerOpen,
    setIsDrawerOpen: setBrickDrawerOpen,
    toggleDrawer: toggleBrickDrawer,
  } = useDrawer();
  const {
    isDrawerOpen: isGenericBrickDrawerOpen,
    toggleDrawer: toggleGenericBrickDrawer,
  } = useDrawer();
  // Context
  const { setModalConfig, setGlobalLoading } = useContext(AppContext);
  // State
  const [loading, setLoading] = useState(false);
  const [loadingBrick, setLoadingBrick] = useState(false);
  const [phases, setPhases] = useState(null);
  const [projectDetails, setProjectDetails] = useState(null);
  const [projectName, setProjectName] = useState(null);
  const [draftPhases, setDraftPhases] = useState([]);
  const [activePhaseId, setActivePhaseId] = useState(null);
  const [activeBrickId, setActiveBrickId] = useState(null);
  const [duplicateBrickId, setDuplicateBrickId] = useState(null);
  const [activeBrickData, setActiveBrickData] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  // Effects
  useEffect(() => {
    fetchProject();
  }, []);

  useEffect(() => {
    if (phases) {
      phases.forEach(phase => {
        globalFormMethods.setValue(`phase-${phase.id}.name`, phase.name);
        globalFormMethods.setValue(`phase-${phase.id}.user`, phase.user);
      });
    }
  }, [phases]);

  useEffect(() => {
    if (activeBrickId || duplicateBrickId) fetchBrick();
  }, [activeBrickId, duplicateBrickId]);

  // API
  const fetchProject = async () => {
    setLoading(true);
    try {
      const {
        data: {
          phases,
          plannedStartDate,
          plannedEndDate,
          user,
          supervisorsUsers,
          internalReferenceUser,
          customer,
          customerReference,
          competenceYear,
          expectedMargin,
          pricing,
          costs,
          name,
        },
      } = await get(`${PLANNER_PROJECT}/${projectId}`);
      setProjectName(name);
      setProjectDetails({
        plannedStartDate,
        plannedEndDate,
        user,
        supervisorsUsers,
        internalReferenceUser,
        customer,
        customerReference,
        competenceYear,
        expectedMargin,
        pricing,
        costs,
      });
      setPhases(phases);
      if (phases.length < 1) setDraftPhases([buildDraftPhase()]);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  const fetchBrick = async () => {
    setLoadingBrick(true);
    try {
      const { data } = await get(
        `${PLANNER_BRICKS}/${activeBrickId || duplicateBrickId}`
      );
      const updatedData = duplicateBrickId ? { ...data, user: null } : data;
      setActiveBrickData(updatedData);
      setLoadingBrick(false);
    } catch (err) {
      console.error(err);
      setLoadingBrick(false);
    }
  };

  const deleteBrick = async (brickId, phaseId) => {
    setGlobalLoading(true);
    setModalConfig(null);
    try {
      await del(`${PLANNER_BRICKS}/${brickId}`);
      const currentPhase = getPhase(phaseId);
      const updatedPhases = [...phases];
      const currentPhaseUpdatedBricks = currentPhase.bricks.filter(
        brick => brick.id !== brickId
      );
      const currentPhaseUpdatedPosition = phases.findIndex(
        p => p.id === phaseId
      );
      updatedPhases[currentPhaseUpdatedPosition] = {
        ...currentPhase,
        bricks: currentPhaseUpdatedBricks,
      };
      setPhases(updatedPhases);
      setGlobalLoading(false);
    } catch (err) {
      setGlobalLoading(false);
      console.error(err);
      if (err?.response?.status === 403) {
        setModalConfig({
          title: "Impossibile eliminare il Brick",
          /* eslint-disable */
          content: "Esistono report associati al brick",
          primaryAction: {
            text: "OK",
          },
        });
      }
    }
  };

  const deletePhase = async phaseId => {
    setGlobalLoading(true);
    setModalConfig(null);
    try {
      await del(`${PLANNER_PHASES}/${phaseId}`);
      const updatedPhases = phases.filter(phase => phase.id !== phaseId);
      setPhases(updatedPhases);
      setGlobalLoading(false);
    } catch (err) {
      setGlobalLoading(false);
      console.error(err);
    }
  };

  const publishProject = async () => {
    setModalConfig({ type: "loading", title: "Pubblicazione in corso..." });
    try {
      await put(`${PLANNER_PROJECT}/${projectId}/publish`);

      const nextPhases = phases.map(p => {
        const tempBricks = p.bricks;
        return {
          ...p,
          bricks: tempBricks.map(b => {
            return {
              ...b,
              isDraft: false,
            };
          }),
        };
      });
      setPhases(nextPhases);

      setModalConfig({
        type: "success",
        title: "Progetto pubblicato",
        primaryAction: {
          text: "OK",
        },
      });
    } catch (err) {
      setModalConfig({
        type: "error",
        primaryAction: {
          text: "OK",
        },
      });
      console.error(err);
    }
  };

  // Helpers
  const buildDraftPhase = () => {
    return {
      ...PHASE_TEMPLATE,
      draftId: nanoid(),
    };
  };

  const addDraftPhase = () => {
    const newDraftPhase = buildDraftPhase();
    const updatedDraftPhases = [...draftPhases, newDraftPhase];
    setDraftPhases(updatedDraftPhases);
  };

  const handleDeletePhase = phaseId =>
    setModalConfig({
      title: "Eliminazione Fase",
      content: "Sei sicuro di voler eliminare questa fase?",
      primaryAction: {
        text: "OK",
        onClick: () => deletePhase(phaseId),
      },
      secondaryAction: {
        text: "ANNULLA",
      },
    });

  const handleDeleteDraftPhase = draftId => {
    const updatedDraftPhases = draftPhases.filter(
      draftPhase => draftPhase.draftId !== draftId
    );
    setDraftPhases(updatedDraftPhases);
    globalFormMethods.unregister(`phase-${draftId}.name`);
    globalFormMethods.unregister(`phase-${draftId}.user`);
  };

  const getDraftPhaseDelete = draftId => {
    const phasesCount = phases.length + draftPhases.length;
    if (phasesCount <= 1) return null;
    return () => handleDeleteDraftPhase(draftId);
  };

  const handleCreatePhase = async ({ draftId, name, user }) => {
    const newPhaseData = {
      name,
      userId: user.id,
      position: phases.length,
      projectId: +projectId,
    };

    setGlobalLoading(true);
    try {
      const { data } = await post(PLANNER_PHASES, newPhaseData);

      const updatedPhases = [...phases, data];
      const updatedDraftPhases = draftPhases.filter(
        phase => phase.draftId !== draftId
      );

      globalFormMethods.setValue(`phase-${data.id}.name`, data.id);
      globalFormMethods.setValue(`phase-${data.id}.user`, data.user);

      setPhases(updatedPhases);
      setDraftPhases(updatedDraftPhases);
      setGlobalLoading(false);
    } catch (err) {
      setGlobalLoading(false);
      console.error(err);
    }
  };

  const handlePhaseReorder = async result => {
    const { destination, source, draggableId } = result;
    // Dropped outside the list
    if (!destination) return;
    // Dropped in place
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return;

    // Reorder phases after drop
    setGlobalLoading(true);
    let updatedPhases = [...phases];
    const draggedPhase = phases.find(phase => String(phase.id) == draggableId);
    updatedPhases.splice(source.index, 1);
    updatedPhases.splice(destination.index, 0, draggedPhase);
    updatedPhases = updatedPhases.map((updatedPhase, idx) => ({
      ...updatedPhase,
      position: idx,
    }));
    setPhases(updatedPhases);

    const phaseOrderMap = updatedPhases?.map(({ id, position }) => ({
      id,
      position,
    }));

    try {
      await put(PLANNER_PHASES_REORDER, phaseOrderMap);
      setGlobalLoading(false);
    } catch (err) {
      setPhases(phases);
      setGlobalLoading(false);
      console.error(err);
    }
  };

  const handleEditPhase = async phaseId => {
    if (!phaseId) return;
    // Get updated data from form state
    const updatedPhaseName = globalFormMethods.getValues(
      `phase-${phaseId}.name`
    );
    const updatedPhaseUser = globalFormMethods.getValues(
      `phase-${phaseId}.user`
    );
    // If updated phase name or user are missing avoid API call
    if (!updatedPhaseName || !updatedPhaseUser) return;

    const currentPhase = getPhase(phaseId);
    // Call phase update API only if data has actually changed
    if (
      currentPhase?.name !== updatedPhaseName ||
      currentPhase?.userId !== updatedPhaseUser.id
    ) {
      setGlobalLoading(true);

      const updatedPhaseData = {
        name: updatedPhaseName,
        userId: updatedPhaseUser.id,
      };

      try {
        const { data } = await put(
          `${PLANNER_PHASES}/${phaseId}`,
          updatedPhaseData
        );
        const updatedPhases = [...phases];
        updatedPhases[currentPhase.position] = {
          ...data,
          bricks: currentPhase.bricks,
        };
        setPhases(updatedPhases);
        setGlobalLoading(false);
      } catch (err) {
        globalFormMethods.setValue(`phase-${phaseId}.name`, currentPhase.name);
        globalFormMethods.setValue(`phase-${phaseId}.user`, currentPhase.user);
        setGlobalLoading(false);
        console.error(err);
      }
    }
  };

  const handleAddBrick = phaseId => {
    setActivePhaseId(phaseId);
    toggleBrickDrawer();
  };

  const handleAddGenericBrick = phaseId => {
    setActivePhaseId(phaseId);
    toggleGenericBrickDrawer();
  };

  const handleEditBrick = (brickId, phaseId) => {
    setActiveBrickId(brickId);
    setActivePhaseId(phaseId);
    toggleBrickDrawer();
  };

  const handleCopyBrick = (brickId, phaseId) => {
    setDuplicateBrickId(brickId);
    setActivePhaseId(phaseId);
    toggleBrickDrawer();
  };

  const handleDeleteBrick = (brickId, phaseId) =>
    setModalConfig({
      title: "Eliminazione Brick",
      content: "Sei sicuro di voler eliminare questo brick?",
      primaryAction: {
        text: "OK",
        onClick: () => deleteBrick(brickId, phaseId),
      },
      secondaryAction: {
        text: "ANNULLA",
      },
    });

  const onSubmit = () => {
    const hasMissingBricks = phases.some(phase => phase.bricks.length < 1);
    if (hasMissingBricks) return showPublishError();

    setModalConfig({
      title: "Pubblicazione progetto",
      /* eslint-disable */
      content: `Pubblicando il progetto, questo diventerà visibile e tutti i Brick verranno assegnati ai rispettivi Owner.\n
        Ricorda che se hai creato delle fasi senza salvarle, queste non saranno pubblicate`,
      primaryAction: {
        text: "PUBBLICA",
        onClick: publishProject,
      },
      secondaryAction: {
        text: "ANNULLA",
      },
    });
  };

  const onPublishFailed = () => showPublishError();

  const showPublishError = () =>
    setModalConfig({
      title: "Impossibile pubblicare il progetto",
      /* eslint-disable */
      content: `La tua skyline ha dati mancanti.\n
                Verifica di aver compilato correttamente tutte le informazioni necessarie per poter pubblicare il progetto`,
      primaryAction: {
        text: "OK",
      },
    });

  const drawerFormsCleanup = () => {
    genericBrickFormMethods.reset();
    brickFormMethods.reset();
    setActivePhaseId(null);
    setActiveBrickId(null);
    setDuplicateBrickId(null);
    setActiveBrickData(null);
    setErrorMessage("");
  };

  const handleBrickDrawerClosing = () => {
    drawerFormsCleanup();
    toggleBrickDrawer();
  };

  const handleGenericBrickDrawerClosing = () => {
    toggleGenericBrickDrawer();
    drawerFormsCleanup();
  };

  const getActivePhaseTitle = () =>
    phases?.find(p => p.id === activePhaseId)?.name;

  const getPhase = phaseId => phases?.find(p => p.id === phaseId);

  // Renders
  const renderPhases = () => (
    <Droppable droppableId="droppable" direction="horizontal">
      {(provided, snapshot) => (
        <>
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className={classes.droppablePhasesWrapper}
          >
            {phases?.map(({ bricks, id, name, user }, idx) => (
              <Draggable key={id} draggableId={id?.toString()} index={idx}>
                {(provided, snapshot) => (
                  <BuilderPhase
                    key={id}
                    bricks={bricks}
                    id={id}
                    index={idx}
                    dndProvided={provided}
                    missesBricks={
                      globalFormMethods.formState.isSubmitted &&
                      bricks.length < 1
                    }
                    onAddBrick={handleAddBrick}
                    onAddGenericBrick={handleAddGenericBrick}
                    onEditBrick={handleEditBrick}
                    onCopyBrick={handleCopyBrick}
                    onEditPhase={handleEditPhase}
                    onDeleteBrick={handleDeleteBrick}
                    onDeletePhase={handleDeletePhase}
                    title={name}
                  />
                )}
              </Draggable>
            ))}
          </div>
          {provided.placeholder}
        </>
      )}
    </Droppable>
  );

  const renderDraftPhases = () =>
    draftPhases?.map(({ draftId, name, user }) => (
      <BuilderPhase
        key={draftId}
        draftId={draftId}
        onCreatePhase={handleCreatePhase}
        onDeletePhase={getDraftPhaseDelete(draftId)}
        title={name}
      />
    ));

  const renderAddPhaseButton = () => (
    <div className={classes.phase}>
      <Button
        onClick={addDraftPhase}
        className={classes.newPhaseButton}
        variant="outlined"
        color="primary"
        fullWidth
      >
        + FASE
      </Button>
    </div>
  );

  const updateBricks = async data => {
    setLoadingBrick(true);
    const body = { ...data };
    body.plannedStartDate = data.plannedStartDate.format("YYYY-MM-DDTHH:mm:ss");
    body.plannedEndDate = data.plannedEndDate.format("YYYY-MM-DDTHH:mm:ss");
    body.plannedHours = `${String(data.plannedHours).padStart(2, "0")}:00:00`;
    body.userId = data.user.id;
    body.skillsIds =
      data?.skillsIds?.length > 0
        ? data?.skillsIds.map(skill => skill.id).join(",")
        : null;

    body.checklists = data?.checklists?.map((item, idx) => {
      const formattedItem = {
        ...item,
        id: item.originalId,
        position: idx,
      };
      delete formattedItem.originalId;
      if (!Number.isInteger(formattedItem.id)) delete formattedItem.id;
      return formattedItem;
    });
    delete body.user;

    if (!activeBrickId) {
      body.phaseId = activePhaseId;
      body.checklists.forEach(item => delete item.id);
    } else {
      // Reinsert deleted checklist items w/ "deleted" key
      let deletedChecklists = activeBrickData?.checklists.filter(
        c => body.checklists.findIndex(ck => ck.id === c.id) < 0
      );
      if (deletedChecklists.length > 0) {
        deletedChecklists = deletedChecklists.map(c => ({
          ...c,
          deleted: true,
        }));
        body.checklists = [...body.checklists, ...deletedChecklists];
      }
    }
    const apiCall = !activeBrickId ? post : put;
    const endpoint = !activeBrickId
      ? PLANNER_BRICKS
      : `${PLANNER_BRICKS}/${activeBrickId}`;

    try {
      const res = await apiCall(endpoint, body);

      const currentPhase = getPhase(activePhaseId);
      const currentPhaseBricks = [...currentPhase.bricks];

      if (!activeBrickId) {
        currentPhaseBricks.push(res.data);
      } else {
        const targetBrickIndex = currentPhaseBricks.findIndex(
          b => b.id === activeBrickId
        );
        currentPhaseBricks[targetBrickIndex] = { ...res.data };
      }

      const updatedPhases = [...phases];
      const currentPhaseUpdatedPosition = phases.findIndex(
        p => p.id === currentPhase.id
      );

      updatedPhases[currentPhaseUpdatedPosition] = {
        ...currentPhase,
        bricks: currentPhaseBricks,
      };
      setPhases(updatedPhases);
      setLoadingBrick(false);
      setBrickDrawerOpen(false);
      drawerFormsCleanup();
    } catch (err) {
      console.error(err);
      setErrorMessage("Elaborazione non riuscita");
      setBrickDrawerOpen(true);
      setLoadingBrick(false);
    }
  };

  const renderFormDrawers = () => (
    <>
      <Drawer
        open={isBrickDrawerOpen}
        title={
          !loadingBrick && (!activeBrickId ? "Nuovo Brick" : "Modifica Brick")
        }
        primaryText={!loadingBrick && "SALVA"}
        secondaryText={!loadingBrick && "ANNULLA"}
        onPrimary={brickFormMethods.handleSubmit(updateBricks)}
        onSecondary={handleBrickDrawerClosing}
        errorMessage={errorMessage}
      >
        <FormProvider {...brickFormMethods}>
          <BrickForm
            loading={loadingBrick}
            phaseTitle={getActivePhaseTitle()}
            brickData={activeBrickData}
            isEdit={!!activeBrickId}
          />
        </FormProvider>
      </Drawer>

      <Drawer
        open={isGenericBrickDrawerOpen}
        title="Nuovo Brick Generico"
        primaryText="SALVA"
        secondaryText="ANNULLA"
        onSecondary={handleGenericBrickDrawerClosing}
        onPrimary={genericBrickFormMethods.handleSubmit(data => {
          toggleGenericBrickDrawer();
          drawerFormsCleanup();
        }, console.log)}
        errorMessage={errorMessage}
      >
        <FormProvider {...genericBrickFormMethods}>
          <GenericBrickForm
            phaseTitle={getActivePhaseTitle()}
            loading={loadingBrick}
          />
        </FormProvider>
      </Drawer>
    </>
  );

  return (
    <Layout showSecondCol={false} padRight={false}>
      <DragDropContext onDragEnd={handlePhaseReorder}>
        <div className={classes.header}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 40,
            }}
          >
            <Typography variant="h5">Crea Skyline</Typography>
            <div>
              <Button
                disabled={!phases || phases.length < 1}
                color="primary"
                variant="contained"
                className={classes.button}
                onClick={globalFormMethods.handleSubmit(
                  onSubmit,
                  onPublishFailed
                )}
              >
                PUBBLICA
              </Button>
            </div>
          </div>
          <Accordion
            title={{ name: projectName }}
            actions={[
              {
                icon: <EditIcon />,
                label: "MODIFICA",
                onClick: () => history.push(`/planner/${projectId}`),
              },
            ]}
            details={<ProjectDetails details={projectDetails} />}
            showDetail
          />
        </div>
        {loading && (
          <div className={classes.loadingWrapper}>
            <Loading showWrapper={false} />
          </div>
        )}
        {!loading && phases && (
          <PerfectScrollbar
            style={{ height: "auto" }}
            options={{ suppressScrollY: true }}
          >
            <div className={classes.wrapper}>
              <FormProvider {...globalFormMethods}>
                {renderPhases()}
                {renderDraftPhases()}
                {renderAddPhaseButton()}
              </FormProvider>
            </div>
          </PerfectScrollbar>
        )}
      </DragDropContext>
      {renderFormDrawers()}
    </Layout>
  );
};

export default SkylineBuilder;

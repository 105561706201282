export const TimeFilterType = {
  FISCAL_YEAR: "0",
  TO_DATE: "1",
};

export const TimeFilter = {
  CREATED: "0",
  STARTED: "1",
  CLOSED: "2",
};

export const Status = {
  LAST_SIX_MONTHS: "0",
  LAST_THREE_MONTHS: "1",
  YEAR_TO_DATE: "2",
  MONTH_FROM_EARLY: "3",
  YEAR_TO_DATE_MONTH: "4",
};

export const PeopleCountFilter = {
  PEOPLE_2_5: "0",
  PEOPLE_6_15: "1",
  PEOPLE_16_50: "2",
  PEOPLE_50: "3",
};

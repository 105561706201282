import React, { useState, useEffect } from "react";
import { useParams } from "react-router";

// Material
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

import {
  WILD_BLUE_YONDER,
  PACIFIC_BLU,
  MALACHITE,
  GHOST_WHITE,
} from "constants/colors";

//Enum
import { workLoadFiltersTypes } from "utils/enums/workLoad";
import { effortGraphColor } from "utils/enums/workLoad";

// Assests
import { ReactComponent as CancelIcon } from "assets/cancel.svg";
import effortLegend from "assets/legenda_carico.png";
//Hooks
import useFetch from "hooks/useHTTP";
import useFilters from "hooks/useFilters";

// Components
import ChartCard from "components/ChartCard";
import Loading from "components/Loading";
import CustomSelect from "components/CustomSelect";
import LabelList from "components/LabelList";
import SvgIcon from "components/SvgIcon";

import {
  PROJECT,
  WORKLOAD_EFFORT,
  WORKLOAD_BURNDOWN,
  WORKLOAD_BURNDOWN_DETAIL,
} from "constants/api";

// Charts and Themes
import StackedRadiusColumnManager from "components/Charts/StackedRadiusColumn/manager";
import StackedRadiusMultiColumnManager from "components/Charts/StackedRadiusMultiColumn/manager";

import {
  parseDate,
  parseMounth,
  parseWeek,
  timeToHours,
  timeToFormattedHoursMinutesString,
  truncateDecimals,
} from "utils";

// key
import { nanoid } from "nanoid";

const useStyles = makeStyles(() => ({
  chartContainer: {
    height: "380px",
    width: "100%",
  },
  select: {
    marginRight: "10px",
  },
  customSelect: {
    display: "flex",
    maxWidth: "639px",
  },
  InfoContainer: {
    height: "500px",
    marginTop: 20,
  },
  closeIcon: {
    cursor: "pointer",
    width: 16,
    height: 16,
  },
  detailContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "100px",
  },
  detailHeader: {
    display: "flex",
    padding: "20px",
    flexDirection: "row",
    width: "100%",
    backgroundColor: "#1E2840",
    justifyContent: "space-between",
  },
  closeDetailIcon: {
    height: "100%",
    marginRight: "0px",
    marginTop: "auto",
  },
  detailRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    marginTop: "20px",
  },
  detailColumn: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
  },
  detailBody: {
    marginLeft: "10px",
    marginRight: "20px",
  },
  loadingWrapper: {
    height: "300px",
  },
  legend: {
    display: "flex",
    float: "right",
  },
  legendIcon: {
    maxWidth: "100%",
    height: 15,
    marginTop: 0,
    marginBottom: 30,
  },
}));

const WorkLoad = () => {
  const classes = useStyles();
  const { get } = useFetch();

  // State
  const [loadingEffort, setLoadingEffort] = useState(false);
  const [loadingBurnDown, setLoadingBurnDown] = useState(false);
  const [loadingBurnDownInfo, setLoadingBurnDownInfo] = useState(false);

  const [effortGraphData, setEffortGraphData] = useState(null);
  const [burnDownGraphData, setBurnDownGraphData] = useState(null);
  const [burnDownInfoData, setBurnDownInfoData] = useState(null);

  const [effortScrollBars, setEffortScrollBars] = useState(false);
  const [burnDownScrollBars, setBurnDownScrollBars] = useState(false);

  const { filters, setFilters } = useFilters({
    EffortFilter: workLoadFiltersTypes.DAYS,
    BurnDownFilter: workLoadFiltersTypes.DAYS,
  });
  const { projectId } = useParams();

  useEffect(() => {
    fetchEffortGraph(filters.EffortFilter);
  }, [filters.EffortFilter]);

  useEffect(() => {
    fetchBurnDownGraph(filters.BurnDownFilter);
    setBurnDownInfoData(null);
  }, [filters.BurnDownFilter]);

  // Renders
  const renderLoading = () => (
    <Loading
      showWrapper={false}
      animationStyle={{ height: 80, width: 80, marginTop: -100 }}
    />
  );

  const getColor = type => {
    switch (type) {
      case effortGraphColor.PAST:
        return PACIFIC_BLU;
      case effortGraphColor.TODAY:
        return MALACHITE;
      case effortGraphColor.FUTURE:
        return WILD_BLUE_YONDER;
      default:
        return PACIFIC_BLU;
    }
  };

  const getToolText = type => {
    switch (type) {
      case effortGraphColor.PAST:
        return ["rendicontate"];
      case effortGraphColor.TODAY:
        return ["totali", "ore rendicontate"];
      case effortGraphColor.FUTURE:
        return ["da rendicontare"];
      default:
        return ["rendicontate"];
    }
  };

  const randomDate = () => {
    var dob;

    //set a range of years
    var min = 1900;
    var max = 2004;

    // Math.ceil prevents the value from being 0;
    var month = Math.ceil(Math.random() * 12);
    var day = Math.ceil(Math.random() * 28);
    var year = Math.floor(Math.random() * (max - min) + min);

    //this ensures that the format will stay mm/dd/yyyy;
    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }
    //concatenates random dob in mm/dd/yyyy format;
    dob = year + "/" + month + "/" + day;

    return dob;
  };

  const generateEffortValue = value => {
    let result = [];
    let filterFunction;

    switch (filters.EffortFilter) {
      case workLoadFiltersTypes.DAYS:
        filterFunction = (...args) => parseDate(...args);
        break;
      case workLoadFiltersTypes.WEEKS:
        filterFunction = (...args) => parseWeek(...args);
        break;
      case workLoadFiltersTypes.MONTHS:
        filterFunction = (...args) => parseMounth(...args);
        break;
      default:
        filterFunction = (...args) => parseDate(...args);
        break;
    }

    for (let index = 0; index < value; index++) {
      result.push({
        x: filterFunction(
          randomDate(new Date(2012, 0, 1), new Date()).toString()
        ),
        type: 1,
        fill:
          index === value / 2
            ? getColor(2)
            : index > value / 2
            ? getColor(3)
            : getColor(1),
      });
    }
    result = result.map(item => {
      var newItem = { x: item.x, type: item.type, fill: item.fill };
      newItem.y = [];
      var cycle = Math.floor(Math.random() * 20);
      for (let index = 0; index < cycle; index++) {
        newItem.y.push({
          name: "Utente " + (index + 1),
          hours: Math.floor(Math.random() * 15) + 10,
        });
      }
      return newItem;
    });
    return result;
  };

  // API GET EFFORT GRAPH
  const fetchEffortGraph = async range => {
    try {
      setLoadingEffort(true);
      let filterFunction;

      switch (filters.EffortFilter) {
        case workLoadFiltersTypes.DAYS:
          filterFunction = (...args) => parseDate(...args);
          break;
        case workLoadFiltersTypes.WEEKS:
          filterFunction = (...args) => parseWeek(...args);
          break;
        case workLoadFiltersTypes.MONTHS:
          filterFunction = (...args) => parseMounth(...args);
          break;
        default:
          filterFunction = (...args) => parseDate(...args);
          break;
      }
      const res = await get(
        `${PROJECT}/${projectId}/${WORKLOAD_EFFORT}?type=${range}`
      );
      if (res.ok) {
        var newData = res.data.map(item => {
          let x = filterFunction(item.x).toString();
          let text = getToolText(item.type);
          let y = item.y.map(({ name, expectedHours, reportedHours }) => {
            return {
              name: name ? name : "UTENTE SCONOSCIUTO",
              hours:
                truncateDecimals(timeToHours(reportedHours), 1) +
                truncateDecimals(timeToHours(expectedHours), 1),
              extraText:
                (item.type === effortGraphColor.TODAY &&
                  reportedHours &&
                  text[1]) ||
                "",
              extraY:
                (item.type === effortGraphColor.TODAY &&
                  (truncateDecimals(timeToHours(reportedHours), 1) || "0")) ||
                "",
            };
          });

          return {
            x,
            type: item.type,
            fill: getColor(item.type),
            toolTipText: text[0],
            y,
          };
        });

        if (newData?.length > 30) {
          setEffortScrollBars(true);
        }
        setEffortGraphData(newData);
      }
      setLoadingEffort(false);
    } catch (err) {
      const data = generateEffortValue(30);
      if (data?.length > 30) {
        setEffortScrollBars(true);
      }
      setEffortGraphData(data);
      console.log(err);
      setLoadingEffort(false);
    }
  };

  // API GET BURNDOWN GRAPH
  const fetchBurnDownGraph = async (range = 1) => {
    try {
      setLoadingBurnDown(true);
      let filterFunction;

      switch (filters.BurnDownFilter) {
        case workLoadFiltersTypes.DAYS:
          filterFunction = (...args) => parseDate(...args);
          break;
        case workLoadFiltersTypes.WEEKS:
          filterFunction = (...args) => parseWeek(...args);
          break;
        case workLoadFiltersTypes.MONTHS:
          filterFunction = (...args) => parseMounth(...args);
          break;
        default:
          filterFunction = (...args) => parseDate(...args);
          break;
      }

      const res = await get(
        `${PROJECT}/${projectId}/${WORKLOAD_BURNDOWN}?type=${range}`
      );
      if (res.ok) {
        var newData = res.data.map(item => {
          let x = filterFunction(item.x);
          let extraX = item.x;
          let y = timeToHours(item.y) !== 0 ? timeToHours(item.y) : null;
          let extraY =
            timeToHours(item.extraY) !== 0 ? timeToHours(item.extraY) : null;

          return {
            x,
            extraX,
            y,
            extraY,
          };
        });
        if (newData?.length > 30) {
          setBurnDownScrollBars(true);
        }

        setBurnDownGraphData(newData);
      }
      setLoadingBurnDown(false);
    } catch (err) {
      console.log(err);
      setLoadingBurnDown(false);
    }
  };

  // API GET REPORT INFO
  const fetchBurnDownInfo = async time => {
    try {
      setLoadingBurnDownInfo(true);

      const res = await get(
        `${PROJECT}/${projectId}/${WORKLOAD_BURNDOWN_DETAIL}?type=${filters.BurnDownFilter}&timeValue=${time}`
      );
      if (res.ok) {
        let addedBricks = res.data.addedBricks.map(brick => {
          return {
            id: brick.id,
            name: brick.name,
            phaseName: brick.phaseName,
            rightText: timeToFormattedHoursMinutesString(brick.hours),
          };
        });

        let updatedBricks = res.data.updatedBricks.map(brick => {
          return {
            id: brick.id,
            name: brick.name,
            phaseName: brick.phaseName,
            rightText: "+ " + timeToFormattedHoursMinutesString(brick.hours),
          };
        });

        let newData = {
          addedBricks,
          updatedBricks,
        };

        setBurnDownInfoData(newData);
      }
      setLoadingBurnDownInfo(false);
    } catch (err) {
      console.log(err);
      setLoadingBurnDownInfo(false);
    }
  };

  const onChange = (e, filterKey) => {
    let value = e.target.value;
    setFilters({ ...filters, [filterKey]: value });
  };

  const filtersRange = [
    { value: workLoadFiltersTypes.DAYS, label: "DAYS" },
    { value: workLoadFiltersTypes.WEEKS, label: "WEEKS" },
    { value: workLoadFiltersTypes.MONTHS, label: "MONTHS" },
  ];

  const renderSelect = (disabled, filterKey, minWidth) => {
    return (
      <div key={nanoid()} className={classes.select}>
        <CustomSelect
          options={filtersRange}
          disabled={disabled}
          onChange={e => onChange(e, filterKey)}
          value={filters[filterKey]}
          minWidth={minWidth}
        />
      </div>
    );
  };

  const renderEffortGraph = () =>
    loadingEffort ? (
      <div className={classes.loadingWrapper}>{renderLoading()} </div>
    ) : (
      effortGraphData && (
        <div className={classes.chartContainer}>
          <ChartCard
            height={"380px"}
            marginLeft={"0px"}
            title="Effort erogato nel tempo sul progetto"
            filters={renderSelect(false, "EffortFilter", "194px")}
          >
            <StackedRadiusMultiColumnManager
              id={"stackedMultiColumn1"}
              scrollBars={effortScrollBars}
              data={effortGraphData}
            />
          </ChartCard>
          <div className={classes.legend}>
            <img
              src={effortLegend}
              className={classes.legendIcon}
              alt={"effort legend"}
            ></img>
          </div>
        </div>
      )
    );

  const renderBurnDownGraph = () =>
    loadingBurnDown ? (
      <div className={classes.loadingWrapper}>{renderLoading()} </div>
    ) : (
      burnDownGraphData && (
        <div className={classes.chartContainer}>
          <ChartCard
            height={"380px"}
            marginLeft={"0px"}
            title="Burndown chart"
            filters={renderSelect(false, "BurnDownFilter", "194px")}
          >
            <StackedRadiusColumnManager
              id={"stackedColumn2"}
              onClick={fetchBurnDownInfo}
              scrollBars={burnDownScrollBars}
              columnWidth={20}
              tmp={burnDownInfoData}
              data={burnDownGraphData}
            />
          </ChartCard>
        </div>
      )
    );

  const reloadBurnDownGraph = () => {
    setBurnDownGraphData([...burnDownGraphData]);
    setBurnDownInfoData(null);
  };

  const renderBurnDownInfo = () =>
    loadingBurnDownInfo ? (
      <div className={classes.chartContainer}>{renderLoading()}</div>
    ) : (
      burnDownInfoData && (
        <div className={classes.InfoContainer}>
          <div className={classes.detailContainer}>
            <div className={classes.detailHeader}>
              <Typography variant="h6">
                Dettaglio {(burnDownInfoData && burnDownInfoData.Name) || ""}
              </Typography>
              <div className={classes.closeDetailIcon}>
                <SvgIcon
                  color={GHOST_WHITE}
                  className={classes.closeIcon}
                  icon={<CancelIcon />}
                  onClick={() => reloadBurnDownGraph()}
                  strokeWidth={2}
                />
              </div>
            </div>
            <div className={classes.detailRow}>
              <div className={classes.detailColumn}>
                <Typography variant="h6">
                  {(burnDownInfoData.addedBricks &&
                    burnDownInfoData.addedBricks?.length) ||
                    "0"}{" "}
                  Brick aggiunti
                </Typography>
                <div className={classes.detailBody}>
                  <LabelList disabled data={burnDownInfoData?.addedBricks} />
                </div>
              </div>
              <div className={classes.detailColumn}>
                <Typography variant="h6">
                  {(burnDownInfoData.updatedBricks &&
                    burnDownInfoData.updatedBricks?.length) ||
                    "0"}{" "}
                  Brick con stima a finire modificata
                </Typography>
                <div className={classes.detailBody}>
                  <LabelList disabled data={burnDownInfoData?.updatedBricks} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    );

  return (
    <>
      {renderEffortGraph()}
      {renderBurnDownGraph()}
      {renderBurnDownInfo()}
    </>
  );
};

export default WorkLoad;

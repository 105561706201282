import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

// Components
import NumberFormatCustom from "components/NumberFormat";
import Loading from "components/Loading";
import SolidGaugeCost from "components/Charts/SolidGauge/cost";
import Accordion from "components/Accordion";

//Hooks
import useFetch from "hooks/useHTTP";

// Constants
import { SPACE_CADET, WHITE, WILD_BLUE_YONDER } from "constants/colors";
import { PROJECT } from "constants/api";

// hooks
import { nanoid } from "nanoid";

//constants
import { COLORS2, COLORS4, COLORS5 } from "components/Charts/SolidGauge/themes";
import Truncate from "react-truncate";

// Styles
const useStyles = makeStyles(() => ({
  children: {
    borderTop: `0.5px solid rgba(255, 255, 255, .2)`,
    borderBottom: `0.5ppx solid rgba(255, 255, 255, .2)`,
    width: "100%",
    height: "55px",
    display: "flex",
    alignItems: "center",
    "&:first-of-type": {
      borderTop: `0`,
    },
    "&:last-of-type": {
      borderBottom: `1px solid rgba(255, 255, 255, .2)`,
    },
  },
  column: {
    marginBottom: 20,
  },
  containerGraph: {
    height: "280px",
    width: "234px",
  },

  containerMargin: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    height: "280px",
    width: "234px",
  },

  graph: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
  firstCol: {
    width: "234px",
  },

  rowNumber: {
    width: "234px",
    fontSize: 15,
    textAlign: "end",
    color: WHITE,
  },
  rowText: {
    fontSize: 12.5,
    width: "234px",
    color: WILD_BLUE_YONDER,
  },

  totRow: {
    display: "flex",
    marginLeft: 35,
  },
  title: {
    fontSize: "30px",
    fontWeight: "bold",
  },
  totNumber: {
    fontSize: 27,
    width: "234px",
    textAlign: "end",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginBottom: "25px",
    paddingLeft: "10px",
  },
  totText: {
    textTransform: "uppercase",
    fontSize: 15,
    width: "234px",
    textAlign: "end",
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
  },
  loadingWrapper: {
    height: "100%",
  },
  boxMargin: {
    width: "180px",
    height: "180px",
    backgroundColor: SPACE_CADET,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  marginText: {
    fontSize: 35,
  },
  subMarginText: {
    textTransform: "uppercase",
    fontSize: 10,
  },
  triangle: {
    width: 0,
    height: 0,
    borderLeft: "50px solid",
    borderLeftColor: SPACE_CADET,
    borderTop: "90px solid transparent",
    borderBottom: "90px solid transparent",
  },
}));

const Economics = () => {
  const classes = useStyles({});
  const { projectId } = useParams();
  const { get, loading } = useFetch();
  const [data, setData] = useState();

  // DATI
  // costs = altri costi
  // worked costs = costi ricorrenti
  // estimated cost = stima a finire
  // costi pianificati = costi piantificati
  // margine previsto = estimated margin
  // margine atteso = planned margin
  // margine attuale = current margin
  // Attenzione all'utente

  // API
  const getData = async () => {
    await get(`${PROJECT}/${projectId}/margins`)
      .then(res => {
        if (res.ok) {
          setData(res?.data);
        }
      })
      .catch(e => console.log("e", e));
  };

  useEffect(() => {
    getData();
  }, []);

  const dataPlanned = {
    percentage: Math.round(data?.plannedMarginPercentage * 1) / 1,
    labelPercentage: "Margine pianificato",
    series: [
      {
        category: "Margine pianificato",
        value:
          Math.round(data?.plannedMarginPercentage * 1) / 1 < 0
            ? 100
            : Math.round(data?.plannedMarginPercentage * 1) / 1,
        full: 100,
      },
    ],
  };
  const dataWorked = {
    percentage: Math.round(data?.expectedMarginPercentage * 1) / 1,
    labelPercentage: "Margine previsto",
    series: [
      {
        category: "Margine previsto",
        value:
          Math.round(data?.expectedMarginPercentage * 1) / 1 < 0
            ? 100
            : Math.round(data?.expectedMarginPercentage * 1) / 1,
        full: 100,
      },
    ],
  };

  const dataReported = {
    percentage: Math.round(data?.currentMarginPercentage * 1) / 1,
    labelPercentage: "Margine consuntivo",
    series: [
      {
        category: "Margine consuntivo",
        value:
          Math.round(data?.currentMarginPercentage * 1) / 1 < 0
            ? 100
            : Math.round(data?.currentMarginPercentage * 1) / 1,
        full: 100,
      },
    ],
  };
  const renderColor = (value, expectedMargin) => {
    const plannedValue = Math.round(value * 1) / 1;
    let color = COLORS2;
    if (plannedValue < 0) {
      color = COLORS4;
    } else if (value >= 0 && value < expectedMargin) {
      color = COLORS5;
    }
    return color;
  };

  const renderGraph = () => {
    const expectedMargin = data?.expectedMargin;
    return (
      <div className={classes.graph}>
        <div className={classes.containerMargin}>
          <div className={classes.boxMargin}>
            <Typography className={classes.marginText}>
              {data?.expectedMargin}%
            </Typography>
            <Typography className={classes.subMarginText}>
              {"margine target"}
            </Typography>
          </div>
          <div className={classes.triangle}></div>
        </div>

        <div className={classes.containerGraph}>
          <SolidGaugeCost
            type={1}
            id={"solidGauge1"}
            data={dataPlanned}
            theme={renderColor(data?.plannedMarginPercentage, expectedMargin)}
          />
        </div>
        <div className={classes.containerGraph}>
          <SolidGaugeCost
            type={1}
            id={"solidGauge2"}
            data={dataWorked}
            theme={renderColor(data?.expectedMarginPercentage, expectedMargin)}
          />
        </div>
        <div className={classes.containerGraph}>
          <SolidGaugeCost
            type={1}
            id={"solidGauge3"}
            data={dataReported}
            theme={renderColor(data?.currentMarginPercentage, expectedMargin)}
          />
        </div>
        <div className={classes.containerGraph} />
      </div>
    );
  };

  const renderLabel = () => {
    return (
      <div className={classes?.totRow}>
        <div className={classes.firstCol} />
        <Typography className={classes.totText}>
          {"Costi pianificati"}
        </Typography>
        <Typography className={classes.totText}>{"Costi Previsti"}</Typography>
        <Typography className={classes.totText}>
          {"Costi consuntivati"}
        </Typography>
        <Typography className={classes.totText}>{"Stima a finire"}</Typography>
      </div>
    );
  };

  const renderNumber = () => {
    return (
      <div className={classes?.totRow}>
        <div className={classes.firstCol} />
        <div className={classes.totNumber}>
          <NumberFormatCustom
            value={data?.plannedCost + data?.costsSum}
            suffix="€"
          />
        </div>
        <div className={classes.totNumber}>
          <NumberFormatCustom
            value={data?.estimatedCost + data?.workedCost + data?.costsSum}
            suffix="€"
          />
        </div>
        <div className={classes.totNumber}>
          <NumberFormatCustom
            value={data?.workedCost + data?.costsSum}
            suffix="€"
          />
        </div>
        <div className={classes.totNumber}>
          <NumberFormatCustom value={data?.estimatedCost} suffix="€" />
        </div>
      </div>
    );
  };

  const renderChildren = () => {
    return data?.userProjectCosts?.map(el => {
      const providedFor = el?.estimatedCost + el?.workedCost;
      const name = el?.user?.surname + " " + el?.user?.name;
      return (
        <div className={classes?.children} key={nanoid()}>
          <div style={{ width: 234 }}>
            <Typography className={classes.rowText}>
              <Truncate lines={2}>{name.toUpperCase()}</Truncate>
            </Typography>
          </div>

          <div className={classes.rowNumber}>
            <NumberFormatCustom
              decimalScale={el?.plannedCost === 0 ? 0 : 2}
              value={el?.plannedCost}
              suffix="€"
            />
          </div>
          <div className={classes.rowNumber}>
            <NumberFormatCustom
              decimalScale={el?.estimatedCost + el?.workedCost === 0 ? 0 : 2}
              value={providedFor}
              suffix="€"
            />
          </div>
          <div className={classes.rowNumber}>
            <NumberFormatCustom
              decimalScale={el?.workedCost === 0 ? 0 : 2}
              value={el?.workedCost}
              suffix="€"
            />
          </div>
          <div className={classes.rowNumber}>
            <NumberFormatCustom
              decimalScale={el?.estimatedCost === 0 ? 0 : 2}
              value={el?.estimatedCost}
              suffix="€"
            />
          </div>
        </div>
      );
    });
  };

  const renderCosts = () => {
    return data?.costs?.map(el => {
      return (
        <div className={classes?.children} key={nanoid()}>
          <Typography className={classes.rowText}>{el?.description}</Typography>
          <div className={classes.rowNumber}>
            <NumberFormatCustom
              decimalScale={el?.value === 0 ? 0 : 2}
              value={el?.value}
              suffix="€"
            />
          </div>
          <div className={classes.rowNumber}>
            <NumberFormatCustom
              decimalScale={el?.value === 0 ? 0 : 2}
              value={el?.value}
              suffix="€"
            />
          </div>
          <div className={classes.rowNumber}>
            <NumberFormatCustom
              decimalScale={el?.value === 0 ? 0 : 2}
              value={el?.value}
              suffix="€"
            />
          </div>
          <div className={classes.rowNumber}></div>
        </div>
      );
    });
  };

  return loading ? (
    <div className={classes.loadingWrapper}>
      <Loading showWrapper={false} />
    </div>
  ) : (
    <div className={classes.wrapper}>
      {renderGraph()}
      {renderLabel()}
      {renderNumber()}
      {data?.userProjectCosts?.length > 0 && (
        <div className={classes.column}>
          <Accordion
            title={{
              name: "Risorse",
            }}
            key={nanoid()}
            details={renderChildren()}
            defaultExpanded
            heightScroll={"300px"}
          />
        </div>
      )}
      {data?.costs?.length > 0 && (
        <div className={classes.column}>
          <Accordion
            title={{
              name: "Altri costi",
            }}
            key={nanoid()}
            details={renderCosts()}
            defaultExpanded
            heightScroll={"300px"}
          />
        </div>
      )}
    </div>
  );
};

export default Economics;

import React from "react";
import PropTypes from "prop-types";

// Material
import { Typography, makeStyles } from "@material-ui/core";
import { SPACE_CADET } from "constants/colors";

// Components
import PerfectScrollbar from "react-perfect-scrollbar";
import Date from "components/Date";

// Contants
import { WILD_BLUE_YONDER } from "constants/colors";

const useStyles = makeStyles(theme => ({
  box: {
    minWidth: "330px",
    minHeight: "88px",
    backgroundColor: SPACE_CADET,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: "10px 0",
    padding: "10px ",
  },
  container: {
    flex: "0 0 457px",
    padding: "0 20px",
    height: "289px",
    position: "relative",
    marginTop: 40,
  },
  noteContainer: {
    display: "flex",
    flexDirection: "column",
  },
  text: {
    fontSize: "20px",
    color: theme.palette.text.primary,
  },
  text2: {
    fontSize: "13px",
    color: theme.palette.text.primary,
    marginBottom: "10px",
  },
  text3: {
    fontSize: "13px",
    color: WILD_BLUE_YONDER,
    margin: "10px 0",
  },
}));

const Note = ({ data }) => {
  const classes = useStyles();
  if (data?.notes?.length === 0) return null;

  const renderText = (str, customClass) => {
    return (
      <Typography component="h3" className={customClass || classes.text}>
        {str}
      </Typography>
    );
  };

  return (
    <div className={classes.container}>
      {renderText("Note da report")}
      <PerfectScrollbar
        id="pf-notes-brick"
        style={{ height: "calc(100% - 35px)" }}
      >
        <div className={classes.noteContainer}>
          {data?.notes?.map(({ description, creationDateTime }, index) => (
            <div key={index} className={classes.box}>
              <Date dateString={creationDateTime} className={classes.text3} />
              {renderText(description, classes.text2)}
            </div>
          ))}
        </div>
      </PerfectScrollbar>
    </div>
  );
};

Note.propTypes = {
  data: PropTypes.object,
};

export default Note;

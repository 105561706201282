import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router";
// Date
import dayjs from "dayjs";
// hooks
import { useHistory } from "react-router-dom";

// Material
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

// Components
import Layout from "components/Layout";
import ChartCard from "components/ChartCard";
import Button from "components/Button";
import Loading from "components/Loading";
import Date from "components/Date";
import CustomSelect from "components/CustomSelect";
import useFilters from "hooks/useFilters";

// Charts and Themes
import AreaWithDateManager from "components/Charts/AreaWithDate/manager";
import StackedColumnManager from "components/Charts/StackedColumn/manager";
import GanttManager from "components/Charts/Gantt/manager";

// Constants
import {
  WILD_BLUE_YONDER,
  WHITE,
  PRIMARY,
  PACIFIC_BLU,
  MALACHITE,
} from "constants/colors";

// API
import {
  BRICKS,
  PLANNER_BRICKS_REPORT,
  PLANNER_BRICKS_ESTIMATE_FINISH,
} from "constants/api";

// Page components
import Evaluation from "./components/Evaluation";
import Note from "./components/Note";
import Report from "./components/Report";
import Skill from "./components/Skill";
import Checklist from "./components/Checklist";

// Enum
import { brickFiltersTypes } from "utils/enums/bricks";

//Hooks
import useFetch from "hooks/useHTTP";

//Utils
import { brickTypes } from "utils/enums/bricks";
import {
  timeToDays,
  timeToHours,
  truncateDecimals,
  parseDate,
  parseMounth,
  parseWeek,
} from "utils";

// Context
import { AppContext } from "context/AppContext";

const useStyles = makeStyles(() => ({
  headerBox: {
    display: "flex",
    justifyContent: "space-between",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "12px 0 20px",
  },
  chartContainer: {
    height: 300,
    marginTop: 10,
  },
  disabledBrick: {
    background: WILD_BLUE_YONDER,
    color: WHITE,
  },
  filters: {
    display: "flex",
  },
  filter: {
    borderRadius: 20,
    textTransform: "uppercase",
    padding: 10,
    margin: 5,
    cursor: "pointer",
  },
  selectedFilter: {
    background: PRIMARY,
  },
  kpiContainer: {
    display: "flex",
    alignItems: "flex-start",
    margin: "50px 0 0 0",
    padding: "50px 0",
    flexWrap: "wrap",
  },
  noData: {
    margin: "30px 10px",
  },
  loadingWrapper: {
    height: "350px",
  },
  dateColumns: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  dateRow: {
    height: "50px !important",
    display: "flex",
    justifyContent: "space-between",
    margin: "20px 10px 10px 10px",
  },
  detailFirstCol: {
    flex: "0 0 457px",
  },
  titleProject: {
    fontSize: 15,
  },
  headerMargin: {
    marginTop: "10px",
  },
}));

const BrickDashboard = () => {
  const classes = useStyles();
  const { get, put } = useFetch();
  const history = useHistory();

  // Context
  const { addBreadcrumb, setPageTitle, setOwner, setRefreschSecondCol } =
    useContext(AppContext);

  // State
  const [loadingProject, setLoadingProject] = useState(false);
  const [loadingReport, setLoadingReport] = useState(false);
  const [loadingEstimated, setLoadingEstimated] = useState(false);

  const [projectData, setProjectData] = useState(null);
  const [reportGraphData, setReportGraphData] = useState(null);
  const [estimateFinishGraphData, setEstimateFinishGraphData] = useState(null);
  const [ratingManager, setRatingManager] = useState(0);
  const [reportScrollBars, setReportScrollBars] = useState(false);
  const { id } = useParams();
  const { filters, setFilters } = useFilters({
    EstimatedFilter: brickFiltersTypes.DAYS,
    ReportFilter: brickFiltersTypes.DAYS,
  });

  // Effects
  useEffect(() => {
    fetchProject();
  }, []);

  useEffect(() => {
    fetchEstimateFinishGraph(filters.EstimatedFilter);
  }, [filters.EstimatedFilter]);

  useEffect(() => {
    fetchReportGraph(filters.ReportFilter);
  }, [filters.ReportFilter]);

  // Renders
  const renderLoading = () => (
    <Loading
      showWrapper={false}
      animationStyle={{ height: 80, width: 80, marginTop: -100 }}
    />
  );

  useEffect(() => {
    if (projectData) {
      addBreadcrumb({
        name: projectData?.name,
      });
      setPageTitle(projectData?.name);
    }
  }, [projectData]);

  // API GET BRICKS
  const fetchProject = async () => {
    try {
      setLoadingProject(true);
      const res = await get(`${BRICKS}/${id}`);
      if (res.ok) {
        setProjectData(res.data);
        const path = res?.data?.user;
        //QUI CI VA L'OWNER, temporaneamente ci metto user
        setOwner({
          name: path?.name + " " + path?.surname,
          id: path?.id,
          image: path?.imageUrl,
          jobTitle: "owner di brick",
          onClick: () => history.push(`/user/${path?.id}`),
        });
      }
      setLoadingProject(false);
    } catch (err) {
      console.log(err);
      setLoadingProject(false);
    }
  };

  // API GET REPORT GRAPH
  const fetchReportGraph = async (range = 1) => {
    try {
      setLoadingReport(true);

      let filterFunction;
      switch (filters.ReportFilter) {
        case brickFiltersTypes.DAYS:
          filterFunction = (...args) => parseDate(...args);
          break;
        case brickFiltersTypes.WEEKS:
          filterFunction = (...args) => parseWeek(...args);
          break;
        case brickFiltersTypes.MONTHS:
          filterFunction = (...args) => parseMounth(...args);
          break;
        default:
          filterFunction = (...args) => parseDate(...args);
          break;
      }

      const res = await get(`${BRICKS}/${id}/${PLANNER_BRICKS_REPORT}${range}`);
      if (res.ok) {
        var newData = res.data.map(time => {
          var y = timeToHours(time.y) || 0;
          var extraY = timeToHours(time.extraY);
          return {
            x: filterFunction(time.x),
            y,
            extraY,
            dayToISOString: time.x,
          };
        });

        if (newData.length > 30) {
          setReportScrollBars(true);
        }

        setReportGraphData(newData);
      }
      setLoadingReport(false);
    } catch (err) {
      console.log(err);
      setLoadingReport(false);
    }
  };

  // API GET ESTIMATE FINISH GRAPH
  const fetchEstimateFinishGraph = async (range = 1) => {
    try {
      setLoadingEstimated(true);
      const res = await get(
        `${BRICKS}/${id}/${PLANNER_BRICKS_ESTIMATE_FINISH}${range}`
      );
      if (res.ok) {
        var newData = res.data.map(time => {
          var y = truncateDecimals(timeToDays(time.y) || 0, 1);
          return {
            x: time.x,
            y,
          };
        });
        setEstimateFinishGraphData(newData);
      }
      setLoadingEstimated(false);
    } catch (err) {
      console.log(err);
      setLoadingEstimated(false);
    }
  };

  const closeOrReassignBrick = async () => {
    const ratingData = {
      rating: ratingManager,
    };
    try {
      const res = await put(`${BRICKS}/${id}/rate`, ratingData);
      if (res.ok) {
        fetchProject();
        fetchReportGraph();
        fetchEstimateFinishGraph();
        setRefreschSecondCol(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onChange = (e, filterKey) => {
    let value = e.target.value;
    setFilters({ ...filters, [filterKey]: value });
  };

  const filtersRange = [
    { value: brickFiltersTypes.DAYS, label: "DAYS" },
    { value: brickFiltersTypes.WEEKS, label: "WEEKS" },
    { value: brickFiltersTypes.MONTHS, label: "MONTHS" },
  ];

  const renderSelect = (disabled, filterKey, minWidth) => {
    return (
      <div className={classes.select}>
        <CustomSelect
          options={filtersRange}
          disabled={disabled}
          onChange={e => onChange(e, filterKey)}
          value={filters[filterKey]}
          minWidth={minWidth}
        />
      </div>
    );
  };

  const renderHeader = () => (
    <div>
      <div className={classes.headerMargin}>
        <Typography className={classes.titleProject}>
          {"Nome progetto:"} <b>{projectData?.projectName}</b>
        </Typography>
        <Typography className={classes.titleProject}>
          {"Nome fase:"} <b>{projectData?.phaseName}</b>
        </Typography>
      </div>
      {projectData?.status === brickTypes.TO_VALIDATE && (
        <div className={classes.buttonContainer}>
          <Button
            variant="contained"
            disabled={ratingManager > 0}
            color={ratingManager === 0 ? "primary" : "default"}
            className={ratingManager > 0 ? classes.disabledBrick : ""}
            onClick={closeOrReassignBrick}
            style={{ marginRight: 10 }}
          >
            RIASSEGNA BRICK
          </Button>

          <Button
            variant="contained"
            disabled={ratingManager === 0}
            color={ratingManager > 0 ? "primary" : "default"}
            className={ratingManager === 0 ? classes.disabledBrick : ""}
            onClick={closeOrReassignBrick}
          >
            CHIUDI BRICK
          </Button>
        </div>
      )}
    </div>
  );

  const renderEvaluation = () =>
    !loadingProject && (
      <Evaluation
        data={projectData}
        ratingManager={ratingManager}
        setRatingManager={setRatingManager}
      />
    );

  const renderGanttGraph = () =>
    loadingProject ? (
      <div className={classes.loadingWrapper}>{renderLoading()} </div>
    ) : (
      projectData && (
        <div className={classes.chartContainer}>
          <ChartCard height={"300px"} title="Time">
            <GanttManager
              id={"gantt1"}
              data={[
                {
                  name: "PIANIFICATO",
                  fromDateName: "Data di inizio pianificata",
                  fromDate:
                    dayjs(projectData.plannedStartDate).format("DD MMM YYYY") ||
                    null,
                  toDateName: "Data di fine pianificata",
                  toDate:
                    dayjs(projectData.plannedEndDate).format("DD MMM YYYY") ||
                    null,
                  color: PACIFIC_BLU,
                },
                {
                  name: "REALE",
                  fromDateName: "Data di inizio reale",
                  fromDate: dayjs(projectData.displayStartDate).format(
                    "DD MMM YYYY"
                  ),
                  toDateName: projectData.realEndDate
                    ? "Data di fine reale"
                    : "Data di fine prevista",
                  toDate: dayjs(projectData.displayEndDate).format(
                    "DD MMM YYYY"
                  ),
                  color: MALACHITE,
                },
              ]}
            />
          </ChartCard>
        </div>
      )
    );

  const renderEstimatedGraph = () =>
    loadingEstimated ? (
      <div className={classes.loadingWrapper}>{renderLoading()} </div>
    ) : (
      estimateFinishGraphData && (
        <>
          <div className={classes.chartContainer}>
            <ChartCard
              title="Andamento modifica stima a finire"
              filters={renderSelect(false, "EstimatedFilter", "194px")}
            >
              <AreaWithDateManager
                id={"areaWithDate1"}
                data={estimateFinishGraphData}
              />
            </ChartCard>
          </div>
          {projectData && (
            <div className={classes.dateRow}>
              <div className={classes.dateColumns}>
                <Date variant="h6" dateString={projectData.displayStartDate} />
                {(projectData.realStartDate && "Data inizio reale") ||
                  "Data inizio pianifcata"}
              </div>
              <div className={classes.dateColumns}>
                <Date variant="h6" dateString={projectData.displayEndDate} />
                {(projectData.realEndDate && "Data fine reale") ||
                  "Data fine prevista"}
              </div>
            </div>
          )}
        </>
      )
    );

  const renderReportGraph = () =>
    loadingReport ? (
      <div className={classes.loadingWrapper}> {renderLoading()} </div>
    ) : (
      reportGraphData && (
        <div className={classes.chartContainer}>
          <ChartCard
            height={"350px"}
            title="Ore rendicontate nel tempo"
            filters={renderSelect(
              /*estimateFinishGraphData && estimateFinishGraphData?.Count !== "100"
                ? false
                : true,*/
              false,
              "ReportFilter",
              "194px"
            )}
          >
            <StackedColumnManager
              id={"stackedColumn1"}
              scrollBars={reportScrollBars}
              data={reportGraphData}
            />
          </ChartCard>
        </div>
      )
    );

  const renderReportDetail = () =>
    loadingProject ? (
      <div className={classes.loadingWrapper}> {renderLoading()} </div>
    ) : projectData ? (
      <div className={classes.kpiContainer}>
        <div className={classes.detailFirstCol}>
          <Report brickData={projectData} />
          <Note data={projectData} />
        </div>
        <Checklist disabled data={projectData?.checklists} />
        <Skill data={projectData?.skills} />
      </div>
    ) : (
      <Typography variant="h5" className={classes?.noData}>
        Non ci sono dati disponibili
      </Typography>
    );

  const renderProject = () => (
    <>
      {renderHeader()}
      {renderEvaluation()}
      {renderGanttGraph()}
      {renderEstimatedGraph()}
      {renderReportGraph()}
      {renderReportDetail()}
    </>
  );

  const headerIcons = [
    {
      icon: "do",
    },
  ];

  return <Layout headerIcons={headerIcons}>{renderProject()}</Layout>;
};

export default BrickDashboard;

import React, { useState, useReducer, useContext, useEffect } from "react";
import clsx from "clsx";
import { Link, useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";

// Material UI
import {
  Typography,
  IconButton,
  Button,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";

// custom components
import Logo from "components/Logo";
import Layout from "components/Layout";
import Loading from "components/Loading";
import TextField from "components/TextField";

// context
import { AppContext } from "context/AppContext";
import { AuthContext } from "context/AuthContext";

// icons
import { ReactComponent as MailOutlineIcon } from "assets/email.svg";
import { ReactComponent as LockOpenIcon } from "assets/lock.svg";
import { ReactComponent as VisibilityOffIcon } from "assets/visibilityOff.svg";
import { ReactComponent as VisibilityIcon } from "assets/visibility.svg";
import WhiteCheckedIcon from "assets/check.svg";

// constants
import {
  FORGOT_PASSWORD,
  WALKTHROUGH,
  ROOT,
  RESET_PASSWORD,
} from "constants/routes";
import { ERROR, WILD_BLUE_YONDER } from "constants/colors";

const useStyles = makeStyles(theme => ({
  img: {
    width: 20,
    marginRight: theme.spacing(1),
  },
  wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  contentWrapper: {
    display: props => (props.loading || props.user ? "none" : "flex"),
    flexDirection: "column",
    width: "20%",
  },
  welcome: {
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(2.5),
    alignSelf: "center",
  },
  form: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
  formItem: {
    minHeight: 54,
  },
  margin: {
    margin: `${theme.spacing(1)}px 0px`,
    width: "100%",
    "&:focus": {
      outline: "none",
    },
  },
  iconButton: {
    color: "white",
    width: "100%",
  },
  checkboxWrapper: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  checkbox: {
    color: WILD_BLUE_YONDER,

    "& span": {
      paddingLeft: 0,
    },
  },
  uncheckedIcon: {
    borderRadius: 3,
    border: props =>
      props.errors && props.value === "privacy" && props.errors.privacy
        ? `1px solid ${ERROR}`
        : "none",
    width: 24,
    height: 24,
    backgroundColor: theme.palette.componentsBackground.main,
  },
  checkedIcon: {
    borderRadius: 3,
    backgroundColor: theme.palette.primary.main,
    "&:before": {
      display: "block",
      width: 24,
      height: 24,
      backgroundImage: `url(${WhiteCheckedIcon})`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "60%",
      content: '""',
    },
  },
  linkWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: theme.spacing(12),
  },
  link: {
    color: "white",
    textDecoration: "none",
  },
}));

const defaultValues = {
  email: "",
  password: "",
  showPassword: false,
  autologin: false,
  privacy: false,
};

function reducer(state, action) {
  return { ...state, [action.type]: action.value };
}

function Login() {
  const history = useHistory();
  const { usersWalkThrough, setModalConfig } = useContext(AppContext);

  const { user, setUser, handleLogin, handleLogOut } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useReducer(reducer, defaultValues);
  const [helperText, setHelperText] = useState(false);
  const classes = useStyles({ loading, user });

  // Redirect on Home page if user is already logged
  useEffect(() => {
    if (user) {
      history.replace(ROOT);
    }
  }, []);

  // Form
  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    clearErrors,
    setValue,
  } = useForm({
    mode: "onChange",
    defaultValues,
  });

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const StyledCheckbox = props => {
    const checkboxProps = { ...props };
    const value = checkboxProps.checked;
    const classes = useStyles({ errors, value });

    return (
      <Checkbox
        disableRipple
        color="primary"
        checkedIcon={<span className={classes.checkedIcon} />}
        icon={<span className={classes.uncheckedIcon} />}
        {...props}
      />
    );
  };

  const login = async formData => {
    const { email, password } = formData;
    setLoading(true);
    setHelperText(false);
    try {
      const userData = await handleLogin(email, password, state.autologin);
      if (userData.canAccessWeb) {
        setUser(userData);
        setLoading(false);
        // Verify if is first login (confirmedAt param not present) or not
        if (userData.confirmedAt) {
          // Replace browser history item with home page route
          if (usersWalkThrough == undefined || usersWalkThrough == null)
            history.push(WALKTHROUGH);
          else {
            if (!usersWalkThrough.includes(userData.id))
              history.push(WALKTHROUGH);
            else history.replace(ROOT);
          }
        } else {
          history.push(RESET_PASSWORD);
        }
      } else {
        // User cannot access to web
        setLoading(false);
        await handleLogOut();
        setModalConfig({
          title: "Impossibile accedere al web",
          content:
            "Il tuo profilo non ha i permessi necessari \n per accedere ai servizi web",
          primaryAction: {
            text: "OK",
          },
        });
      }
    } catch (err) {
      setHelperText(true);
      setLoading(false);
    }
  };

  return (
    <Layout showHeader={false} showFirstCol={false} showSecondCol={false}>
      <div className={classes.wrapper}>
        {(loading || user) && <Loading />}
        <div className={classes.contentWrapper}>
          <Logo isBlue />
          <Typography variant="h5" className={classes.welcome}>
            Benvenuto
          </Typography>
          <form
            className={classes.form}
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(data => login(data))}
          >
            <Controller
              render={({ field }) => (
                <TextField
                  className={clsx(classes.margin, classes.formItem)}
                  type="email"
                  label="EMAIL"
                  icon={<MailOutlineIcon />}
                  {...field}
                  error={!!errors.email || !!helperText}
                  onChange={e => {
                    setValue("email", e.target.value || null);
                    setHelperText(false);
                    clearErrors(["email", "password"]);
                    field.onChange(e.target.value);
                  }}
                />
              )}
              defaultValue={defaultValues.email}
              control={control}
              name="email"
              rules={{
                required: true,
              }}
            />
            <Controller
              render={({ field }) => (
                <TextField
                  className={clsx(classes.margin, classes.formItem)}
                  type={state.showPassword ? "text" : "password"}
                  label="PASSWORD"
                  icon={<LockOpenIcon />}
                  {...field}
                  error={!!errors.password || !!helperText}
                  onChange={e => {
                    setValue("password", e.target.value || null);
                    setHelperText(false);
                    clearErrors(["email", "password"]);
                    field.onChange(e.target.value);
                  }}
                  endIcon={
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        setState({
                          type: "showPassword",
                          value: !state.showPassword,
                        })
                      }
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      className={classes.iconButton}
                    >
                      {state.showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  }
                />
              )}
              defaultValue={defaultValues.password}
              control={control}
              name="password"
              rules={{
                required: true,
              }}
            />
            {helperText && (
              <Typography variant="subtitle1" color="error">
                Username e password non corretti
              </Typography>
            )}
            <div className={classes.checkboxWrapper}>
              <FormControlLabel
                className={classes.checkbox}
                control={
                  <StyledCheckbox
                    checked={state.autologin}
                    onChange={e =>
                      setState({ type: "autologin", value: e.target.checked })
                    }
                    value="autologin"
                  />
                }
                label="Rimani collegato"
              />
              <Controller
                render={({ field }) => (
                  <FormControlLabel
                    className={classes.checkbox}
                    control={
                      <StyledCheckbox
                        {...field}
                        checked={field.value}
                        inputRef={field.ref}
                      />
                    }
                    label="I have read and accept the Terms of Service and Privacy Policy"
                  />
                )}
                control={control}
                name="privacy"
                rules={{
                  required: { value: true },
                }}
              />
            </div>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              className={classes.margin}
              disabled={!isValid}
            >
              ACCEDI
            </Button>
          </form>
          <span className={classes.linkWrapper}>
            <MailOutlineIcon className={classes.img} alt="forgot_password" />
            <Link to={FORGOT_PASSWORD} className={classes.link}>
              Hai dimenticato la password?
            </Link>
          </span>
        </div>
      </div>
    </Layout>
  );
}

export default Login;

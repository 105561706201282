import dayjs from "dayjs";

// ENUM
import { mounts } from "./enums/globals";

const weekOfYear = require("dayjs/plugin/weekOfYear");
dayjs.extend(weekOfYear);

export const timeToHours = time => {
  if (!time) return null;
  var array = time.split(":");
  var seconds = parseInt(array[0], 10) * 60 * 60 + parseInt(array[1], 10) * 60;
  return seconds / 60 / 60;
};

export const timeToFormattedHoursMinutesString = (time, lowercase = true) => {
  if (!time) return null;
  var array = time.split(":");
  let hoursString = lowercase ? "h" : "H";
  let minutesString = lowercase ? "m" : "M";
  let minutes = array[1] > 0 ? array[1] : null;

  return (
    array[0] + hoursString + " " + (minutes ? minutes + minutesString : "")
  );
};

export const timeToDays = time => {
  if (!time) return null;
  var array = time.split(":");
  return array[0] / 8;
};

export function truncateDecimals(num, digits) {
  if (isNaN(num)) return null;
  var numS = num.toString(),
    decPos = numS.indexOf("."),
    substrLength = decPos == -1 ? numS.length : 1 + decPos + digits,
    trimmedResult = numS.substr(0, substrLength),
    finalResult = isNaN(trimmedResult) ? 0 : trimmedResult;
  return parseFloat(finalResult);
}

export function parseDate(data) {
  let day = new Date(data).getDate();
  let mounth = new Date(data).getMonth();
  return day + " " + mounts[mounth];
}

export function parseWeek(data) {
  return dayjs(data.substring(0, 10)).week() + " WK";
}

export function parseMounth(data) {
  let data2 = new Date(data);
  let sufx =
    mounts[data2.getMonth()] === mounts[0]
      ? data2.getFullYear().toString().substr(2, 4)
      : " ";
  return mounts[data2.getMonth()] + " " + sufx;
}

import React from "react";
import theme from "theme";
import PropTypes from "prop-types";
import clsx from "clsx";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
// Enums
import { collarTypes } from "utils/enums/users";
// Constants
import { SPACE_CADET, WHITE } from "constants/colors";

// Images
import userPlaceholder from "assets/user_placeholder.svg";

const useStyles = makeStyles(() => ({
  wrapper: ({ collarColor }) => ({
    borderWidth: collarColor ? 5 : 0,
    borderColor: collarColor,
    borderStyle: "solid",
    borderRadius: "100%",
    padding: collarColor && 6,
  }),
  avatar: ({ image, onClick, size }) => ({
    borderRadius: "50%",
    backgroundColor: SPACE_CADET,
    backgroundImage: `url("${image || userPlaceholder}")`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    cursor: onClick ? "pointer" : "default",
    width: size,
    height: size,
  }),
}));

const Avatar = ({ collar, image, onClick, size, className }) => {
  const collarColors = {
    [collarTypes.WHITE]: WHITE,
    [collarTypes.BLUE]: theme.palette.primary.main,
  };

  const classes = useStyles({
    collarColor: collarColors[collar],
    image,
    size,
    onClick,
  });
  return (
    <div onClick={onClick} className={clsx(classes.wrapper, className)}>
      <div className={classes.avatar} />
    </div>
  );
};

Avatar.defaultProps = {
  collar: false,
  image: null,
  size: "2.5rem",
};

Avatar.propTypes = {
  className: PropTypes.string,
  collar: PropTypes.oneOf([false, ...Object.values(collarTypes)]),
  image: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default Avatar;

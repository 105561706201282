import React, { useEffect, useState, useContext } from "react";

// Hooks
import useFetch from "hooks/useHTTP";
import { useForm, Controller } from "react-hook-form";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid } from "@material-ui/core";

// Components
import Layout from "components/Layout";
import Button from "components/Button";
import Loading from "components/Loading";
import TextField from "components/TextField";
import Navigation from "../components/Navigation";

// Constants
import { GENERAL_SETTINGS } from "constants/api";

// Context
import { AppContext } from "context/AppContext";

// Styles
const useStyles = makeStyles(theme => ({
  paragraph: {
    width: "100%",
    borderBottom: "1px solid white",
    marginTop: `${theme.spacing(8)}px`,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  gridContainer: {
    margin: `${theme.spacing(2)}px 0`,
  },
}));

const SettingsGeneral = () => {
  // Hooks
  const classes = useStyles({});
  const { handleSubmit, control, setValue } = useForm();
  const { get, post /* , put */ } = useFetch();
  const { setModalConfig } = useContext(AppContext);

  // State
  const [settings, setSettings] = useState();
  const [loading, setLoading] = useState(false);

  // API
  const fetchSettings = async () => {
    await get(GENERAL_SETTINGS)
      .then(response => {
        if (response.ok) {
          setSettings(response.data);
          setLoading(false);
        }
      })
      .catch(e => {
        setLoading(false);
        console.log("e", e);
      });
  };

  // Effects
  useEffect(() => {
    fetchSettings();
  }, []);

  useEffect(() => {
    if (settings) {
      setValue("tenantName", settings.tenantName);
      setValue("doGrooveTitle", settings.doGrooveTitle);
      setValue("beGrooveTitle", settings.beGrooveTitle);
      setValue("tenantTargetMargin", settings.tenantTargetMargin);
    }
  }, [settings]);

  const saveSettings = async data => {
    setModalConfig({
      type: "loading",
    });
    const body = { ...data };
    if (settings?.id) {
      body.id = settings.id;
    }
    body.tenantTargetMargin = parseInt(data.tenantTargetMargin, 10);
    /* if (settings.id) {
      await put(GENERAL_SETTINGS, body)
        .then(response => {
          if (response.ok) {
            setModalConfig({
              type: "success",
              primaryAction: {
                text: "OK",
                onClick: () => {
                  setModalConfig(null);
                },
              },
            });
          }
        })
        .catch(() => {
          setModalConfig({
            type: "error",
            primaryAction: {
              text: "OK",
              onClick: () => {
                setModalConfig(null);
              },
            },
          });
        });
    } else { */
    await post(GENERAL_SETTINGS, body)
      .then(response => {
        if (response.ok) {
          setModalConfig({
            type: "success",
            primaryAction: {
              text: "OK",
              onClick: () => {
                setModalConfig(null);
              },
            },
          });
        }
      })
      .catch(() => {
        setModalConfig({
          type: "error",
          primaryAction: {
            text: "OK",
            onClick: () => {
              setModalConfig(null);
            },
          },
        });
      });
    /* } */
  };

  return (
    <Layout showSecondCol={false} showHeader={false} showPosts={false}>
      <Navigation />
      {loading ? (
        <div className={classes.loading}>
          <Loading showWrapper={false} />
        </div>
      ) : (
        <form>
          <div className={classes.header}>
            <Typography variant="h5">Generale</Typography>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={handleSubmit(saveSettings)}
            >
              SALVA
            </Button>
          </div>
          <div className={classes.paragraph}>
            <Typography variant="h5">Groove</Typography>
          </div>
          <Grid className={classes.gridContainer} container spacing={2}>
            <Grid item xs={3} xl={3}>
              <Controller
                render={({ field }) => (
                  <TextField
                    label="Nome azienda"
                    shrink={!!field.value}
                    {...field}
                  />
                )}
                name="tenantName"
                control={control}
              />
            </Grid>
            <Grid item xs={3} xl={3}>
              <Controller
                render={({ field }) => (
                  <TextField label="Do" shrink={!!field.value} {...field} />
                )}
                name="doGrooveTitle"
                control={control}
              />
            </Grid>
            <Grid item xs={3} xl={3}>
              <Controller
                render={({ field }) => (
                  <TextField label="Be" shrink={!!field.value} {...field} />
                )}
                name="beGrooveTitle"
                control={control}
              />
            </Grid>
          </Grid>
          <div className={classes.paragraph}>
            <Typography variant="h5">Project</Typography>
          </div>
          <Grid className={classes.gridContainer} container spacing={2}>
            <Grid item xs={3} xl={3}>
              <Controller
                render={({ field, fieldState }) => (
                  <TextField
                    label="Margine target"
                    icon="%"
                    type="number"
                    error={fieldState.error}
                    helperText={fieldState.error && fieldState.error.message}
                    inputProps={{ min: 0, max: 100 }}
                    {...field}
                  />
                )}
                name="tenantTargetMargin"
                control={control}
                rules={{
                  min: { value: 0, message: "Inserire un valore tra 0 e 100" },
                  max: {
                    value: 100,
                    message: "Inserire un valore tra 0 e 100",
                  },
                }}
              />
            </Grid>
          </Grid>
        </form>
      )}
    </Layout>
  );
};

export default SettingsGeneral;

import React, { useContext, useState, useLayoutEffect } from "react";
import PropTypes from "prop-types";
//Hooks
import useDrawer from "hooks/useDrawer";
// Context
import { AppContext } from "context/AppContext";
// Material UI
import { makeStyles } from "@material-ui/core/styles";
// Components
import Header from "./Header";
import FirstCol from "./FirstCol";
import SecondCol from "./SecondCol";
import UserProfile from "sidebars/UserProfile";
import Drawer from "components/Drawer";

// Hooks
import useWindowSize from "hooks/useWindowSize";
// Layout config
import { firstColConfig, secondColConfig, mainColConfig } from "./config";

// Styles
const useStyles = makeStyles(() => ({
  wrapper: {
    display: "flex",
    height: "100vh",
  },
  mainCol: ({ showFirstCol, showSecondCol }) => ({
    height: "100%",
    flex: 1,
    paddingLeft:
      (showFirstCol || showSecondCol) && mainColConfig.horizontalPadding / 2,
    overflowX: "hidden",
  }),
  contentWrapper: ({
    mainColWidth,
    padRight,
    showFirstCol,
    showSecondCol,
    showHeader,
    showFilters,
  }) => ({
    height: showHeader
      ? showFilters
        ? "calc(100% - 140px)"
        : "calc(100% - 100px)"
      : "100%",
    width: mainColWidth || "auto",
    paddingRight:
      (showFirstCol || showSecondCol) &&
      padRight &&
      mainColConfig.horizontalPadding / 2,
  }),
}));

const Layout = ({
  children,
  padRight,
  showHeader,
  showFirstCol,
  showSecondCol,
  showFilters,
  headerIcons,
  showPosts,
}) => {
  // Context
  const { isFirstColOpen, isSecondColOpen } = useContext(AppContext);

  // State
  const [mainColWidth, setmainColWidth] = useState();
  const {
    isDrawerOpen: isUserProfileOpen,
    toggleDrawer: toggleUserProfileDrawer,
  } = useDrawer();

  // Hooks
  const [windowWidth] = useWindowSize();
  const classes = useStyles({
    isFirstColOpen,
    isSecondColOpen,
    mainColWidth,
    padRight,
    showFirstCol,
    showHeader,
    showSecondCol,
    showFilters,
  });

  // Effects
  useLayoutEffect(() => {
    setmainColWidth(windowWidth - getUnavailableWidth());
  }, [windowWidth, isFirstColOpen]);

  // Helpers
  const getUnavailableWidth = () => {
    let unavailableWidth = 0;

    const firstColWidth = firstColConfig?.width?.collapsed;
    const secondColWidth = secondColConfig?.width?.collapsed;

    if (showFirstCol && firstColWidth) unavailableWidth += firstColWidth;
    if (showSecondCol && secondColWidth) unavailableWidth += secondColWidth;
    if (showFirstCol || showSecondCol)
      unavailableWidth += mainColConfig.horizontalPadding / 2;

    if (isFirstColOpen)
      unavailableWidth +=
        firstColConfig.width.expanded - firstColConfig.width.collapsed;

    return unavailableWidth;
  };

  const renderMainCol = () => (
    <div className={classes.mainCol}>
      <div className={classes.contentWrapper}>
        {showHeader && (
          <Header
            padRight={!padRight}
            toggleUserProfileDrawer={toggleUserProfileDrawer}
            showFilters={showFilters}
            headerIcons={headerIcons}
          />
        )}
        {children}
      </div>
    </div>
  );

  return (
    <div className={classes.wrapper}>
      {showFirstCol && <FirstCol showPosts={showPosts} />}
      {showSecondCol && <SecondCol />}
      {renderMainCol()}
      <Drawer
        open={isUserProfileOpen}
        title={"Profilo utente"}
        // primaryText="CAMBIA PASSWORD" TODO : RIPRISTINARE
        // onPrimary={() => console.log("Cambia password")}
        themeType={"dark"}
        onClose={toggleUserProfileDrawer}
      >
        <UserProfile />
      </Drawer>
    </div>
  );
};

Layout.defaultProps = {
  padRight: true,
  showHeader: true,
  showFirstCol: true,
  showSecondCol: true,
  showFilters: false,
  showPosts: true,
};

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  padRight: PropTypes.bool,
  showHeader: PropTypes.bool,
  showFirstCol: PropTypes.bool,
  showSecondCol: PropTypes.bool,
  showFilters: PropTypes.bool,
  headerIcons: PropTypes.array,
  showPosts: PropTypes.bool,
};
export default Layout;

import React, { useEffect, useState, useContext } from "react";

// Hooks
import useFetch from "hooks/useHTTP";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

import { portfoliosConfig } from "components/Table/config";

// Icons
import { ReactComponent as EditIcon } from "assets/edit.svg";
import { ReactComponent as DeleteIcon } from "assets/delete-outlined.svg";
import plusIcon from "assets/plus-white.png";

// Components
import Button from "components/Button";
import Table from "components/Table";
import Layout from "components/Layout";
import Loading from "components/Loading";
import Navigation from "../components/Navigation";

// Constants
import { PORTFOLIO, SETTINGS_PORTFOLIOS } from "constants/api";

// Context
import { ManagingDrawersContext } from "context/ManagingDrawersContext";
import { AppContext } from "context/AppContext";

// Styles
const useStyles = makeStyles(theme => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(4),
  },
  buttonIcon: {
    width: 16,
    height: 16,
  },
}));

const SettingsPortfolios = () => {
  // Context
  const {
    setPortfolioDrawerOpen,
    setErrorMessage,
    setLoadingDrawer,
    setSelectedItem,
    retrigger,
    setRetrigger,
  } = useContext(ManagingDrawersContext);

  const { setModalConfig, setGlobalLoading } = useContext(AppContext);

  // Hooks
  const classes = useStyles({});
  const { get, del } = useFetch();

  // State
  const [portfolios, setPortfolios] = useState([]);
  const [loadingPortfolios, setLoadingPortfolios] = useState(false);

  // Effects
  useEffect(() => {
    fetchPortfolios();
  }, []);

  useEffect(() => {
    if (retrigger) {
      fetchPortfolios();
      setRetrigger();
    }
  }, [retrigger]);

  // Helpers
  const handlePortfolioDetele = id => {
    setModalConfig({
      title: "Eliminazione Portfolio",
      content: "Sei sicuro di voler eliminare il portfolio?",
      primaryAction: {
        text: "OK",
        onClick: () => deletePortfolio(id),
      },
      secondaryAction: {
        text: "ANNULLA",
      },
    });
  };

  // API
  const fetchPortfolios = async () => {
    setLoadingPortfolios(true);
    try {
      const res = await get(
        `${SETTINGS_PORTFOLIOS}?OnlyGrooveRoots=true&ForEachResultTraverseBranch=true`
      );
      setLoadingPortfolios(false);
      setPortfolios(res.data);
    } catch (err) {
      setLoadingPortfolios(false);
      setErrorMessage("Elaborazione non riuscita");
    }
  };

  const fetchPortfolio = async id => {
    setLoadingDrawer(true);
    await get(`${PORTFOLIO}/${id}`)
      .then(res => {
        if (res.ok) {
          setSelectedItem(res?.data);
          setLoadingDrawer(false);
        }
      })
      .catch(() => {
        setLoadingDrawer(false);
      });
  };

  const deletePortfolio = async portfolio => {
    setGlobalLoading(true);
    setModalConfig(null);
    try {
      await del(`${PORTFOLIO}/${portfolio.id}`);
      setRetrigger("portfolio");
      setGlobalLoading(false);
    } catch (err) {
      setGlobalLoading(false);
      console.error(err);

      if (err?.response?.status === 403) {
        setModalConfig({
          title: "Impossibile eliminare il Portfolio",
          content: `Il portfolio "${portfolio.name}" contiene degli elementi al suo interno`,
          primaryAction: {
            text: "OK",
          },
        });
      }
    }
  };

  return (
    <Layout showSecondCol={false} showHeader={false} showPosts={false}>
      <Navigation />
      <div className={classes.header}>
        <Typography variant="h5">Portfolio</Typography>
        <Button
          color="primary"
          variant="contained"
          startIcon={<img src={plusIcon} className={classes.buttonIcon} />}
          onClick={() => setPortfolioDrawerOpen(true)}
        >
          Nuovo portfolio
        </Button>
      </div>
      {loadingPortfolios ? (
        <div className={classes.loading}>
          <Loading showWrapper={false} />
        </div>
      ) : (
        <Table
          data={portfolios}
          config={portfoliosConfig}
          rowActions={[
            {
              onClick: item => {
                fetchPortfolio(item?.id);
                setPortfolioDrawerOpen(true);
              },
              icon: <EditIcon />,
            },
            {
              onClick: handlePortfolioDetele,
              icon: <DeleteIcon />,
            },
          ]}
        />
      )}
    </Layout>
  );
};

export default SettingsPortfolios;

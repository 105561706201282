import React /* , { useState } */ from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { nanoid } from "nanoid";

import {
  Table as TableMUI,
  TableBody,
  /* TablePagination, */
} from "@material-ui/core";
import TableHeader from "./TableHeader";
import Loading from "components/Loading";
/* import Button from "components/Button"; */
import TableRow from "./TableRow";

import { PRIMARY, SPACE_CADET } from "constants/colors";

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
  },
  tableBody: {
    backgroundColor: SPACE_CADET,
  },
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
    backgroundColor: SPACE_CADET,
    borderRadius: 5,
  },
  active: {
    backgroundColor: PRIMARY,
  },
}));

/* function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onChangePage } = props;
  const classes = useStyles();

  const handleBackButtonClick = event => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onChangePage(event, page + 1);
  };

  const renderPagesButtons = () => {
    const buttonsArray = [];
    let offset = 1;
    for (let index = 1; index <= count && offset <= 5; index += rowsPerPage) {
      buttonsArray.push(
        <Button
          key={offset}
          className={page === offset - 1 && classes.active}
          onClick={e => onChangePage(e, offset)}
        >
          {offset}
        </Button>
      );
      offset += 1;
    }
    return buttonsArray;
  };

  return (
    <div className={classes.root}>
      <Button onClick={handleBackButtonClick} disabled={page === 0}>
        Precedente
      </Button>
      {renderPagesButtons()}
      <Button
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
      >
        Successivo
      </Button>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
}; */

const Table = ({ loading, rowActions, config, data }) => {
  const classes = useStyles({});
  /* const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5); */

  /* const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }; */

  return (
    <div className={classes.wrapper}>
      {loading ? (
        <Loading />
      ) : (
        <div className={classes.table} data-value="tableContainer">
          <TableMUI>
            <TableHeader
              /* order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort} */
              columns={config.columns}
              hasActions={!!rowActions}
            />
            <TableBody className={classes.tableBody}>
              {data.map((row, index) => (
                <TableRow
                  key={nanoid()}
                  row={row}
                  index={index}
                  config={config}
                  actions={rowActions}
                />
              ))}
            </TableBody>
          </TableMUI>
          {/* <TablePagination
            rowsPerPageOptions={[5, 10, 20, 40]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            labelRowsPerPage="Visualizza elementi"
            labelDisplayedRows={({ from, to, count }) =>
              `Mostra da ${from} a ${to} di ${count} elementi`
            }
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          /> */}
        </div>
      )}
    </div>
  );
};

Table.propTypes = {
  loading: PropTypes.bool,
  rowActions: PropTypes.array,
  config: PropTypes.object,
  data: PropTypes.array,
};

Table.defaultProps = {
  loading: false,
};

export default Table;

import React, { useState } from "react";
import PropTypes from "prop-types";

import Loading from "components/Loading";

import StackedRadiusMultiColumn from ".";

import { WILD_BLUE_YONDER, PACIFIC_BLU, MALACHITE } from "constants/colors";

//Enum
import { effortGraphColor } from "utils/enums/workLoad";

const getColor = type => {
  switch (type) {
    case effortGraphColor.PAST:
      return PACIFIC_BLU;
    case effortGraphColor.TODAY:
      return MALACHITE;
    case effortGraphColor.FUTURE:
      return WILD_BLUE_YONDER;
    default:
      return PACIFIC_BLU;
  }
};

const data1 = [
  {
    x: "15/05/2021",
    type: 1,
    fill: getColor(1),
    y: [
      {
        name: "Mario Rossi3",
        hours: 60,
      },
      {
        name: "Mario Rossi31",
        hours: 20,
      },
    ],
  },
  {
    x: "16/05/2021",
    type: 1,
    fill: getColor(1),
    y: [
      {
        name: "Mario Rossi3",
        hours: 40,
      },
      {
        name: "Mario Rossi31",
        hours: 40,
      },
      {
        name: "Mario Rossi11",
        hours: 10,
      },
      {
        name: "Mario Rossi21",
        hours: 15,
      },
    ],
  },
  {
    x: "17/05/2021",
    type: 1,
    fill: getColor(1),
    y: [
      {
        name: "Mario Rossi3",
        hours: 40,
      },
      {
        name: "Mario Rossi31",
        hours: 20,
      },
      {
        name: "Mario Rossi11",
        hours: 10,
      },
      {
        name: "Mario Rossi21",
        hours: 15,
      },
    ],
  },
  {
    x: "18/05/2021",
    type: 1,
    fill: getColor(1),
    y: [
      {
        name: "Mario Rossi21",
        hours: 15,
      },
      {
        name: "Mario Rossi31",
        hours: 20,
      },
      {
        name: "Mario Rossi11",
        hours: 10,
      },
      {
        name: "Mario Rossi21",
        hours: 15,
      },
    ],
  },
  {
    x: "19/05/2021",
    type: 1,
    fill: getColor(1),
    y: [
      {
        name: "Mario Rossi3",
        hours: 40,
      },
      {
        name: "Mario Rossi1",
        hours: 10,
      },
      {
        name: "Mario Rossi21",
        hours: 15,
      },
      {
        name: "Mario Rossi31",
        hours: 20,
      },
      {
        name: "Mario Rossi11",
        hours: 10,
      },
      {
        name: "Mario Rossi21",
        hours: 15,
      },
    ],
  },
  {
    x: "21/05/2021",
    type: 1,
    fill: getColor(1),
    y: [
      {
        name: "Mario Rossi",
        hours: 25,
      },
      {
        name: "Mario Rossi1",
        hours: 30,
      },
      {
        name: "Mario Rossi2",
        hours: 25,
      },
      {
        name: "Mario Rossi3",
        hours: 40,
      },
      {
        name: "Mario Rossi1",
        hours: 10,
      },
      {
        name: "Mario Rossi21",
        hours: 15,
      },
      {
        name: "Mario Rossi31",
        hours: 20,
      },
      {
        name: "Mario Rossi11",
        hours: 10,
      },
      {
        name: "Mario Rossi21",
        hours: 15,
      },
      {
        name: "Mario Rossi311",
        hours: 20,
      },
      {
        name: "Mario Rossi3111",
        hours: 20,
      },
    ],
  },
  {
    x: "22/05/2021",
    type: 1,
    fill: getColor(1),
    y: [
      {
        name: "Mario Rossi3",
        hours: 40,
      },
      {
        name: "Mario Rossi1",
        hours: 10,
      },
      {
        name: "Mario Rossi21",
        hours: 15,
      },
      {
        name: "Mario Rossi31",
        hours: 20,
      },
      {
        name: "Mario Rossi11",
        hours: 10,
      },
      {
        name: "Mario Rossi21",
        hours: 15,
      },
      {
        name: "Mario Rossi311",
        hours: 20,
      },
      {
        name: "Mario Rossi3111",
        hours: 20,
      },
    ],
  },
  {
    x: "23/05/2021",
    type: 1,
    fill: getColor(1),
    y: [
      {
        name: "Mario Rossi",
        hours: 25,
      },
      {
        name: "Mario Rossi1",
        hours: 30,
      },
      {
        name: "Mario Rossi2",
        hours: 25,
      },
      {
        name: "Mario Rossi1",
        hours: 10,
      },
      {
        name: "Mario Rossi21",
        hours: 15,
      },
      {
        name: "Mario Rossi31",
        hours: 20,
      },
      {
        name: "Mario Rossi11",
        hours: 10,
      },
      {
        name: "Mario Rossi21",
        hours: 15,
      },
      {
        name: "Mario Rossi311",
        hours: 20,
      },
      {
        name: "Mario Rossi3111",
        hours: 20,
      },
    ],
  },
  {
    x: "24/05/2021",
    type: 2,
    fill: getColor(2),
    y: [
      {
        name: "Mario Rossi",
        hours: 25,
      },
      {
        name: "Mario Rossi1",
        hours: 10,
      },
      {
        name: "Mario Rossi2",
        hours: 25,
      },
      {
        name: "Mario Rossi3",
        hours: 10,
      },
      {
        name: "Mario Rossi1",
        hours: 50,
      },
      {
        name: "Mario Rossi21",
        hours: 15,
      },
      {
        name: "Mario Rossi31",
        hours: 20,
      },
      {
        name: "Mario Rossi11",
        hours: 10,
      },
      {
        name: "Mario Rossi21",
        hours: 15,
      },
      {
        name: "Mario Rossi311",
        hours: 20,
      },
      {
        name: "Mario Rossi3111",
        hours: 20,
      },
    ],
  },
  {
    x: "25/05/2021",
    type: 3,
    fill: getColor(3),
    y: [
      {
        name: "Mario Rossi",
        hours: 2,
      },
      {
        name: "Mario",
        hours: 5,
      },
      {
        name: "Mario Rossi2",
        hours: 15,
      },
      {
        name: "Mario2",
        hours: 2,
      },
    ],
  },
  {
    x: "26/05/2021",
    type: 3,
    fill: getColor(3),
    y: [
      {
        name: "Mario Rossi",
        hours: 25,
      },
      {
        name: "Mario Rossi1",
        hours: 30,
      },
      {
        name: "Mario Rossi2",
        hours: 25,
      },
      {
        name: "Mario Rossi1",
        hours: 10,
      },
      {
        name: "Mario Rossi21",
        hours: 15,
      },
      {
        name: "Mario Rossi31",
        hours: 20,
      },
      {
        name: "Mario Rossi11",
        hours: 10,
      },
      {
        name: "Mario Rossi21",
        hours: 15,
      },
      {
        name: "Mario Rossi311",
        hours: 20,
      },
      {
        name: "Mario Rossi3111",
        hours: 20,
      },
    ],
  },
  {
    x: "27/05/2021",
    type: 3,
    fill: getColor(3),
    y: [
      {
        name: "Mario Rossi",
        hours: 45,
      },
      {
        name: "Mario Rossi1",
        hours: 50,
      },
      {
        name: "Mario Rossi2",
        hours: 25,
      },
      {
        name: "Mario Rossi3",
        hours: 40,
      },
      {
        name: "Mario Rossi1",
        hours: 10,
      },
      {
        name: "Mario Rossi21",
        hours: 15,
      },
      {
        name: "Mario Rossi31",
        hours: 20,
      },
      {
        name: "Mario Rossi11",
        hours: 10,
      },
      {
        name: "Mario Rossi21",
        hours: 15,
      },
      {
        name: "Mario Rossi311",
        hours: 20,
      },
      {
        name: "Mario Rossi3111",
        hours: 20,
      },
    ],
  },
  {
    x: "28/05/2021",
    type: 3,
    fill: getColor(3),
    y: [
      {
        name: "Mario Rossi",
        hours: 45,
      },
      {
        name: "Mario Rossi1",
        hours: 50,
      },
      {
        name: "Mario Rossi2",
        hours: 25,
      },
      {
        name: "Mario Rossi1",
        hours: 10,
      },
      {
        name: "Mario Rossi21",
        hours: 15,
      },
      {
        name: "Mario Rossi31",
        hours: 20,
      },
      {
        name: "Mario Rossi11",
        hours: 10,
      },
      {
        name: "Mario Rossi21",
        hours: 15,
      },
      {
        name: "Mario Rossi311",
        hours: 20,
      },
      {
        name: "Mario Rossi3111",
        hours: 20,
      },
    ],
  },
  {
    x: "29/05/2021",
    type: 3,
    fill: getColor(3),
    y: [
      {
        name: "Mario Rossi",
        hours: 45,
      },
      {
        name: "Mario Rossi1",
        hours: 50,
      },
      {
        name: "Mario Rossi2",
        hours: 25,
      },
      {
        name: "Mario Rossi3",
        hours: 40,
      },
      {
        name: "Mario Rossi1",
        hours: 10,
      },
      {
        name: "Mario Rossi21",
        hours: 15,
      },
      {
        name: "Mario Rossi31",
        hours: 20,
      },
      {
        name: "Mario Rossi11",
        hours: 10,
      },
      {
        name: "Mario Rossi21",
        hours: 15,
      },
      {
        name: "Mario Rossi311",
        hours: 20,
      },
      {
        name: "Mario Rossi3111",
        hours: 20,
      },
    ],
  },
  {
    x: "5/06/2021",
    type: 3,
    fill: getColor(3),
    y: [
      {
        name: "Mario Rossi1",
        hours: 10,
      },
      {
        name: "Mario Rossi21",
        hours: 15,
      },
      {
        name: "Mario Rossi31",
        hours: 20,
      },
      {
        name: "Mario Rossi11",
        hours: 10,
      },
      {
        name: "Mario Rossi21",
        hours: 15,
      },
      {
        name: "Mario Rossi311",
        hours: 20,
      },
      {
        name: "Mario Rossi3111",
        hours: 20,
      },
    ],
  },
  {
    x: "7/06/2021",
    type: 3,
    fill: getColor(3),
    y: [
      {
        name: "Mario Rossi",
        hours: 45,
      },
      {
        name: "Mario Rossi1",
        hours: 50,
      },
      {
        name: "Mario Rossi2",
        hours: 25,
      },
      {
        name: "Mario Rossi1",
        hours: 10,
      },
      {
        name: "Mario Rossi21",
        hours: 15,
      },
      {
        name: "Mario Rossi31",
        hours: 20,
      },
      {
        name: "Mario Rossi11",
        hours: 10,
      },
      {
        name: "Mario Rossi21",
        hours: 15,
      },
      {
        name: "Mario Rossi311",
        hours: 20,
      },
      {
        name: "Mario Rossi3111",
        hours: 20,
      },
    ],
  },
  {
    x: "7/06/2021",
    type: 3,
    fill: getColor(3),
    y: [
      {
        name: "Mario Rossi",
        hours: 45,
      },
    ],
  },
  {
    x: "7/06/2021",
    type: 3,
    fill: getColor(3),
    y: [
      {
        name: "Mario Rossi",
        hours: 45,
      },
      {
        name: "Mario Rossi1",
        hours: 50,
      },
      {
        name: "Mario Rossi2",
        hours: 25,
      },
      {
        name: "Mario Rossi11",
        hours: 10,
      },
      {
        name: "Mario Rossi21",
        hours: 15,
      },
    ],
  },
];

const StackedRadiusColumnManager = props => {
  const [data] = useState(props.data);

  if (Object.keys(data).length > 0) {
    return <StackedRadiusMultiColumn data={data} {...props} />;
  }
  return <Loading />;
};

StackedRadiusColumnManager.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

StackedRadiusColumnManager.defaultProps = {
  data: data1,
};

export default StackedRadiusColumnManager;

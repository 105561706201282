/* eslint-disable max-len */
import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

import { WILD_BLUE_YONDER, SPACE_CADET } from "constants/colors";

const useStyles = makeStyles(() => ({
  container: {
    flex: 1,
    height: "100%",
  },
}));

const Gantt = props => {
  const classes = useStyles();
  const { data, id } = props;

  useEffect(() => {
    if (!data || Object.keys(data).length === 0) return;

    var chart = am4core.create(`div${id}`, am4charts.XYChart);
    chart.hiddenState.properties.opacity = 0;

    chart.paddingLeft = 30;
    chart.dateFormatter.dateFormat = "yyyy-MM-dd";
    chart.dateFormatter.inputDateFormat = "yyyy-MM-dd";

    var colorSet = new am4core.ColorSet();
    colorSet.saturation = 0.4;

    chart.data = data;

    var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "name";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.inversed = true;
    categoryAxis.renderer.labels.template.disabled = true;

    var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.dateFormatter.dateFormat = "yyyy-MM-dd";
    dateAxis.baseInterval = { count: 30, timeUnit: "minute" };
    dateAxis.renderer.minGridDistance = 70;
    dateAxis.strictMinMax = true;
    dateAxis.renderer.tooltipLocation = 0;
    dateAxis.renderer.opposite = true;
    dateAxis.renderer.labels.template.fill = am4core.color(props.color);
    dateAxis.renderer.grid.template.stroke = am4core.color(props.color);
    dateAxis.renderer.grid.template.strokeWidth = 3;

    var series1 = chart.series.push(new am4charts.ColumnSeries());
    series1.columns.template.width = am4core.percent(80);
    series1.columns.template.height = am4core.percent(15);
    series1.columns.template.column.cornerRadiusTopLeft = 10;
    series1.columns.template.column.cornerRadiusTopRight = 10;
    series1.columns.template.column.cornerRadiusBottomLeft = 10;
    series1.columns.template.column.cornerRadiusBottomRight = 10;
    series1.columns.template.tooltipHTML = `<div style="color:${WILD_BLUE_YONDER}; display: inline-block">{fromDateName}</div> <b>{fromDate}</b> <div style="color:${WILD_BLUE_YONDER}; display: inline-block"> &nbsp-&nbsp {toDateName}</div>&nbsp <b>{toDate}</b>`;
    series1.columns.template.tooltipY = am4core.percent(0);
    series1.tooltip.background.stroke = am4core.color(WILD_BLUE_YONDER);
    series1.tooltip.pointerOrientation = "down";

    var bullet = series1.bullets.push(new am4charts.LabelBullet());
    bullet.label.text = `[bold]{name}[/]`;
    bullet.label.fontSize = 10;
    bullet.label.truncate = false;
    bullet.label.hideOversized = false;
    bullet.label.dy = -20;
    bullet.label.locationX = am4core.percent(100);
    bullet.label.fill = am4core.color(props.color);

    series1.dataFields.openDateX = "fromDate";
    series1.dataFields.dateX = "toDate";
    series1.dataFields.categoryY = "name";
    series1.columns.template.propertyFields.fill = "color";
    series1.columns.template.propertyFields.stroke = "color";
    series1.columns.template.strokeOpacity = 1;
    series1.tooltip.getFillFromObject = false;
    series1.tooltip.background.fill = am4core.color(SPACE_CADET);
    series1.tooltip.background.stroke = am4core.color(SPACE_CADET);
  }, [data]);

  return <div className={classes.container} id={`div${id}`} />;
};

Gantt.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  theme: PropTypes.array,
  legend: PropTypes.bool,
  color: PropTypes.string,
};

Gantt.defaultProps = {
  legend: false,
  color: WILD_BLUE_YONDER,
};

export default Gantt;

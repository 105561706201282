import React, { memo } from "react";
import PropTypes from "prop-types";
import theme from "theme";
import clsx from "clsx";

// Components
import Date from "components/Date";
import Avatar from "components/Avatar";
import SvgIcon from "components/SvgIcon";
// Assets
import calendarIcon from "assets/calendar.svg";
import meetingIcon from "assets/meeting.svg";
import { ReactComponent as DeleteIcon } from "assets/delete-outlined.svg";
import { ReactComponent as CopyIcon } from "assets/copy-outlined-icon.svg";
// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Typography, IconButton } from "@material-ui/core";
// Constants
import { WILD_BLUE_YONDER, OXFORD_BLUE } from "constants/colors";

// Styles
const useStyles = makeStyles(() => ({
  wrapper: {
    border: props =>
      `1px ${props.isDraft ? "dashed" : "solid"} ${WILD_BLUE_YONDER}${
        props.isDraft ? "CC" : "80"
      }`,
    backgroundColor: OXFORD_BLUE,
    padding: 25,
    margin: "15px auto",
    borderRadius: 5,
    overflow: "hidden",
    display: "flex",
    position: "relative",
    "&:hover": {
      background: WILD_BLUE_YONDER + "10",
      cursor: "pointer",
    },
  },
  brickContent: {
    marginLeft: 20,
    maxWidth: 175,
  },
  brickTitle: {
    fontWeight: "bold",
    marginBottom: 4,
  },
  ellipsis: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  grey: {
    color: WILD_BLUE_YONDER,
    lineHeight: 0.5,
  },
  calendarIcon: {
    width: 10,
    aspectRatio: "1/1",
    marginRight: 4,
  },
  dateWrapper: {
    display: "flex",
    alignItems: "center",
    color: WILD_BLUE_YONDER,
  },
  date: {
    marginTop: 3,
  },
  sep: {
    margin: "0 3px",
  },
  genericAvatar: {
    width: 25,
    height: 25,
    borderRadius: "50%",
    backgroundColor: "white",
    backgroundImage: `url(${meetingIcon})`,
    backgroundSize: "60%",
    backgroundPosition: "center 7px",
    backgroundRepeat: "no-repeat",
  },
  brickIcon: {
    position: "absolute",
    right: -10,
    width: 55,
  },
  delete: {
    bottom: -4,
  },
  copy: {
    top: -4,
  },
}));

const BuilderBrick = ({
  endDate,
  name,
  onEdit,
  onCopy,
  onDelete,
  startDate,
  user,
  users,
  isDraft,
}) => {
  // Hooks
  const classes = useStyles({ isDraft });

  const isGeneric = users > 0;

  const handleCopy = e => {
    e.stopPropagation();
    onCopy();
  };

  const handleDelete = e => {
    e.stopPropagation();
    onDelete();
  };

  // Renders
  const renderAvatar = () => (
    <div className={classes.avatarWrapper}>
      {!isGeneric && <Avatar size={25} image={user?.imageUrl} />}
      {isGeneric && <div className={classes.genericAvatar}></div>}
    </div>
  );

  const renderBrickTitle = () => (
    <Typography
      variant="body1"
      className={clsx(classes.ellipsis, classes.brickTitle)}
      title={name}
    >
      {name}
    </Typography>
  );

  const renderSubInfo = () => {
    if (isGeneric)
      return (
        <Typography className={classes.grey} variant="subtitle1">
          {users} users
        </Typography>
      );
    else
      return (
        <div className={classes.dateWrapper}>
          <img src={calendarIcon} className={classes.calendarIcon} />
          <Date
            dateString={startDate}
            variant="subtitle1"
            className={clsx(classes.grey, classes.date)}
          />
          <Typography
            variant="caption"
            className={clsx(classes.grey, classes.sep)}
            component="span"
          >
            |
          </Typography>
          <Date
            dateString={endDate}
            variant="subtitle1"
            className={clsx(classes.grey, classes.date)}
          />
        </div>
      );
  };

  return (
    <div className={classes.wrapper} onClick={onEdit}>
      {renderAvatar()}
      <div className={classes.brickContent}>
        {renderBrickTitle()}
        {renderSubInfo()}
      </div>
      <IconButton
        className={clsx(classes.brickIcon, classes.copy)}
        onClick={handleCopy}
      >
        <SvgIcon
          icon={<CopyIcon />}
          color={WILD_BLUE_YONDER}
          hoverColor={theme.palette.primary.main}
          width={12}
        />
      </IconButton>
      <IconButton
        className={clsx(classes.brickIcon, classes.delete)}
        onClick={handleDelete}
      >
        <SvgIcon
          icon={<DeleteIcon />}
          color={WILD_BLUE_YONDER}
          hoverColor={theme.palette.secondary.main}
          width={13}
        />
      </IconButton>
    </div>
  );
};

BuilderBrick.propTypes = {
  avatarImageUrl: PropTypes.string,
  endDate: PropTypes.string,
  name: PropTypes.string,
  onEdit: PropTypes.func,
  onCopy: PropTypes.func,
  onDelete: PropTypes.func,
  startDate: PropTypes.string,
  user: PropTypes.object,
  users: PropTypes.number,
  isDraft: PropTypes.bool,
};

export default memo(BuilderBrick);

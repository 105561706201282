export const sentimentTypes = {
  POSITIVE: 1,
  NEUTRAL: 2,
  NEGATIVE: 3,
};

export const checkSentiments = number => {
  if (number >= -1 && number < -0.33) {
    return sentimentTypes.NEGATIVE;
  } else if (number >= -0.33 && number <= 0.33) {
    return sentimentTypes.NEUTRAL;
  } else {
    return sentimentTypes.POSITIVE;
  }
};
